import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Product from "../components/product";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllProducts } from "../actions/product";
import Loader from "../components/loader";
import Message from "../components/message";
import Paginate from "../components/paginate";
import FilterComponent from "../components/filterComponent";
import { f, g } from '../Utils/translateLibrary/filter';
import { p } from '../Utils/translateLibrary/productDetails';

const SearchScreen = ({ history, match }) => {
    const dispatch = useDispatch();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const sort = params.get('sort');
    const category = params.get('category') ||'';
    const color = params.get('color') ||'';
    const priceFrom = params.get('price-from') || '';
    const priceTo = params.get('price-to') || '';
    const consistency = params.get('consistency') || '';
    const pageNumber = params.get('page') || 1;
    const name = params.get('q') || '';
    const seller = params.get('seller') || '';
    const shipmentType = params.get('shipment-type') || '';
    const keyword = params.get('q') || '';
    
    const productList = useSelector((state) => state.productList);
    const { loading, error, products, page, pages, totalItems } = productList;

    const settings = useSelector((state) => state.settings);
    const { language } = settings;

    const getVisibleProductIndexes = () => {
        const _limit = 8;
        let _firstIndex = 1;
        let _lastIndex = products.length;
        if(page > 1) {
            _firstIndex += _limit * (page - 1);
            _lastIndex += _limit * (page - 1);
        }

        return _firstIndex +  ' - ' + _lastIndex;
    }

    useEffect(() => {
        /** @todo move to global logic */
        // load DOM variables 
        setTimeout(() => {
            const _header = document.getElementById('navbar');
            if(!_header) {
                return;
            }

            document.documentElement.style.setProperty('--header-height', _header.offsetHeight + 'px');
        }, 100);

        dispatch(fetchAllProducts(keyword, pageNumber, seller, name, sort,category,color,priceFrom,priceTo,consistency, shipmentType));
    }, [dispatch, keyword, pageNumber,seller, name, sort, category,color,priceFrom,priceTo,consistency, shipmentType]);

    return (
        <>
            <Container>
                <div className="search-section">
                    <div className="col-md-9">
                        {products && products.length > 0 ? (
                            <>
                                <div className="shop-meta-list">
                                    <small>{getVisibleProductIndexes()} {g.of[language]} {totalItems} {p.itemsVisible[language]}</small>
                                </div>
                                <Row>
                                    {products.map((product, i) => {
                                        return (
                                            <Col key={i} sm={12} md={6} lg={4} xl={4} className="mb-3">
                                                <Product
                                                    product={product}
                                                    lang={language}
                                                />
                                            </Col>
                                        );
                                    })}
                                </Row>
                                <Paginate
                                    pages={pages}
                                    page={page}
                                />
                            </>
                        ) : loading ? (
                            <Loader />
                        ) : error ? (
                            <Message variant="danger">{error}</Message>
                        ) : (
                        <div>
                            {f.noProductsFound[language]}
                        </div>
                        )}
                    </div>
                    <div className="col-md-3">
                        <FilterComponent 
                            history={history}
                            p={search}
                            num={pageNumber}
                        />
                    </div>
                </div>
            </Container>
        </>
    );
};

export default SearchScreen;
