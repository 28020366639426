export const isAdmin = (user) => {
    return user.role === 'admin';
}

export const isSeller = (user) => {
    return user.role === 'seller';
}

export const isAdminOrSeller = (user) => {
    return ['admin', 'seller'].includes(user.role);
}

export const isAdminOrSupport = (user) => {
    return ['admin', 'support'].includes(user.role);
}

export const getUserIdent = (userName) => {
    if(!userName) {
        return null;
    }

    let userIdent = userName; 
    userIdent = userIdent.trim();
    userIdent = userIdent.replace(' ', '-');
    userIdent = userIdent.replace(/[&/\\#,+()$~%.'":*?<>{}]/g,'');
    userIdent = userIdent.toLowerCase();
    return userIdent;
}