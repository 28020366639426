export {g} from './globals';
export const f = {
    sellerSearch: {
        en: "Seller search",
        de: "Verkäufersuche",
        fr: "",
    },
    productSearch: {
        en: "Product search",
        de: "Artikelsuche",
        fr: "",
    },
    search: {
        en: "Search",
        de: "Suche",
        fr: "Cherche",
    },
    selectCategory: {
        en: "Select a category",
        de: "Wählen Sie eine Sorte",
        fr: "",
    },
    noProductsFound: {
        en: "No products found",
        de: "Keine Produkte gefunden",
        fr: ""
    },
    sortBy: {
        en: "Sort By",
        de: "Sortieren",
        fr: ""
    },
    sortPriceAsc: {
        en: "Price ascending",
        de: "Preis aufsteigend",
        fr: ""
    },
    sortPriceDesc: {
        en: "Price descending",
        de: "Preis absteigend",
        fr: ""
    },
    applyFilter: {
        en: "Apply Filter",
        de: "Filter anwenden",
        fr: ""
    },
    removeFilter: {
        en: "Remove filter",
        de: "Filter entfernen",
        fr: ""
    },
    selectShipment: {
        en: "Select a shipment type",
        de: "Wählen Sie eine Versandart",
        fr: "",
    },
    selectColor: {
        en: "Select a color",
        de: "Wählen Sie eine Farbe",
        fr: "",
    },
    selectConsistency: {
        en: "Select a consistency",
        de: "Wählen Sie eine Konsistenz",
        fr: "",
    }
};
