import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Route } from 'react-router-dom'
import { Table, Button, Row, Col, ButtonGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../../components/message'
import Loader from '../../../components/loader'
import SearchBox from '../../../components/searchBox'
import { deleteProduct, fetchAllProducts } from '../../../actions/product'
import { p } from "../../../Utils/translateLibrary/productDetails";
import { consistencies, categories } from '../../../Utils/translateLibrary/product';
import "../../../Utils/css/web.css";

const SellerProductListScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1;;
  const keyword = match.params.keyword;
  
  const dispatch = useDispatch()

  // User Auth
  const userAuth = useSelector((state) => state.userAuth)
  const { userInfo } = userAuth;
  const userId = userInfo._id;

  // User Settings
  const settings = useSelector((state) => state.settings)
  const { language, currency } = settings

  // Product List
  const productList = useSelector((state) => state.productList)
  const { loading, products, error, page, pages } = productList

  // Product Delete
  const productDelete = useSelector((state) => state.productDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete

  // auth
  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      if (userInfo.role !== 'seller') {
        history.push('/')
      }
      dispatch(fetchAllProducts(keyword, pageNumber, userId))
    }
  }, [dispatch, history, userInfo, successDelete, pageNumber, keyword, userId])

  const deleteHandler = (e, id) => {
    e.preventDefault()
    if (window.confirm('Möchten Sie das Produkt löschen ?')) {
      dispatch(deleteProduct(id))
    }
  }
  const createProductHandler = () => {
    history.push('/seller/product')
  }
  return (
    <>
      <Row className="align-items-center">
        <Col>
          <h1>Produkte</h1>
        </Col>
        <Col className="text-right">
          <div className="d-flex justify-content-end">
            <Button
              className="my-3 align-items-right"
              onClick={createProductHandler}
            >
              <i className="fas fa-plus mr-3" />
              { p.create[language] }
            </Button>
          </div>
        </Col>
      </Row>
      <Route
        render={({ history }) => (
          <SearchBox
            history={history}
            product
            user={userInfo}
            placeholder="Search Produkte by name, seeler or category"
          />
        )}
      />
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant="danger">{errorDelete}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : products ? (
        <>
          <Table striped bordered hover responsive className="table-sm">
            <thead>
              <tr>
                <th>Product ID</th>
                <th>Produkt Name</th>
                <th>Preis</th>
                <th>Kategorie</th>
                <th>Konsistenz</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {products?.map((product) => {
                return (
                  <tr key={product._id}>
                    <td>{product._id}</td>
                    <td>{product.name}</td>
                    <td>
                      {currency === 'eur' ? '€ ' : '$ '}
                      {product.price}
                    </td>
                    <td>{categories[product.category][language]}</td>
                    <td>{consistencies[product.consistency][language]}</td>
                    <td>
                      <ButtonGroup className="d-flex justify-content-center">
                        <LinkContainer
                          to={`/seller/product/${product._id}/edit`}
                        >
                          <Button type="button" className="m-1 btn-sm">
                            <i className="fas fa-edit" />
                          </Button>
                        </LinkContainer>
                        <Button
                          variant="danger"
                          className="m-1 btn-sm"
                          onClick={(e) => deleteHandler(e, product._id)}
                        >
                          <i className="fas fa-trash" />
                        </Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
          {/*<PaginateProductsAdmin pages={pages} page={page} keyword={keyword} />*/}
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default SellerProductListScreen;
