export const productObject = (
  userId,
  name,
  category,
  price,
  discount,
  consistency,
  color,
  description,
  weights,
  images,
  shipmentType,
  isPrio
) => {
  return {
    userId: userId,
    name: name,
    category: category,
    price: price,
    discount: discount,
    consistency: consistency,
    color: color,
    description: description,
    weights: weights,
    images: images,
    shipmentType: shipmentType,
    isPrio: isPrio
  }
}
