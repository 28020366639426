export const setting = {
    title: {
        en: "SETTINGS",
        de: "EINSTELLUNGEN",
        fr: "PARAMÈTRES",
    },
    selLan: {
        en: "Select Preferred langauge",
        de: "Bevorzugte Sprache auswählen",
        fr: "Sélectionnez la langue préférée",
    },
    selCurr: {
        en: "Select Preferred Currency",
        de: "Gewünschte Währung auswählen",
        fr: "Sélectionnez la devise préférée",
    },
    region: {
        en: "Select preferred country/region",
        de: "",
        fr: "",
    },
    change: {
        en: "Change Settings",
        de: "",
        fr: "",
    },
};
