export const setMemberShipRadio = [
    {
        label: "Standard Member",
        memberType: "member",
    },
    {
        label: "Premium Member",
        memberType: "premium",
    },
    {
        label: "Silver Member",
        memberType: "silver",
    },
    {
        label: "Gold Member",
        memberType: "gold",
    },
];
