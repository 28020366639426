export const getAddressInfoInline = (aAddress) => {
    let _parts = [];
    
    if(aAddress.address1) {
        _parts.push(aAddress.address1);
    }

    if(aAddress.address2){
        _parts.push(aAddress.address2);
    }

    if(aAddress.city) {
        let _cityParts = [];
        
        if(aAddress.zip) {
            _cityParts.push(aAddress.zip);
        }

        _cityParts.push(aAddress.city);
        _parts.push(_cityParts.join(' '));
    }

    if(aAddress.country) {
        _parts.push(aAddress.country);
    }

    return _parts.join(', ');
  }
  