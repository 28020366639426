export const acceptablePaymentMethods = [
    {
        label: "PayPal oder Kreditkarte",
        id: "PayPal",
        name: "paymentMethod",
        value: "paypal",
    },
    // {
    //     label: "Apple Pay (Only on Safari)",
    //     id: "Apple Pay",
    //     name: "paymentMethod",
    //     value: "Apple Pay",
    // },
    // {
    //     label: "Google Pay (Only on Google Chrome)",
    //     id: "Google Pay",
    //     name: "paymentMethod",
    //     value: "Google Pay",
    // },
    // {
    //     label: "Alipay",
    //     id: "Alipay",
    //     name: "paymentMethod",
    //     value: "Alipay",
    // },
    // {
    //     label: "WeChat Pay",
    //     id: "WeChat Pay",
    //     name: "paymentMethod",
    //     value: "WeChat Pay",
    // },
    {
        label: "Banküberweisung",
        id: "Bank Transfer",
        name: "paymentMethod",
        value: "bank",
    },
];