import React, { } from 'react'
// import { Link } from 'react-router-dom'

export const agb = () => {

    return (

        <div className='container'>

        <div class= "container-imprint">
         <div class= "imprint-title" ><br></br><h1>Allgemeine Geschäftsbedingungen der Imkereibedarf Profi GmbH </h1></div>
         <div class= "imprint-text">

Inhaltsverzeichnis <br></br><br></br>

A. Geltungsbereich<br></br>
B. Vertragsschluss<br></br>
C. Widerrufsrecht<br></br>
D. Preise und Zahlungsbedingungen<br></br>
E. Liefer- und Versandbedingungen<br></br>
F. Eigentumsvorbehalt<br></br>
G. Mängelhaftung (Gewährleistung)<br></br>
H. Anwendbares Recht<br></br>
I. Gerichtsstand<br></br>
J. Alternative Streitbeilegung<br></br><br></br>

A) Geltungsbereich<br></br><br></br>

A.1 Diese Allgemeinen Geschäftsbedingungen (nachfolgend "AGB") von Imkereibedarf Profi GmbH, gelten für alle Verträge über die Lieferung von Waren, die ein Verbraucher oder Unternehmer (nachfolgend „Kunde“) mit der Imkereibedarf Profi GmbH hinsichtlich der von Honigfinder in seinem Online-Shop angebotenen Waren abschließt. Hiermit wird der Einbeziehung von eigenen Bedingungen des Kunden widersprochen, es sei denn, es ist etwas anderes vereinbart.<br></br>

<br></br>A.2 Verbraucher im Sinne dieser AGB ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können. Unternehmer im Sinne dieser AGB ist eine natürliche oder juristische Person oder eine rechtsfähige Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder selbständigen beruflichen Tätigkeit handelt.<br></br>

<br></br>B) Vertragsschluss/Zustandekommen des Vertrages<br></br><br></br>

B.1 Die im Online-Shop der Imkereibedarf Profi GmbH enthaltenen Produktbeschreibungen stellen keine verbindlichen Angebote seitens der Imkereibedarf Profi GmbHs dar, sondern dienen zur Abgabe eines verbindlichen Angebots durch den Kunden.<br></br>

<br></br>B.2 Der Kunde kann das Angebot, über das in dem Online-Shop der Imkereibedarf Profi GmbH integrierten Online-Bestellformular abgeben. Dabei gibt der Kunde, nachdem er die ausgewählten Waren in den virtuellen Warenkorb gelegt und den elektronischen Bestellprozess durchlaufen hat, durch Klicken des den Bestellvorgang abschließenden Buttons ein rechtlich verbindliches Vertragsangebot in Bezug auf die im Warenkorb enthaltenen Waren ab. Ferner kann der Kunde das Angebot auch telefonisch, per Fax, per E-Mail, postalisch oder per Online-Kontaktformular gegenüber der Imkereibedarf Profi GmbH abgeben.<br></br>

<br></br>B.3 Die Imkereibedarf Profi GmbH kann das Angebot des Kunden innerhalb von sechs Tagen annehmen,<br></br>

- indem er dem Kunden eine schriftliche Auftragsbestätigung oder eine Auftragsbestätigung in Textform (Fax oder E-Mail) übermittelt, wobei insoweit der Zugang der Auftragsbestätigung beim Kunden maßgeblich ist, oder<br></br>
- indem er dem Kunden die bestellte Ware liefert, wobei insoweit der Zugang der Ware beim Kunden maßgeblich ist, oder<br></br>
- indem er den Kunden nach Abgabe von dessen Bestellung zur Zahlung auffordert.<br></br>

<br></br>Liegen mehrere der vorgenannten Alternativen vor, kommt der Vertrag in dem Zeitpunkt zustande, in dem eine der vorgenannten Alternativen zuerst eintritt. Die Frist zur Annahme des Angebots beginnt am Tag nach der Absendung des Angebots durch den Kunden zu laufen und endet mit dem Ablauf des fünften Tages, welcher auf die Absendung des Angebots folgt. Nimmt die Imkereibedarf Profi GmbH das Angebot des Kunden innerhalb vorgenannter Frist nicht an, so gilt dies als Ablehnung des Angebots mit der Folge, dass der Kunde nicht mehr an seine Willenserklärung gebunden ist.<br></br>

<br></br>B.4 Bei der Abgabe eines Angebots über das Online-Bestellformular der Imkereibedarf Profi GmbH wird der Vertragstext von Imkereibedarf Profi GmbH gespeichert und dem Kunden nach Absendung seiner Bestellung nebst den vorliegenden AGB in Textform (z. B. E-Mail, Fax oder Brief) zugeschickt. Zusätzlich wird der Vertragstext auf der Internetseite der Imkereibedarf Profi GmbH archiviert und kann vom Kunden über sein passwortgeschütztes Kundenkonto unter Angabe der entsprechenden Login-Daten kostenlos abgerufen werden, sofern der Kunde vor Absendung seiner Bestellung ein Kundenkonto im Online-Shop der Imkereibedarf Profi GmbH angelegt hat.<br></br>

<br></br>B.5 Vor verbindlicher Abgabe der Bestellung über das Online-Bestellformular der Imkereibedarf Profi GmbH kann der Kunde mögliche Eingabefehler durch aufmerksames Lesen der auf dem Bildschirm dargestellten Informationen erkennen. Ein wirksames technisches Mittel zur besseren Erkennung von Eingabefehlern kann dabei die Vergrößerungsfunktion des Browsers sein, mit deren Hilfe die Darstellung auf dem Bildschirm vergrößert wird. Seine Eingaben kann der Kunde im Rahmen des elektronischen Bestellprozesses so lange über die üblichen Tastatur- und Mausfunktionen korrigieren, bis er den Bestellvorgang abschließenden Button anklickt.<br></br>

<br></br>B.6 Für den Vertragsschluss steht ausschließlich die deutsche Sprache zur Verfügung.<br></br>

<br></br>B.7 Die Bestellabwicklung und Kontaktaufnahme finden in der Regel per E-Mail und automatisierter Bestellabwicklung statt. Der Kunde hat sicherzustellen, dass die von ihm zur Bestellabwicklung angegebene E-Mail-Adresse zutreffend ist, so dass unter dieser Adresse die von Imkereibedarf Profi GmbH versandten E-Mails empfangen werden können. Insbesondere hat der Kunde bei dem Einsatz von SPAM-Filtern sicherzustellen, dass alle von Imkereibedarf Profi GmbH oder von diesem mit der Bestellabwicklung beauftragten Dritten versandten E-Mails zugestellt werden können.<br></br>

<br></br>C) Widerrufsrecht<br></br><br></br>

C.1 Verbrauchern steht grundsätzlich ein Widerrufsrecht zu.<br></br>

<br></br>C.2 Nähere Informationen zum Widerrufsrecht ergeben sich aus der Widerrufsbelehrung der Imkereibedarf Profi GmbH.<br></br>

<br></br>D) Preise und Zahlungsbedingungen<br></br><br></br>

D.1 Sofern sich aus der Produktbeschreibung der Imkereibedarf Profi GmbH nichts anderes ergibt, handelt es sich bei den angegebenen Preisen um Gesamtpreise, die die gesetzliche Umsatzsteuer enthalten. Gegebenenfalls zusätzlich anfallende Liefer- und Versandkosten werden in der jeweiligen Produktbeschreibung gesondert angegeben. Sofern nicht Abweichendes vereinbart wurde, ist die Zahlung des Kaufpreises mit Vertragsschluss fällig.<br></br>

<br></br>D.2 Die Zahlungsmöglichkeit/en wird/werden dem Kunden im Online-Shop der Imkereibedarf Profi GmbH mitgeteilt.<br></br>

{/* <br></br>D.3 Bei Auswahl der Zahlungsart „SOFORT Überweisung“ erfolgt die Zahlungsabwicklung über den Zahlungsdienstleister SOFORT GmbH, Theresienhöhe 12, 80339 München (im Folgenden „SOFORT“). Um den Rechnungsbetrag über SOFORT Überweisung bezahlen zu können, muss der Kunde über ein für die Teilnahme an SOFORT Überweisung frei geschaltetes Online-Banking-Konto mit PIN/TAN-Verfahren verfügen, sich beim Zahlungsvorgang entsprechend legitimieren und die Zahlungsanweisung gegenüber SOFORT bestätigen. Die Zahlungstransaktion wird unmittelbar danach von SOFORT durchgeführt und das Bankkonto des Kunden belastet. Nähere Informationen zur Zahlungsart SOFORT Überweisung kann der Kunde im Internet unter https://www.sofort.com/ger-DE/kaeufer/su/so-funktioniert-sofort-ueberweisung/ abrufen.<br></br> */}

<br></br>E) Liefer- und Versandbedingungen<br></br><br></br>

E.1 Die Lieferung von Waren erfolgt auf dem Versandweg an die vom Kunden angegebene Lieferanschrift, sofern nichts anderes vereinbart ist. Bei der Abwicklung der Transaktion ist die in der Bestellabwicklung der Imkereibedarf Profi GmbH angegebene Lieferanschrift maßgeblich.<br></br>

<br></br>E.2 Sendet das Transportunternehmen die versandte Ware an die Imkereibedarf Profi GmbH zurück, da eine Zustellung beim Kunden nicht möglich war, trägt der Kunde die Kosten für den erfolglosen Versand. Dies gilt nicht, wenn der Kunde sein Widerrufsrecht wirksam ausübt, wenn er den Umstand, der zur Unmöglichkeit der Zustellung geführt hat, nicht zu vertreten hat oder wenn er vorübergehend an der Annahme der angebotenen Leistung verhindert war, es sei denn, dass die Imkereibedarf Profi GmbH ihm die Leistung eine angemessene Zeit vorher angekündigt hatte.<br></br>

{/* <br></br>E.3 Selbstabholung ist aus logistischen Gründen nicht möglich.<br></br> */}


<br></br>F) Eigentumsvorbehalt<br></br><br></br>

Tritt die Imkereibedarf Profi GmbH in Vorleistung, behält er sich bis zur vollständigen Bezahlung des geschuldeten Kaufpreises das Eigentum an der gelieferten Ware vor.<br></br>

<br></br>G) Mängelhaftung (Gewährleistung)<br></br><br></br>

G.1 Ist die Kaufsache mangelhaft, gelten die Vorschriften der gesetzlichen Mängelhaftung.<br></br>

<br></br>G.2 Der Kunde wird gebeten, angelieferte Waren mit offensichtlichen Transportschäden bei dem Zusteller zu reklamieren und die Imkereibedarf Profi GmbH hiervon in Kenntnis zu setzen. Kommt der Kunde dem nicht nach, hat dies keinerlei Auswirkungen auf seine gesetzlichen oder vertraglichen Mängelansprüche.<br></br>

<br></br>H) Anwendbares Recht<br></br><br></br>

Für sämtliche Rechtsbeziehungen der Parteien gilt das Recht der Bundesrepublik Deutschland unter Ausschluss der Gesetze über den internationalen Kauf beweglicher Waren. Bei Verbrauchern gilt diese Rechtswahl nur insoweit, als nicht der gewährte Schutz durch zwingende Bestimmungen des Rechts des Staates, in dem der Verbraucher seinen gewöhnlichen Aufenthalt hat, entzogen wird.<br></br>

<br></br>I) Gerichtsstand<br></br><br></br>

Handelt der Kunde als Kaufmann, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen mit Sitz im Hoheitsgebiet der Bundesrepublik Deutschland, ist ausschließlicher Gerichtsstand für alle Streitigkeiten aus diesem Vertrag der Geschäftssitz der Imkereibedarf Profi GmbH. Hat der Kunde seinen Sitz außerhalb des Hoheitsgebiets der Bundesrepublik Deutschland, so ist der Geschäftssitz der Imkereibedarf Profi GmbH ausschließlicher Gerichtsstand für alle Streitigkeiten aus diesem Vertrag, wenn der Vertrag oder Ansprüche aus dem Vertrag der beruflichen oder gewerblichen Tätigkeit des Kunden zugerechnet werden können. Die Imkereibedarf Profi GmbH ist in den vorstehenden Fällen jedoch in jedem Fall berechtigt, das Gericht am Sitz des Kunden anzurufen.<br></br>

<br></br>J) Alternative Streitbeilegung<br></br><br></br>

J.1 Die EU-Kommission stellt im Internet unter folgendem Link eine Plattform zur Online-Streitbeilegung bereit: http://ec.europa.eu/consumers/odr<br></br>

Diese Plattform dient als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten aus Online-Kauf- oder Dienstleistungsverträgen, an denen ein Verbraucher beteiligt ist.<br></br>

<br></br>J.2 Die Imkereibedarf Profi GmbH ist zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle nicht verpflichtet, hierzu jedoch grundsätzlich bereit.<br></br>

        
        </div>
        </div>
        </div>


    )
}

export default agb;