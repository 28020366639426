export const shippingTranslate = {
    title: {
        en: "Shipping address",
        de: "Lieferadresse",
        fr: "",
    },
    givenName: {
        en: "Given Name",
        de: "Vorname",
        fr: "",
    },
    surname: {
        en: "Surname",
        de: "Nachname",
        fr: "",
    },
    email: {
        en: "Email Address",
        de: "Email Adresse",
        fr: "",
    },
    addressLine1: {
        en: "Address data",
        de: "Adressinformationen",
        fr: "",
    },
    addressLine2: {
        en: "Address Line 2",
        de: "Straßenzusatz",
        fr: "",
    },
    country: {
        en: "Country or Region",
        de: "Land",
        fr: "",
    },
    city: {
        en: "City",
        de: "Stadt",
        fr: "",
    },
    contact: {
        en: "Contact Number",
        de: "Telefonnummer",
        fr: "",
    },
    continue: {
        en: "Continue",
        de: "Weiter",
        fr: "",
    },
    isBillingAddressDifferent: {
        en: "Is billing address different",
        de: "Abweichende Rechnungsadresse",
        fr: "",
    },
    receiver: {
        en: "Receiver",
        de: "Empfänger",
        fr: "",
    },
    street: {
        en: "Street and house no.",
        de: "Straße und Hausnummer",
        fr: "",
    },
    zip: {
        en: "Zip code",
        de: "Postleitzahl",
        fr: "",
    }
};
