import { IMAGE_UPLOAD_REQUEST, IMAGE_UPLOAD_SUCCESS, IMAGE_UPLOAD_FAIL, IMAGE_DELETE_REQUEST, IMAGE_DELETE_SUCCESS, IMAGE_DELETE_FAIL, IMAGE_DELETE_RESET, IMAGE_UPLOAD_RESET } from "../actions/types";

export const uploadImageReducer = (state = { messages: [] }, action) => {
    switch (action.type) {
        case IMAGE_UPLOAD_REQUEST:
            return { imageUploadLoading: true };
        case IMAGE_UPLOAD_SUCCESS:
            return {
                imageUploadLoading: false,
                imageUploadSuccess: true,
                images: action.payload
            };
        case IMAGE_UPLOAD_FAIL:
            return { imageUploadLoading: false, imageUploadError: action.payload };
        case IMAGE_UPLOAD_RESET:
            return {};
        default:
            return state;
    }
};

export const deleteImageReducer = (state = { messages: [] }, action) => {
    switch (action.type) {
        case IMAGE_DELETE_REQUEST:
            return { imageDeleteLoading: true };
        case IMAGE_DELETE_SUCCESS:
            return {
                imageDeleteLoading: false,
                imageDeleteSuccess: true
            };
        case IMAGE_DELETE_FAIL:
            return { imageDeleteLoading: false, imageDeleteError: action.payload };
        case IMAGE_DELETE_RESET:
            return {};
        default:
            return state;
    }
};