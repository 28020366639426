export const c = {
    shoppingCart: {
        en: "Shopping cart",
        de: "Warenkorb",
        fr: "PANIER D'ACHAT",
    },
    emptyCart: {
        en: "Your cart is empty",
        de: "Ihr Warenkorb ist leer",
        fr: "Votre panier est vide",
    },
    goBack: {
        en: "Go Back",
        de: "Zurückgehen",
        fr: "Retourner",
    },
    checkoutNow: {
        en: "Checkout now",
        de: "Zusammenfassung",
        fr: "VÉRIFIER MAINTENANT",
    },
    subTotal: {
        en: "Sub total",
        de: "Zwischensumme",
        fr: "SOUS-TOTAL",
    },
    items: {
        en: "Items",
        de: "Artikel",
        fr: "ITEMENTE",
    },
    total: {
        en: "Total",
        de: "Gesamtpreis",
        fr: "TOTAL",
    },
    proceed: {
        en: "PROCEED TO CHECKOUT",
        de: "Zur Kasse gehen",
        fr: "PASSER À LA CAISSE",
    },
};
