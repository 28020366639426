import { MESSAGES_CREATE_FAIL, MESSAGES_CREATE_REQUEST, MESSAGES_CREATE_SUCCESS, MESSAGES_LIST_FAIL, MESSAGES_LIST_REQUEST, MESSAGES_LIST_SUCCESS, DIALOG_LIST_FAIL, DIALOG_LIST_REQUEST, DIALOG_LIST_SUCCESS } from "../actions/types";

export const messageListReducer = (state = { messages: [] }, action) => {
    switch (action.type) {
        case MESSAGES_LIST_REQUEST:
            return { loading: true };
        case MESSAGES_LIST_SUCCESS:
            return {
                loading: false,
                success: true,
                messages: action.payload.messages,
            };
        case MESSAGES_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const messageCreateReducer = (state = { messages: [] }, action) => {
    switch (action.type) {
        case MESSAGES_CREATE_REQUEST:
            return { loading: true };
        case MESSAGES_CREATE_SUCCESS:
            return {
                loading: false,
                success: true,
                messageInfo: action.payload
            };
        case MESSAGES_CREATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};

export const dialogListReducer = (state = { dialogs: [] }, action) => {
    switch (action.type) {
        case DIALOG_LIST_REQUEST:
            return { loading: true };
        case DIALOG_LIST_SUCCESS:
            return {
                loading: false,
                success: true,
                dialogs: action.payload.users,
            };
        case DIALOG_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
};