import React, { useState, useEffect } from "react";
import { Form, Button, InputGroup, FormControl } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../../components/formContainer";
import Message from "../../components/message";
import Loader from "../../components/loader";
import { getUserInfo, updateUserProfile } from "../../actions/user";
import FormComponent from "../../components/formComponent";
import EditProfileNavbar from "../../components/editProfileNavbar";
import ShowImage from '../../components/showImage';
import { p, g, shippingTranslate } from '../../Utils/translateLibrary/profile';
import { getUserIdent, isAdminOrSeller } from "../../Utils/helpers/user";
import { deleteProfileImage, uploadProfileImage } from "../../actions/image";

const ProfileScreen = ({ history, match }) => {
    const [description, setDescription] = useState("");
    const [username, setUsername] = useState("");
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [zip, setZip] = useState("");
    const [country, setCountry] = useState("");
    const [contactNum, setContactNum] = useState("");

    const dispatch = useDispatch();

    const userDetails = useSelector((state) => state.userDetails);
    const { loading, error, user } = userDetails;

    // User Authentication
    const userAuth = useSelector((state) => state.userAuth);
    const { userInfo } = userAuth;

    // Update User
    const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
    const { success } = userUpdateProfile;

    const settings = useSelector((state) => state.settings);
    const { language } = settings;

    const uploadImage = useSelector((state) => state.uploadImage);
    const { imageUploadLoading, imageUploadSuccess, imageUploadError, images } = uploadImage;

    const deleteImage = useSelector((state) => state.deleteImage);
    const { imageDeleteSuccess, imageDeleteError } = deleteImage;

    const [previewSource, setPreviewSource] = useState([]);

    const handleFileInputChange = (e) => {
        const file = e.target.files[0]
        if (!file) {
          return
        }

        previewFile(file)
        e.target.value = null;
      }

      
    const previewFile = (file) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = () => {
            dispatch(uploadProfileImage(userInfo._id, reader.result));
        }
    }
    
    const handleRemoveImage = (index) => {
        let _file = previewSource[index];
        dispatch(deleteProfileImage(userInfo._id, _file));
        previewSource.splice(index, 1)
        setPreviewSource(previewSource);
    }

    useEffect(() => {
        if (!userInfo) {
            history.push("/login");
        } else {
            if (!user) {
                dispatch(getUserInfo(userInfo._id));
            } else {
                setUsername(user.username ? user.username : "");
                setDescription(user.description || "");
                setName(user.name || '');
                setSurname(user.surname || '');
                setEmail(user.email ? user.email : "");
                setAddress1(user.address1 ? user.address1 : "");
                setAddress2(user.address2 ? user.address2 : "");
                setCountry(user.country ? user.country : "");
                setCity(user.city ? user.city : "");
                setZip(user.zip || '');
                setContactNum(user.contactNum || "");
                setPreviewSource(images || user.images || []);
            }
        }
    }, [dispatch, history, userInfo, user, images]);

    const submitHandler = (e) => {
        e.preventDefault();
        if (username !== "" && email !== "") {
            dispatch(
                updateUserProfile(
                    username,
                    name,
                    surname,
                    email,
                    contactNum,
                    city,
                    zip,
                    address1,
                    address2,
                    country,
                    description,
                    getUserIdent(username)
                )
            );
        }
    };

    return (
        <>
            <EditProfileNavbar match={match} />
            <FormContainer>
                {error && <Message variant="danger">{error}</Message>}
                {success && (
                    <Message variant="success">{"Profil erfolgreich aktualisiert"}</Message>
                )}
                {imageUploadError && <Message variant="danger">{imageUploadError}</Message>}
                {imageUploadSuccess && (
                    <Message variant="success">{"Bild erfolgreich hochgeladen"}</Message>
                )}
                {imageDeleteError && <Message variant="danger">{imageDeleteError}</Message>}
                {imageDeleteSuccess && (
                    <Message variant="success">{"Bild erfolgreich gelöscht"}</Message>
                )}
                {loading && <Loader />}
                <Form onSubmit={submitHandler} autoComplete="on">
                    <div className='mt-3'>
                        <Form.Label style={{ fontWeight:"bolder"}}>{p.images[language]}</Form.Label>
                        <Form.Group className="mb-3">
                            <Form.Control disabled={previewSource.length >= 4 || imageUploadLoading} accept="image/jpeg, image/png" type="file" onChange={handleFileInputChange} />
                        </Form.Group>
                        <ShowImage
                            source={previewSource}
                            handleRemoveImage={(index) => handleRemoveImage(index)}
                        />
                    </div>

                    {isAdminOrSeller(userInfo) && (<div className='mt-auto'>
                        <Form.Label style={{ fontWeight:"bolder"}}>{p.description[language]} *</Form.Label>
                        <textarea onChange={(e) => setDescription(e.target.value)} value={description} placeholder="..." rows={3} className="w-100 input"></textarea>
                    </div>)}

                    <FormComponent
                        label={p.username[language]}
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />

                    <FormComponent
                        label={g.firstname[language]}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    
                    <FormComponent
                        label={g.lastname[language]}
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                    />

                    <FormComponent
                        label={g.email[language]}
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    
                    <FormComponent
                        label={p.shipping_address[language]}
                        type="text"
                        placeholder={"Straße und Hausnummer"}
                        value={address1}
                        required={false}
                        onChange={(e) => setAddress1(e.target.value)}
                    />

                    <FormComponent
                        label={shippingTranslate.addressLine2[language]}
                        value={address2}
                        required={false}
                        onChange={(e) => setAddress2(e.target.value)}
                    />
                    <Form.Group className="py-2">
                        <Form.Label><Form.Label className="font-weight-bold">{p.country[language]}</Form.Label></Form.Label>
                        <Form.Select
                            onChange={(e) => setCountry(e.target.value)}
                            value={country}
                        >
                            <option value="" disabled>Wählen Sie ein Land oder eine Region</option>
                            <option value="Deutschland">Deutschland</option>
                            <option value="Frankreich">Frankreich</option>
                        </Form.Select>
                    </Form.Group>

                    <FormComponent
                        label={shippingTranslate.zip[language]}
                        placeholder={"PLZ"}
                        value={zip}
                        required={false}
                        onChange={(e) => setZip(e.target.value)}
                    />

                    <FormComponent
                        label={p.city[language]}
                        type="text"
                        placeholder={"Stadt"}
                        value={city}
                        required={false}
                        onChange={(e) => setCity(e.target.value)}
                    />

                    <Form.Group className="py-2">
                        <Form.Label className="font-weight-bold">{p.contact_number[language]}</Form.Label>
                        <InputGroup className="mb-3">
                            <InputGroup.Text className="filter-label border" style={{ fontWeight:"bolder" }}>
                                {country === "Frankreich" ? "+33" : "+49"}
                            </InputGroup.Text>
                            <FormControl className="border input" style={{ fontWeight:"bolder"}}
                                value={contactNum}
                                onChange={(e) => setContactNum(e.target.value)}
                            />
                        </InputGroup>
                    </Form.Group>
                    <div className="py-3 d-flex">
                        <Button style={{ borderRadius:"40px", fontWeight:"bolder"}}
                            type="submit"
                            variant="primary"
                            className="m-auto text-dark border"
                            disabled={username === "" || email === ""}
                        >
                            {p.update[language]}
                        </Button>
                    </div>
                </Form>
            </FormContainer>
        </>
    );
};

export default ProfileScreen;
