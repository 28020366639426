import React, { useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, ButtonGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/message";
import Loader from "../../components/loader";
import { listAllOrders } from "../../actions/order";
import { ORDER_DELETE_RESET } from "../../actions/types";
import { Route } from "react-router-dom";
import SearchBox from "../../components/searchBox";
import Paginate from "../../components/paginate";
import { formatDate } from "../../Utils/helpers/date";
import { summary } from "../../Utils/translateLibrary/orderSummary";
import { p } from "../../Utils/translateLibrary/productDetails";
import { payT } from "../../Utils/translateLibrary/paymentMethod";
import { isAdminOrSupport } from "../../Utils/helpers/user";

const OrderListScreen = ({ history, match }) => {
    const searchParams = window.location.search;
    const params = new URLSearchParams(searchParams);
    const keyword = params.get('q') || '';

    const pageNumber = match.params.pageNumber || 1;

    const dispatch = useDispatch();

    // User Auth
    const userAuth = useSelector((state) => state.userAuth);
    const { userInfo } = userAuth;

    // Order List
    const listOrders = useSelector((state) => state.listOrders);
    const { loading, orders, page, pages, error } = listOrders;


    const settings = useSelector((state) => state.settings)
    const { language } = settings

    // Product Delete
    const orderDelete = useSelector((state) => state.orderDelete);
    const { loadingDelete, error: errorDelete, message } = orderDelete;

    useEffect(() => {
        if (userInfo) {
            if (!isAdminOrSupport(userInfo)) {
                history.push("/");
            }
        } else {
            history.push("/login");
            
            dispatch(listAllOrders(keyword, pageNumber));
        }
        if (message) {
            setTimeout(() => {
                dispatch({ type: ORDER_DELETE_RESET });
            }, 500);
        }
    }, [dispatch, history, userInfo, message, keyword, pageNumber]);

    return (
        <>
            {loadingDelete && <Message variant="danger">Deleting...</Message>}
            {errorDelete && <Message variant="danger">{errorDelete}</Message>}
            {message && <Message variant="success">{message}</Message>}
            <Route
                render={({ history }) => (
                    <SearchBox
                        history={history}
                        order
                        placeholder="Suche"
                    />
                )}
            />

            {loading && !message ? (
                <Loader />
            ) : error ? (
                <Message variant="danger">{error}</Message>
            ) : orders ? (
                <>
                    <Table
                        bordered
                        hover
                        responsive
                        className="table-sm"
                    >
                        <thead>
                            <tr>
                                <th>Bestell-Id</th>
                                <th>Kunde</th>
                                <th>Artikel</th>
                                <th>Preis</th>
                                <th>Erstellt am</th>
                                <th>{summary.paymentMethod[language]}</th>
                                <th>{summary.paidAt[language]}</th>
                                <th>{summary.deliveredAt[language]}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map((order) => {
                                return (
                                    <tr key={order._id}>
                                        <td>#{order._id}</td>
                                        <td>{`${order.user.name} ${order.user.surname}`}</td>
                                        <td>
                                            {order.orderItems &&
                                                order.orderItems.map(
                                                    (item, i) => {
                                                        return (
                                                            <li className="list-type-none"
                                                                key={i}
                                                            >{`${item.name} ${item.qty} ${p.pieceShort[language]}`}</li>
                                                        );
                                                    }
                                                )}
                                        </td>
                                         <td>
                                            {order.totalPrice.toFixed(2)} €
                                        </td>

                                        <td>
                                            { formatDate(order.createdAt.substring(0,10))}
                                        </td>
                                        <td>{payT.methods[order.paymentMethod][language]}</td>
                                        <td>
                                            {order.isPaid ? (
                                                formatDate(order.paidAt.substring(0, 10))
                                            ) : (
                                                <i
                                                className="fas fa-times"
                                                style={{ color: 'red' }}
                                                />
                                            )}
                                        </td>
                                        <td>
                                            {order.isDelivered ? (
                                                formatDate(order.deliveredAt.substring(0, 10))
                                            ) : (
                                                <i
                                                className="fas fa-times"
                                                style={{ color: 'red' }}
                                                />
                                            )}
                                        </td>
                                        <td>
                                            <ButtonGroup className="d-flex justify-content-center">
                                                <LinkContainer
                                                    to={`/order/${order._id}`}
                                                >
                                                    <Button className="btn-sm font-weight-bold" style={{ borderRadius:"40px"}}>Details</Button>
                                                </LinkContainer>
                                            </ButtonGroup>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    <Paginate
                        page={page}
                        pages={pages}
                        keyword={keyword}
                    />
                </>
            ) : (
                <></>
            )}
        </>
    );
};

export default OrderListScreen;
