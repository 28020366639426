import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserOrders } from '../actions/order'
import Loader from '../components/loader'
import Message from '../components/message'
import { Table, Button, Container } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { formatDate } from '../Utils/helpers/date'
import Paginate from '../components/paginate'

const OrderHistoryScreen = ({ history }) => {
  const userAuth = useSelector((state) => state.userAuth)
  const { userInfo } = userAuth;

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const pageNumber = params.get('page') || '';

  const { loading, orders, error, page, pages } = useSelector((state) => state.userOrders)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      dispatch(getUserOrders(pageNumber))
    }
  }, [dispatch, userInfo, history, pageNumber])

  return (
    <>
      <Container>
        {orders ? (
          <div>
            {orders.length === 0 ? (
              'Keine Bestellungen gefunden'
            ) : (
              <>
              <Table bordered hover className="table-sm">
                <thead>
                  <tr>
                    <th style={{ fontWeight:"bolder"}}>Bestellnummer</th>
                    <th style={{ fontWeight:"bolder"}}>Datum</th>
                    <th style={{ fontWeight:"bolder"}}>Gesamt</th>
                    <th style={{ fontWeight:"bolder"}}>Bezahlt</th>
                    <th style={{ fontWeight:"bolder"}}>Bestellstatus</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => {
                    return (
                      <tr key={order._id}>
                        <td>#{order._id}</td>
                        <td>{formatDate(order.createdAt.substring(0, 10))}</td>
                        <td>{order.totalPrice.toFixed(2)} €</td>
                        <td>
                          {order.isPaid ? (
                            formatDate(order.paidAt.substring(0, 10))
                          ) : (
                            <i
                              className="fas fa-times"
                              style={{ color: 'red' }}
                            />
                          )}
                        </td>
                        <td>
                          {order.isDelivered
                            ? formatDate(order.deliveredAt.substring(0, 10))
                            : <i
                                className="fas fa-times"
                                style={{ color: 'red' }}
                              />
                          }
                        </td>

                        <LinkContainer to={`/order/${order._id}`}>
                          <td className="d-grid gap-2">
                            <Button className="btn-sm" style={{ fontWeight:"bolder", backgroundColor: "var(--primary)" , borderRadius:"40px"}}>Details</Button>
                          </td>
                        </LinkContainer>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
              
              <Paginate
                page={page}
                pages={pages}
              />
            </>
            )}
          </div>
        ) : loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : null}
      </Container>
    </>
  )
}

export default OrderHistoryScreen
