import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/message";
import Loader from "../../components/loader";
import { listDialogs, getUserInfo, listMessages, sendMessage } from "../../actions/user";
import '../../Utils/css/chat.css';

const ChatScreen = ({ history, match }) => {
    
    // Route Params
    const [userId, setUserId] = useState(match.params.userId);

    const dispatch = useDispatch();
    const messageList = useSelector((state) => state.messageList);
    const { loading, messages, error } = messageList;

    // participant 
    const userDetails = useSelector((state) => state.userDetails);
    const { user } = userDetails;
    const participant = user;

    // userAuth
    const userAuth = useSelector((state) => state.userAuth);
    const { userInfo } = userAuth;

    const dialogList = useSelector((state) => state.dialogList);
    const { dialogs } = dialogList;
    useEffect(() => {
        if (!userInfo) {
            history.push("/login");
            return;
        }

        dispatch(listDialogs(userInfo._id));
        if(userId) {
            dispatch(getUserInfo(userId));
            dispatch(listMessages(userId));
        }
    }, [dispatch, history, userInfo, userId]);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            dispatch(sendMessage(event.target.value, userId)).then(() => 
                dispatch(listMessages(userId))
            );
          }
    } 

    const getSentAt = (createdAt) => {
        let date = new Date(createdAt);
        return date.getHours() + ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
    }

    function ScrollToBottom() {
            const elementRef = useRef();
            useEffect(() => elementRef.current.scrollIntoView());
            return <div ref={elementRef} />;
        };
    return (
        <>
            {loading ? (
                <Loader />
            ) : error ? (
                <Message variant="danger">{error}</Message>
            ) : (
                <>
                    <div className="d-flex">
                        <div className="col-md-2">
                            <div className="dialog-container">
                                {dialogs && (dialogs.map((user) => {
                                    return (
                                        <div onClick={(e) => setUserId(user._id)} key={user._id} className={'dialog-wrapper ' + (participant && user._id === participant._id ? 'active' : '')}>
                                            <strong className="text-strong">{user.username}</strong>
                                            <p className="last-message"></p>
                                        </div>
                                    )
                                }))}
                            </div>
                        </div>
                        {messages && (<div className="col-md-10 p-3">
                            {participant && (
                            <>
                                <h1>{participant.username}</h1>
                                <div className="message-container">
                                    {messages.map((message, index, messages) => {
                                        return (
                                            <div className={(message.senderId === userInfo._id ? 'sent-message' : 'received-message')} key={message._id}>
                                                <div className="message">
                                                    <p className="mb-0 text-left">
                                                        {message.content}
                                                    </p>
                                                </div>
                                                <span className="sent-at">{getSentAt(message.createdAt)}</span>
                                                {index + 1 === messages.length &&
                                                    <ScrollToBottom />
                                                }
                                            </div>
                                        );
                                    })}
                                    {participant && (<div className="send-box">
                                        <input onKeyDown={handleKeyDown} className="form-control send-input" placeholder="Message"></input>
                                        <div className="send-icon-wrapper">
                                            <i className="fa fa-arrow-up"></i>
                                        </div>
                                    </div>)}
                                </div>
                            </>)}
                        </div>)}
                    </div>
                </>
            )}
        </>
    );
};

export default ChatScreen;
