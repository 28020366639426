import product from "../apis/api";
import {
    CART_ADD_ITEM,
    UPDATE_CART_ITEM,
    CART_REMOVE_ITEM,
    CART_ADD_ITEM_TO_DB,
    CART_REMOVE_ITEM_TO_DB,
    UPDATE_CART_ITEM_TO_DB,
    MOVE_CART_ITEM_TO_DB,
    CART_ADD_ITEM_TO_DB_FAIL,
    CART_REMOVE_ITEM_TO_DB_FAIL,
    UPDATE_CART_ITEM_TO_DB_FAIL,
    MOVE_CART_ITEM_TO_DB_FAIL,
    CART_ADD_ITEM_TO_DB_REQUEST,
    CART_REMOVE_ITEM_TO_DB_REQUEST,
    UPDATE_CART_ITEM_TO_DB_REQUEST,
    MOVE_CART_ITEM_TO_DB_REQUEST,
    CART_SAVE_SHIPPING_ADDRESS,
    CART_SAVE_PAYMENT_METHOD,
    CART_SAVE_USER_SHIPPING_INFO,
    CART_SAVE_BILLING_ADDRESS,
    CART_SAVE_USER_BILLING_INFO,
} from "./types";

import { configUtil } from "../Utils/apiConfig";
import { errorHandler } from "../Utils/errorHandling";

export const addToCartDB =
    (productId, qty, weight, userId) => async (dispatch, getState) => {

        // After setting the cart items, the send a post request to the server;
        const { userInfo } = getState().userAuth;
        try {
            const { data } = await product.get(`/product/${productId}`);

            dispatch({ type: CART_ADD_ITEM_TO_DB_REQUEST });
            const response = await product.post(
                `/cart/${userInfo._id}`,
                {
                    item: {
                        productId: data._id,
                        name: data.name,
                        discount: data.discount,
                        price: data.price,
                        images: data.images,
                        weight: weight,
                        qty: qty,
                        color: data.color,
                        ownerId: userId
                    },
                },
                configUtil(userInfo.token)
            );

            dispatch({ type: CART_ADD_ITEM_TO_DB, payload: response.data });
            localStorage.setItem("cartItems", JSON.stringify(response.data));
        } catch (error) {
            dispatch({
                type: CART_ADD_ITEM_TO_DB_FAIL,
                payload: errorHandler(error),
            });
        }
    };

export const addToCart =
    (productId, qty, weight, userId) => async (dispatch, getState) => {
        try {
            const { data } = await product.get(`/product/${productId}`);
            dispatch({
                type: CART_ADD_ITEM,
                payload: {
                    productId: data._id,
                    name: data.name,
                    description: data.description,
                    discount: data.discount,
                    images: data.images,
                    price: data.price,
                    weight: weight,
                    qty: Number(qty),
                    ownerId: userId
                },
            });

            localStorage.setItem(
                "cartItems",
                JSON.stringify(getState().cart.cartItems)
            );
        } catch (error) {
            dispatch({
                type: CART_ADD_ITEM_TO_DB_FAIL,
                payload: errorHandler(error),
            });
        }
    };

export const updateCartToDB =
    (cartId, qty) =>
    async (dispatch, getState) => {
        const { userInfo } = getState().userAuth;

        try {
            dispatch({ type: UPDATE_CART_ITEM_TO_DB_REQUEST });
            const { data } = await product.patch(
                `/cart/${userInfo._id}/update-cart`,
                {
                    id: cartId,
                    qty: qty
                },
                configUtil(userInfo.token)
            );
            dispatch({ type: UPDATE_CART_ITEM_TO_DB, payload: data });
            localStorage.setItem("cartItems", JSON.stringify(data));
        } catch (error) {
            dispatch({
                type: UPDATE_CART_ITEM_TO_DB_FAIL,
                payload: errorHandler(error),
            });
        }
    };

export const updateCart =
    (cartId, qty, productId, weight) =>
    async (dispatch, getState) => {
        dispatch({
            type: UPDATE_CART_ITEM,
            payload: {
                id: cartId,
                qty: qty,
                productId: productId,
                weight: weight
            },
        });
        localStorage.setItem(
            "cartItems",
            JSON.stringify(getState().cart.cartItems)
        );
    };

export const removeCartItemInDB =
    (cartId) => async (dispatch, getState) => {
        const { userInfo } = getState().userAuth;

        try {
            dispatch({ type: CART_REMOVE_ITEM_TO_DB_REQUEST });
            const { data } = await product.patch(
                `/cart/${userInfo._id}/remove`,
                {
                    cartId: cartId
                },
                configUtil(userInfo.token)
            );
            dispatch({ type: CART_REMOVE_ITEM_TO_DB, payload: data });
            localStorage.setItem("cartItems", JSON.stringify(data));
        } catch (error) {
            dispatch({
                type: CART_REMOVE_ITEM_TO_DB_FAIL,
                payload: errorHandler(error),
            });
        }
    };

export const removeItemInCart =
    (cartId) => async (dispatch, getState) => {
        dispatch({
            type: CART_REMOVE_ITEM,
            payload: {
                id: cartId,
            },
        });
        localStorage.setItem(
            "cartItems",
            JSON.stringify(getState().cart.cartItems)
        );
    };

export const moveCartToDB = (cartItems) => async (dispatch, getState) => {
    const { userInfo } = getState().userAuth;
    try {
        dispatch({ type: MOVE_CART_ITEM_TO_DB_REQUEST });

        const { data } = await product.post(
            `/user/${userInfo._id}/move-cart-to-db`,
            { cartItems },
            configUtil(userInfo.token)
        );

        dispatch({ type: MOVE_CART_ITEM_TO_DB, payload: data });

        // remove locally stored cart item
        localStorage.setItem("cartItems", JSON.stringify(data));
    } catch (error) {
        dispatch({
            type: MOVE_CART_ITEM_TO_DB_FAIL,
            payload: errorHandler(error),
        });
    }
};

export const saveShippingAddress = (data) => async (dispatch) => {
    dispatch({
        type: CART_SAVE_SHIPPING_ADDRESS,
        payload: data,
    });
    localStorage.setItem("shippingAddress", JSON.stringify(data));
};

export const saveUserShippingInfo = (data) => async (dispatch) => {
    dispatch({
        type: CART_SAVE_USER_SHIPPING_INFO,
        payload: data,
    });
    localStorage.setItem("userShippingInfo", JSON.stringify(data));
};

export const saveBillingAddress = (data) => async (dispatch) => {
    dispatch({
        type: CART_SAVE_BILLING_ADDRESS,
        payload: data,
    });
    localStorage.setItem("billingAddress", JSON.stringify(data));
};

export const saveUserBillingInfo = (data) => async (dispatch) => {
    dispatch({
        type: CART_SAVE_USER_BILLING_INFO,
        payload: data,
    });
    localStorage.setItem("userBillingInfo", JSON.stringify(data));
};

export const savePaymentMethod = (paymentMethod) => async (dispatch) => {
    dispatch({
        type: CART_SAVE_PAYMENT_METHOD,
        payload: paymentMethod,
    });
    localStorage.setItem("paymentMethod", JSON.stringify(paymentMethod));
};
