import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/message";
import Loader from "../components/loader";
import { register } from "../actions/user";
import FormComponent from "../components/formComponent";
import '../Utils/css/auth.css';
import { validation } from '../Utils/translateLibrary/validation';
import { getUserIdent } from "../Utils/helpers/user";

const RegisterScreen = ({ location, history }) => {
    const [username, setUsername] = useState();
    const [email, setEmail] = useState("");
    const [userRole, setRole] = useState("user");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordMessage, setPasswordMessage] = useState("");
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
    const [validPassword, setValidPassword] = useState(false);
    const [validConfirmPassword, setValidConfirmPassword] = useState(false);
    const [acceptedTerms, setAcceptedTerms] = useState(false);

    // ReCaptcha
    const reRef = useRef();

    const redirect = location.search ? location.search.split("=")[1] : "/";
    const dispatch = useDispatch();

    const userRegister = useSelector((state) => state.userRegister);
    const { loading, error } = userRegister;
    const userAuth = useSelector((state) => state.userAuth);
    const { userInfo } = userAuth;

    // Settings Reducer
    const settings = useSelector((state) => state.settings);
    const { language } = settings;

    useEffect(() => {
        if (userInfo) {
            history.push(redirect);
        }
    }, [history, userInfo, redirect]);

    const submitHandler = async (e) => {
        e.preventDefault();

        const token = await reRef.current.executeAsync();
        reRef.current.reset();

        if (
            username !== "" &&
            email !== "" &&
            validPassword &&
            validConfirmPassword
        ) {
            dispatch(register(username, email, password, userRole, undefined, token, getUserIdent(username)));
        }
    };

    const passwordOnChangeHandler = (e) => {
        let p = e.target.value;
        setPassword(p);
        if (p.length < 8) {
            setPasswordErrorMessage(
                validation.errors.min_length_password[language]
            );
            setValidPassword(false);
        } else {
            setPasswordErrorMessage(null);
            setValidPassword(true);
        }
    };
    const passwordConfirmOnChangeHandler = (e) => {
        const cPassword = e.target.value;
        setConfirmPassword(cPassword);
        if (password !== cPassword) {
            setPasswordMessage(validation.errors.password_not_matching[language]);
            setValidConfirmPassword(false);
        } else if (cPassword.length < 8) {
            setPasswordMessage(
                validation.errors.min_length_password[language]
            );
            setValidConfirmPassword(false);
        } else {
            setPasswordMessage("");
            setValidConfirmPassword(true);
        }
    };

    return (
        <div className="auth-content" style={{backgroundImage: "url('/images/honey-background.jpg')"}}>
            <div className="auth-form">
                <h1>Registrieren</h1>
                {error && <Message variant="danger">{error}</Message>}
                {loading && <Loader />}
                <Form onSubmit={submitHandler} autoComplete="on">
                    <FormComponent
                        label="Benutzername"
                        type="text"
                        value={username}
                        placeholder="Gib deinen Nutzernamen ein"
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <FormComponent
                        label="Email"
                        type="email"
                        value={email}
                        placeholder="Email eingeben"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <FormComponent
                        label="Passwort"
                        type="password"
                        value={password}
                        placeholder="Passwort eingeben"
                        errorMessage={passwordErrorMessage}
                        valid={validPassword}
                        onChange={passwordOnChangeHandler}
                    />

                    <FormComponent
                        label="Passwort bestätigen"
                        type="password"
                        value={confirmPassword}
                        placeholder="Passwort bestätigen"
                        errorMessage={passwordMessage}
                        valid={validConfirmPassword}
                        onChange={passwordConfirmOnChangeHandler}
                    />

                    <div className="role-container"> 
                        <Button onClick={() => setRole('user')} className={'w-100 ' + (userRole === 'user' ? 'active' : '')}>Honig kaufen</Button>
                        <Button onClick={() => setRole('seller')} className={'w-100 ' + (userRole === 'seller' ? 'active' : '')}>Honig verkaufen</Button>
                    </div> 

                    <div className="checkbox"> 
                        <div className="checkboxAGB" style={{display:"flex"}}>
                        <input onChange={(e) => setAcceptedTerms(e.target.value)} type="checkbox" id="AGB" name="AGB" value="AGB"/>
                        <label htmlFor="AGB"> Ich habe die <u>AGB</u> gelesen und akzeptiere sie.</label><br />
                        </div> 

                        <div className="checkboxNEWSLETTER mt-3" style={{display:"flex"}}>
                        <   input type="checkbox" id="NEWSLETTER" name="NEWSLETTER" value="NEWSLETTER"/>
                            <label htmlFor="NEWSLETTER"> Ich möchte über aktuelle Angebote informiert werden.</label><br />
                        </div>
                    </div> 

                    <div className="py-3">
                        <Button
                            type="submit"
                            variant="primary"
                            style={{
                                backgroundColor: "var(--primary)",
                                color: "#000",
                                borderColor: "#000000",
                                fontSize: "large",
                                width: "100%",
                                height:"45px",
                                borderRadius: "40px"
                                }}

                            disabled={
                                username === "" ||
                                email === "" ||
                                !validPassword ||
                                !validConfirmPassword ||
                                !acceptedTerms
                            } 
                        >
                            Registrieren
                        </Button>
                    </div>
                </Form>

                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}
                    size="invisible"
                    ref={reRef}
                />

                <Row className="py-3">
                    <Col>
                        Sie haben bereits ein Konto?{" "}
                        <Link
                            to={redirect ? `/login?redirect=${redirect}` : "/login"}
                        >
                            Login
                        </Link>
                    </Col>
                </Row>
                </div>
        </div>
    );
};

export default RegisterScreen;
