import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import '../Utils/css/footer.css';
import { categories } from "../Utils/translateLibrary/product";
import { useSelector } from "react-redux";

const Footer = () => {
    const settings = useSelector((state) => state.settings);
    const { language } = settings;

    return (
        <footer className="mt-auto">
            <img src="/images/footer_bluete.png" className="hidden md:block side-img" alt="flower"></img>
            <div className="p-2 footer">
                <Container className="mb-3 section">
                    <div className="md:flex">
                        <Col>
                            <h5 className="title">Kontakt</h5>
                            <ul>
                                <li className="d-flex">
                                    <div className="icon-col">
                                        <i className="fa fa-map"></i>
                                    </div>
                                    <p>
                                        Kilianstädter Str. 50<br/>
                                        61137 Schöneck<br/>
                                        Deutschland
                                    </p>
                                </li>
                                <li className="d-flex">
                                    <div className="icon-col">
                                        <i className="fa fa-phone"></i>
                                    </div>
                                    <p><a href="tel:+49(0)6187/2075786">+49(0)6187/2075786</a></p>
                                </li>
                                <li className="d-flex">
                                    <div className="icon-col">
                                        <i className="fa fa-envelope"></i>
                                    </div>
                                    <p><a href="mailto:info@business-biene.de">info@business-biene.de</a></p>
                                </li>
                            </ul>
                        </Col>
                        <Col>
                            <h5 className="title">Kategorie</h5>
                            <ul>
                                <li className="my-2"><Link to="/search?category=0">{categories[0][language]}</Link></li>
                                <li className="my-2"><Link to="/search?category=2">{categories[2][language]}</Link></li>
                                <li className="my-2"><Link to="/search?category=10">{categories[10][language]}</Link></li>
                                <li className="my-2"><Link to="/search?category=19">{categories[19][language]}</Link></li>
                            </ul>
                        </Col>
                        <Col>
                            <h5 className="title">Netzwerk</h5>
                            <ul>
                                <li className="my-2"><a href="https://www.bienenzucht-profi.de" target="_blank" rel="noreferrer">www.bienenzucht-profi.de</a></li>
                                <li className="my-2"><a href="https://www.business-biene.de" target="_blank" rel="noreferrer">www.business-biene.de</a></li>
                            </ul>
                        </Col>
                        <Col>
                            <h5 className="title">Informationen</h5>
                            <ul>
                                <li><Link to="/imprint">Impressum</Link></li>
                                <li><Link to="/agb">AGB</Link></li>
                                <li><Link to="/data-protection">Datenschutzerklärung</Link></li>
                            </ul>
                        </Col>
                    </div>
                </Container>
                <div className="section social-icons-container">
                        <div className="social-icon-wrapper">
                            <a href="https://www.facebook.com/honigfinder/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={ faFacebook } size="xl"></FontAwesomeIcon>
                            </a>
                        </div>
                        <div className="mx-3 social-icon-wrapper">
                            <a href="https://www.instagram.com/honigfinder/?hl=de/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={ faInstagram } size="xl"></FontAwesomeIcon>
                            </a>
                        </div>
                        <div className="social-icon-wrapper">
                            <Link to="/kontakt">
                                <FontAwesomeIcon icon={ faEnvelope } size="xl"></FontAwesomeIcon>
                            </Link> 
                        </div>
                </div>

                <img className="small-icon" src="/images/biene.svg" alt="biene"></img>
            </div>
        </footer>
    );
};

export default Footer;
