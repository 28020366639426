import React, { useState, useEffect } from 'react'
import {
  Form,
  Button,
  InputGroup,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../../../components/formContainer';
import Message from '../../../components/message'
import Loader from '../../../components/loader'
import FormComponent from '../../../components/formComponent'
import { Link } from 'react-router-dom'
import { productObject } from '../../../Utils/createProduct'
import { createProduct, editProduct} from '../../../actions/product'
import ShowImage from '../../../components/showImage';
import { p } from "../../../Utils/translateLibrary/productDetails";
import { validation } from '../../../Utils/translateLibrary/validation'; 
import { listProductDetails } from "../../../actions/product";
import { colors, consistencies, categories, shipmentTypes } from '../../../Utils/translateLibrary/product';
import { deleteProductImage, uploadProductImage } from '../../../actions/image';
import { EDIT_PRODUCT_RESET, IMAGE_DELETE_RESET, IMAGE_UPLOAD_RESET } from '../../../actions/types';
import "../../../Utils/css/table.css";

const SellerEditOrCreateProductScreen = ({ match, history }) => {  
  const productId = match.params.id;

  const [name, setName] = useState('')
  const [category, setCategory] = useState(0)
  const [description, setDescription] = useState('')
  const [consistency, setConsistency] = useState(0)
  const [price, setPrice] = useState('')
  const [discount, setDiscount] = useState();
  const [color, setColor] = useState(0);
  const [weights, setWeights] = useState([]);
  const [newWeight, setNewWeightObj] = useState({weight: '', quantity: ''});
  const [shipmentType, setShipmentType] = useState(0);

  // todo validate whole Form
  const isValid = () => {
    return weights.length > 0 && price > 0 && name !== '';
  }

  // product Details Reducers
  let isEdit = productId !== null && productId !== undefined;
  let stateKey;
  if(isEdit) {
    stateKey = 'productDetails';
  } else {
    stateKey = 'productCreate';
  }
    
  const productData = useSelector((state) => state[stateKey]);
  const { loading, error, product } = productData;
  const updateProductResult = useSelector((state) => state['productEdit']);
  const { success } = updateProductResult;
  const [ hasReducedPrice, setHasReducedPrice ] = useState(false);

  const dispatch = useDispatch()
  const userAuth = useSelector((state) => state.userAuth)
  const { userInfo } = userAuth;

  const settings = useSelector((state) => state.settings);
  const { language } = settings;

  // file input state
  const [previewSource, setPreviewSource] = useState([])
  const [validationError, setErrorMessage] = useState('');

  const uploadImage = useSelector((state) => state.uploadImage);
  const { imageUploadLoading, imageUploadSuccess, imageUploadError, images } = uploadImage;

  const deleteImage = useSelector((state) => state.deleteImage);
  const { imageDeleteSuccess, imageDeleteError } = deleteImage;

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    }

    if (userInfo.role !== 'seller') {
      history.push('/')
    }
    
    if(isEdit) {
      if (product && product._id === productId) {
          setName(product.name);
          setCategory(product.category);
          setDescription(product.description);
          setConsistency(product.consistency);
          setPrice(product.price);
          setDiscount(product.discount);
          setColor(product.color);
          setWeights(product.weights);
          setHasReducedPrice(product.discount != null);
          setShipmentType(product.shipmentType);
          setPreviewSource(images || product.images || []);
      } else {
        dispatch(listProductDetails(productId));
      }
}
  }, [dispatch, history, match, userInfo, product, productId, isEdit, images])

  useEffect(() => {
    return () => {
      dispatch({type: IMAGE_DELETE_RESET});
      dispatch({type: IMAGE_UPLOAD_RESET});
      dispatch({type: EDIT_PRODUCT_RESET});
    };
  }, [dispatch]);

  const validate = () => {
    if(weights.length === 0) {
      throw new Error(validation.errors.required_weight[language]);
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    window.scrollTo({top: 0, behavior: 'smooth'});

    try {
      validate();
    } catch (validationError) {
      setErrorMessage(validationError.message);
      return;
    }

    const productObj = productObject(
      userInfo._id,
      name,
      category,
      price,
      hasReducedPrice ? discount : '',
      consistency,
      color,
      description,
      weights,
      images,
      shipmentType
    );
    
    if(isEdit) {
      dispatch(editProduct(productObj, productId));
      return;
    }

    dispatch(createProduct(productObj))
  }

  const handleFileInputChange = (e) => {
    const file = e.target.files[0]
    if (!file) {
      return
    }
    previewFile(file)
  }
  
  const previewFile = (file) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = () => {
            dispatch(uploadProductImage(product._id, reader.result));
        }
  }

  const handleRemoveImage = (index) => {
      let _file = previewSource[index];
      dispatch(deleteProductImage(product._id, _file));
      previewSource.splice(index, 1)
      setPreviewSource(previewSource);
  }

  const addNewRow = (e) => {
    e.preventDefault();
    
    // validation
    if(newWeight.weight < 1 || newWeight.quantity < 1) {
      setErrorMessage(validation.errors.required_weight_field[language]);
      return;
    }

    const _foundIndex = weights.findIndex(productWeight => productWeight.weight === newWeight.weight);
    if(_foundIndex > -1) {
      setErrorMessage(validation.errors.duplicate_weight[language]);
      return;
    }

    setErrorMessage('');

    // add new row
    let currentWeightState = [...weights];
    currentWeightState.push(newWeight)
    setWeights(currentWeightState);
    setNewWeightObj({weight: '', quantity: ''});
  };

  const removeRow = (e, index) => {
    e.preventDefault();
    
    let currentWeightState = [...weights];
    currentWeightState.splice(index, 1);
    setWeights(currentWeightState);
  };

  return (
    <>
      <Link to="/seller/products" className="btn btn-primary my-3">
        {p.goBack[language]}
      </Link>
      {success && <Message variant="success">{success}</Message>}
      <FormContainer>
        <h1 className='m-0 pt-2 p-0'>{isEdit ? p.update[language] : p.create[language] }</h1>
        {error && <Message variant="danger">{error}</Message>}
        {validationError && <Message variant="danger">{validationError}</Message>}

        {loading ? (
          <Loader />
        ) : (
          <Form onSubmit={submitHandler} autoComplete="on">
            <div className='d-flex w-100'>
              <div className='col mr-3'>
                  <FormComponent
                    label={p.name[language]}
                    type="text"
                    value={name}
                    placeholder="..."
                    onChange={(e) => setName(e.target.value)}
                  />

                  <Form.Group controlId="formBasicSelect" className='py-2'>
                    <Form.Label style={{ fontWeight:"bolder"}}>{p.category[language]}</Form.Label>
                    <Form.Control
                      as="select"
                      value={category}
                      className="input"
                      onChange={e => {
                        setCategory(e.target.value);
                      }}
                    >
                      {Object.values(categories).map((category, key) => {
                        return (
                          <option value={key} key={'category_' + key}>{category[language]}</option>
                        )
                      })}
                    </Form.Control>
                </Form.Group>
                  
                  <FormComponent
                    label={p.price[language]}
                    value={price}
                    type="number"
                    step="0.01"
                    placeholder="9.95"
                    onChange={(e) => setPrice(e.target.value)}
                  />

                  <InputGroup className='form-group'>
                    <Form.Label onClick={(e) => setHasReducedPrice(!hasReducedPrice)} role="button" className="input-label">{p.reducedPrice[language]}</Form.Label>
                    <input type="checkbox" checked={hasReducedPrice} onChange={(e) => setHasReducedPrice(!hasReducedPrice)} />
                  </InputGroup>

                  {hasReducedPrice && 
                  <FormComponent
                    label={p.discount[language]}
                    value={discount}
                    type="number"
                    step="0.01"
                    placeholder="4.95"
                    onChange={(e) => setDiscount(e.target.value)}
                  />}
              </div>
                      
              <div className='col flex-col'>
                <Form.Group controlId="formBasicSelect" className='py-2'>
                    <Form.Label style={{ fontWeight:"bolder"}}>{p.consistency[language]}</Form.Label>
                    <Form.Control
                      as="select"
                      value={consistency}
                      className="input"
                      onChange={e => {
                        setConsistency(e.target.value);
                      }}
                    >
                      {Object.values(consistencies).map((consistency, key) => {
                        return (
                          <option value={key} key={'consistency_' + key}>{consistency[language]}</option>
                        )
                      })}
                    </Form.Control>
                </Form.Group>
                
                <Form.Group controlId="formBasicSelect" className='py-2'>
                  <Form.Label style={{ fontWeight:"bolder"}}>Farbe</Form.Label>
                  <Form.Control
                    as="select"
                    value={color}
                    className="input"
                    onChange={e => {
                    setColor(e.target.value);
                  }}
                  >
                    {Object.values(colors).map((color, key) => {
                      return (
                        <option value={key} key={'color_' + key}>{color[language]}</option>
                      )
                    })}
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="formBasicSelect" className='py-2'>
                  <Form.Label style={{ fontWeight:"bolder"}}>{p.shipmentType[language]}</Form.Label>
                  <Form.Control
                    as="select"
                    value={shipmentType}
                    className="input"
                    onChange={e => {
                    setShipmentType(e.target.value);
                  }}
                  >
                    {Object.values(shipmentTypes).map((type, key) => {
                      return (
                        <option value={key} key={'shipment_' + key}>{type[language]}</option>
                      )
                    })}
                  </Form.Control>
                </Form.Group>
                
                <div className='py-2'>
                  <Form.Label style={{ fontWeight:"bolder"}}>{p.description[language]}</Form.Label>
                  <textarea onChange={(e) => setDescription(e.target.value)} value={description} placeholder="..." rows={3} className="w-100 input"></textarea>
                </div>
              </div>
            </div>

            <div className='mt-3'>
              <table className='table weight-table'>
                    <thead>
                      <tr>
                        <th className='header-col'>{ p.weightInGramm[language] }</th>
                        <th className='header-col'>{ p.quantity[language] }</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {weights.map((productWeight, index) => {
                        return (
                          <tr key={'weight_' + productWeight.weight}>
                            <td>
                              <Form.Control
                                className='input'
                                type="number"
                                step="0.01"
                                defaultValue={productWeight.weight}
                                onInput={(e) => productWeight.weight = Number(e.target.value)}
                              >
                              </Form.Control>
                            </td>
                            <td>
                              <Form.Control
                                className='input'
                                type="number"
                                step="0.01"
                                defaultValue={productWeight.quantity}
                                onInput={(e) => productWeight.quantity = Number(e.target.value)}
                              >
                              </Form.Control>
                            </td>
                            <td>
                            <Button
                              onClick={(e) => removeRow(e, index)}
                            >
                              <i className="fas fa-minus text-center" />
                            </Button>
                            </td>
                          </tr>
                        );
                        })
                      }
                      <tr>
                        <td>
                          <Form.Control
                            className='input'
                            type="number"
                            step="0.01"
                            placeholder="250"
                            value={newWeight.weight}  
                            onChange={e => setNewWeightObj({quantity: newWeight.quantity, weight: Number(e.target.value)})}
                          >
                          </Form.Control>
                        </td>
                        <td>
                          <Form.Control
                            className='input'
                            type="number"
                            placeholder="1"
                            value={newWeight.quantity}  
                            onChange={e => setNewWeightObj({quantity: Number(e.target.value), weight: newWeight.weight})}
                          >
                          </Form.Control>
                        </td>
                        <td>
                          <Button
                            onClick={addNewRow}
                          >
                            <i className="fas fa-plus text-center" />
                          </Button>
                        </td>
                      </tr>
                    </tbody>
              </table>
            </div>

            {imageUploadError && <Message variant="danger">{imageUploadError}</Message>}
              {imageUploadSuccess && (
                <Message variant="success">{"Bild erfolgreich hochgeladen"}</Message>
              )}
              {imageDeleteError && <Message variant="danger">{imageDeleteError}</Message>}
              {imageDeleteSuccess && (
                <Message variant="success">{"Bild erfolgreich gelöscht"}</Message>
              )}

            <div className='mt-3'>
              <Form.Label style={{ fontWeight:"bolder"}}>{p.images[language]}</Form.Label>
                <Form.Group className="mb-3">
                  <Form.Control disabled={previewSource.length >= 4 || imageUploadLoading} accept="image/jpeg, image/png" type="file" onChange={handleFileInputChange} />
                </Form.Group>
                <ShowImage
                  create
                  source={previewSource}
                  handleRemoveImage={(index) => handleRemoveImage(index)}
              />
            </div>

            <>
              <div className="py-4 d-flex">
                <Button type="submit" variant="primary" disabled={!isValid()} className='m-auto btn--with-padding'>
                  {isEdit ? p.update[language] : p.create[language] }
                </Button>
              </div>
            </>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default SellerEditOrCreateProductScreen;