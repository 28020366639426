import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listProductDetails } from "../../actions/product";
import Loader from "../../components/loader";
import Message from "../../components/message";
import ImageCarousel from "../../components/imageCarousel";
import PaymentMethods from "../../components/paymentMethods";
import {
    Row,
    Col,
    ListGroup,
    Card,
    Carousel,
    Button,
} from "react-bootstrap";
import { Image } from "cloudinary-react";
import { p } from "../../Utils/translateLibrary/productDetails";
import { colors, consistencies } from "../../Utils/translateLibrary/product";
import {
    addToCart,
    addToCartDB,
} from "../../actions/cart";
import { validation } from '../../Utils/translateLibrary/validation'; 
import '../../Utils/css/product.css';

const ProductScreen = ({ history, match }) => {
    const [imageIndex, setImageIndex] = useState(0);
    const [carouselIndex, setCarouselIndex] = useState(0);
    const [selectedWeight, setSelectedWeight] = useState('');
    const [validationError, setErrorMessage] = useState('');
    const [tab, setTab] = useState(1);

    const [qty, setQty] = useState(1);

    const dispatch = useDispatch();
    const productDetails = useSelector((state) => state.productDetails);
    const { loading, error, product } = productDetails;

    const userAuth = useSelector((state) => state.userAuth);
    const { userInfo } = userAuth;

    const settings = useSelector((state) => state.settings);
    const { language } = settings;

    useEffect(() => {
        dispatch(listProductDetails(match.params.id));
    }, [dispatch, match]);

    const validate = () => {
        if(!selectedWeight || selectedWeight.weight <= 0) {
            throw new Error(validation.errors.required_selected_weight[language]);
        }
        
        if(qty <= 0 || qty > selectedWeight.quantity) {
            throw new Error(validation.errors.invalid_quantity[language]);
        }
    }

    const addToCartHandler = () => {
        try {
            validate();
          } catch (validationError) {
            setErrorMessage(validationError.message);
            return;
          }

          if (userInfo) {

            // Get the cart in database and add the onces from local storage
            dispatch(addToCartDB(product._id, qty, selectedWeight.weight, product.userId));
        } else {
            dispatch(addToCart(product._id, qty, selectedWeight.weight, product.userId));
        }

        history.push(
            `/cart/${match.params.id}`
        );
    };

    const handleSelectImage = (index) => {
        setImageIndex(index);
        setCarouselIndex(index);
    };
    
    const getImages = () => {
        if(!product) {
            return [];
        }

        const _images = product.images;
        if(!_images || _images.length === 0) {
            return [process.env.REACT_APP_DEFAULT_PRODUCT_IMAGE];
        }

        return _images;
    }

    const quantityOnChangeHandler = (qty) => {
        if(qty <= 0) {
            setQty(0);
            return;
        }

        setQty(qty);
    };

    return (
        <>
            <div className="d-flex">
                <div className="col-md-2 my-2">
                    <div className="btn btn-primary"
                        onClick={() => window.history.back()}
                        >
                        {p.goBack[language]}
                    </div>
                </div>
                <div className="col-md-8">
                    {validationError && <Message variant="danger">{validationError}</Message>}
                </div>
                <div className="col-md-2"></div>
            </div>
            {product ? (
                <>
                    <Row className="justify-content-md-center">
                        <Col md={8} lg={5}>
                            <Row>
                                <Carousel
                                    activeIndex={imageIndex}
                                    onSelect={handleSelectImage}
                                    variant="dark"
                                >
                                    {getImages().map((im, i) => {
                                        return (
                                            <Carousel.Item
                                                key={'carousel_' + i}
                                                style={{ height: "500px" }}
                                            >
                                                <Image
                                                    className="d-block w-100"
                                                    cloudName='dycgvrxas'
                                                    publicId={im}
                                                    style={{
                                                        objectFit: "cover",
                                                    }}
                                                />
                                            </Carousel.Item>
                                        );
                                    })}
                                </Carousel>

                                <ImageCarousel
                                    images={getImages()}
                                    colorIndexOnClick={handleSelectImage}
                                    carouselIndex={carouselIndex}
                                />
                            </Row>
                        </Col>
                        <Col md={4} lg={5} >
                            <Card>
                                <ListGroup
                                    variant="flush"
                                    className="borderless"
                                >
                                    <ListGroup.Item variant="fluid">
                                        <h2 className="title">{product.name}</h2>
                                    </ListGroup.Item>
                                    <ListGroup.Item variant="fluid">
                                        <Row className="price-wrapper">
                                                    {product.discount > 0 ? (
                                                        <Col md="auto">
                                                            <strong className="d-flex justify-content-start">
                                                                <span className="strikediag withpadding text-danger ml-2">
                                                                    {product?.price.toFixed(2)} €
                                                                </span>
                                                            </strong>
                                                        </Col>
                                                    ) : (
                                                        <Col  md={5}>
                                                            <strong className="d-flex ml-2 justify-content-start">
                                                                {product?.price.toFixed(2)} € 
                                                            </strong>
                                                        </Col>
                                                    )}

                                                    {product?.discount > 0 ? (
                                                        <Col className="d-flex text-success ml-2 justify-content-start">
                                                            <strong>
                                                                { product?.discount.toFixed(2) } € 
                                                            </strong>
                                                        </Col>
                                                    ) : null}
                                                </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item className="d-flex">
                                        {product.weights.map((productWeight) => {
                                            return (
                                                <div className="weight-wrapper" key={'weight_' + productWeight.weight}>
                                                    <Button variant={
                                                        productWeight.weight === selectedWeight.weight
                                                        ? "primary"
                                                                : "light"
                                                            }
                                                            onClick={() => setSelectedWeight(productWeight)}
                                                            key={'weight_button_' + productWeight.weight}
                                                            >
                                                        {productWeight.weight + ' g'}
                                                    </Button>
                                                </div>
                                            )
                                        })}
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        {product.feature}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col md={7}>
                                                {p.size[language]}:
                                            </Col>
                                            <Col md={5}>
                                                { selectedWeight.weight > 0 ? (
                                                selectedWeight.weight + ' g')
                                                : ('Keine') }
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col md={7}>
                                                {p.color[language]}:
                                            </Col>
                                            <Col md={5}>{colors[product.color][language]}</Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col md={7}>
                                                {p.consistency[language]}:
                                            </Col>
                                            <Col md={5}>{consistencies[product.consistency][language]}</Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        {(!userInfo || product.userId !== userInfo._id) && (<Row className="center-items">
                                            <Col md={7} className="border d-flex large-input center-items">
                                                <div className="d-block">
                                                    <div>{p.quantity[language]}</div>
                                                    <small>{selectedWeight.quantity > 0 && ('(' + p.maxStock[language] + ' ' + selectedWeight.quantity + ')')}</small>
                                                </div>
                                                
                                                <div className="ml-auto">
                                                    <i onClick={() => quantityOnChangeHandler(qty - 1)} className="fa fa-minus" role="button"></i> 
                                                    <span className="mx-3">{qty}</span>
                                                    <i onClick={() => quantityOnChangeHandler(qty + 1)} className="fa fa-plus" role="button"></i>
                                                </div>
                                            </Col>
                                            <Col md={5}>
                                                <Button onClick={() => addToCartHandler()} disabled={!selectedWeight || selectedWeight.weight <= 0 || qty === 0} variant="primary" className="w-100 border-r-0 large-btn">{p.addToCart[language]}</Button>
                                            </Col>
                                        </Row>)}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        {p.payment[language]}
                                        <PaymentMethods />
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card>
                        </Col>
                    </Row>
                    <div className="section product-details-section">
                        <div className="tab-container">
                            <h2 onClick={() => setTab(1)} role="button">Beschreibung</h2>
                            <h2 onClick={() => setTab(2)} role="button">Bewertungen (0)</h2>
                        </div>
                        <div className={tab !== 1 ? 'hidden' : ''}>
                            {product.description}
                        </div>

                        <div className={tab !== 2 ? 'hidden' : ''}>
                            Noch keine Bewertungen
                        </div>
                    </div>
                </>
            ) : loading ? (
                <Loader />
            ) : error ? (
                <Message variant="danger">{error}</Message>
            ) : null}
        </>
    );
};

export default ProductScreen;
