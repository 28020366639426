import React, { useState, useEffect } from "react";
import { Button, Row, Col, ListGroup, Card, Container, Table } from "react-bootstrap";
import { Image } from "cloudinary-react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Message from "../components/message";
import Loader from "../components/loader";
import {
    getOrderDetails,
    createSessionCheckoutWithStripe,
    payOrder,
    deliverOrder,
} from "../actions/order";
import '../Utils/css/web.css';

import { addDecimals } from "../Utils/addDecimals";
import { paymentDecode } from "../Utils/paymentDecode";
// Handling Stripe
import { useStripe } from "@stripe/react-stripe-js";
// Handling Paypal orders
import paypalOrderScript from "../apis/api";
import { PayPalButton } from "react-paypal-button-v2";
import { ORDER_PAY_RESET, ORDER_DELIVER_RESET } from "../actions/types";
import {
    currencyDecoder,
    stripeCurrencyDecoder,
} from "../Utils/currencyDecoder";
import { configUtil } from "../Utils/apiConfig";
import {summary,g} from '../Utils/translateLibrary/orderSummary';
import {p} from '../Utils/translateLibrary/productDetails';
import { colors } from "../Utils/translateLibrary/product";
import { payT } from "../Utils/translateLibrary/paymentMethod";
import '../Utils/css/order.css';
import { getAddressInfoInline } from "../Utils/helpers/address";
import { formatDate } from "../Utils/helpers/date";
import { isAdmin } from "../Utils/helpers/user";

const OrderScreen = ({ history, match }) => {
    const orderId = match.params.id;

    // State
    const [buttonLoading, setButtonLoading] = useState(false);
    const [sdkReady, setSDKReady] = useState(false);
    const [sdkLoading, setSDKLoading] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    // Order Reducer
    const orderDetails = useSelector((state) => state.orderDetails);
    const { order, loading, error } = orderDetails;

    // Stripe Order Session
    const checkoutWithStripe = useSelector((state) => state.checkoutWithStripe);
    const { session, sessionId, sessionError } = checkoutWithStripe;

    // UserAuth Reducer
    const userAuth = useSelector((state) => state.userAuth);
    const { userInfo } = userAuth;

    // Paypal reducer
    const orderPay = useSelector((state) => state.orderPay);
    const { paypalLoading, paypalSuccess } = orderPay;

    // Settings Reducer
    const settings = useSelector((state) => state.settings);
    const { currency, language } = settings;

    // Order Deliver Reducer
    const orderDeliver = useSelector((state) => state.orderDeliver);
    const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

    const dispatch = useDispatch();
    const stripe = useStripe();

    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [checkoutState, setCheckoutState] = useState(params.get('checkout') || '');

    useEffect(() => {
        const addPayPalScript = async () => {
            if(sdkReady || sdkLoading) {
                return;
            }

            setSDKLoading(true);
            const { data: clientId = process.env.PAYPAL_CLIENT_ID } = await paypalOrderScript.get(
                "/config/paypal",
                configUtil(userInfo.token)
            );

            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=EUR`;
            script.async = true;
            script.onload = () => {
                setSDKLoading(false);
                setSDKReady(true);
            };
            document.body.appendChild(script);
        };

        if (order) {
            if (order._id !== orderId) {
                dispatch(getOrderDetails(orderId));
            }
        }

        if (!order || paypalSuccess || successDeliver || (checkoutState === 'success')) {
            dispatch({ type: ORDER_PAY_RESET });
            dispatch({ type: ORDER_DELIVER_RESET });
            dispatch(getOrderDetails(orderId));
            setCheckoutState('');
            
            setTimeout(() => {
                if(!sessionError){
                    setShowSuccessMessage(false);
                }
            }, 5000)
        } else if (!order.isPaid) {
            if (!window.paypal) {
                addPayPalScript();
            } else {
                setSDKReady(true);
            }
        }

        if (!orderId) {
            history.push("/place-order");
        }
        if (!userInfo) {
            history.push("/login");
        }

        if (session) {
            setButtonLoading(true);
        }

        if (sessionError) {
            setButtonLoading(false);
        }
        
        if (sessionId) {
            const redirectToCheckout = () => {
                stripe.redirectToCheckout({
                    sessionId,
                });
            };
            
            redirectToCheckout();
        }
    }, [
        dispatch,
        history,
        order,
        orderId,
        sessionId,
        session,
        userInfo,
        stripe,
        paypalSuccess,
        sessionError,
        successDeliver,
        sdkReady,
        sdkLoading,
        checkoutState
    ]);

    // added history and session error to the dependency array, if any bugs occure, remove these two

    // Paypal payment handler
    const paypalSuccessPaymentHandler = (paymentResult) => {
        dispatch(payOrder(orderId, paymentResult));
        setCheckoutState('success');
        if(!paymentResult){
            setShowSuccessMessage(true);
        }
    };

    // Stripe payment handler
    const placeOrderHandler = () => {
        const line_items = order.orderItems.map((item) => {
            return {
                price_data: {
                    currency: stripeCurrencyDecoder(currency),
                    product_data: {
                        name: item.name,
                        description: item.description
                            ? item.description
                            : "Keine Beschreibung vorhanden",
                        images: process.env.REACT_APP_DEFAULT_PRODUCT_IMAGE,
                    },
                    unit_amount: item.discount
                                ? item.discount * 100
                                : item.price * 100
                },
                quantity: item.qty,
            };
        });

        dispatch(
            createSessionCheckoutWithStripe(
                line_items,
                order.user.email,
                [paymentDecode(order.paymentMethod)],
                orderId,
                order.shippingCost,
                order.tax,
                stripeCurrencyDecoder(currency)
            )
        );
    };

    const deliverHandler = (e) => {
        e.preventDefault();
        if (window.confirm("Möchten Sie den Versandstatus aktualisieren ?")) {
            dispatch(deliverOrder(order._id || orderId));
        }
    };

    const contactSeller = (userId) => {
        window.location.href= '/chat/'+ userId;
    }

    return order ? (
        <>
            <Container>
            {checkoutState === 'error' && (
                    <Message variant="danger">
                        Bezahlprozess abgebrochen
                    </Message>
            )}

            {(checkoutState === 'success' || showSuccessMessage) && (
                    <Message variant="success">
                        Zahlung erfolgreich erfasst
                    </Message>
            )}
            
            {sessionError && <Message variant="danger">{sessionError}</Message>}
            
            <Row className="section">
                <Col md={9} >
                    <ListGroup variant="flush" >
                        <ListGroup.Item>
                            <h2 style={{  fontWeight:"bolder"}}>{summary.shippingInfos[language]}</h2>
                            <p>
                                <strong>{g.name[language]}:</strong>
                                {` ${order.user.name} ${order.user.surname}`}
                            </p>
                            <p>
                                <strong>{g.email[language]}: </strong>
                                <a className="text-dark"
                                    href={`mailto:${order.user.email}`}
                                >{`${order.user.email}`}</a>
                            </p>
                            <p>
                                <strong>{g.address[language]}: </strong>
                                {getAddressInfoInline(order.shippingAddress)}
                            </p>
                            {order.isDelivered ? (
                                <Message variant="success">{summary.deliveredAt[language]} {formatDate(order.deliveredAt, 'DD.MM.YYYY HH:mm')}</Message>
                            ) : (
                                <Message variant="danger">
                                    {summary.notDeliverd[language]}
                                </Message>
                            )}
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h2 style={{  fontWeight:"bolder"}}>{summary.paymentStatus[language]}</h2>
                            <p>
                                <strong>{summary.paymentMethod[language]}: </strong>
                                {payT.methods[order.paymentMethod][language]}
                            </p>
                            {order.isPaid ? (
                                <Message variant="success">{summary.paidAt[language]} {formatDate(order.paidAt, 'DD.MM.YYYY HH:mm')}</Message>
                            ) : (
                                <Message variant="danger">
                                    {summary.notPaid[language]}
                                </Message>
                            )}
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h2 style={{  fontWeight:"bolder"}}>Artikel</h2>
                            {order.orderItems.length === 0 ? (
                                <Message variant="danger">
                                    Keine Artikel gefunden
                                </Message>
                            ) : (
                                <Table striped hover responsive>
                                    <thead>
                                        <tr>
                                            <th className="hidden md:d-table-cell"></th>
                                            <th colSpan={2}>
                                                <strong className="text-primary">{p.name[language]}</strong>
                                            </th>
                                            <th colSpan={2}>
                                                <strong>{p.weight[language]}</strong>
                                            </th>
                                            <th colSpan={2} className="hidden md:d-table-cell">
                                                <strong>{summary.color[language]}</strong>
                                            </th>
                                            <th colSpan={2}>
                                                <strong>{p.price[language]}</strong>
                                            </th>
                                            <th>
                                                <strong>{p.quantity[language]}</strong>
                                            </th>
                                            <th colSpan={2}>
                                                <strong>{summary.total[language]}</strong>
                                            </th>
                                            <th>Händler kontaktieren</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {order.orderItems.map((item,index) => {
                                        return (
                                        <tr key={'order_item_' + index}>
                                            <td className="hidden md:d-table-cell">
                                                <Image  cloudName="dycgvrxas"
                                                        publicId={
                                                            process.env.REACT_APP_DEFAULT_PRODUCT_IMAGE
                                                        }
                                                        alt={item.name}
                                                        width={50}
                                                        variant="fluid"
                                                />
                                            </td>
                                            <td colSpan={2}>
                                                <Link
                                                    to={`/product/${item.productId}`}
                                                    className="text-primary"
                                                >
                                                    {item.name}
                                                </Link>
                                            </td>
                                            <td colSpan={2}>
                                                {item.weight} g
                                            </td>
                                            <td colSpan={2} className="hidden md:d-table-cell">
                                                {colors[item.color][language]}
                                            </td>
                                            <td colSpan={2}>
                                                €
                                                {item.discount > 0
                                                    ? Number(item.discount).toFixed(2)
                                                    : Number(item.price).toFixed(2)}
                                            </td>
                                            <td>
                                                {item.qty} {p.pieceShort[language]}
                                            </td>
                                            <td colSpan={2}>
                                                €
                                                {item.discount > 0
                                                ? Number(item.discount * item.qty).toFixed(2)
                                                : Number(item.price * item.qty).toFixed(2)}
                                            </td>
                                            <td>{item.ownerId && (<i onClick={() => contactSeller(item.ownerId)} className="fa fa-user" role="button"></i>)}</td>
                                        </tr>
                                    )})}
                                    </tbody>
                                </Table>
                            )}
                        </ListGroup.Item>
                    </ListGroup>
                </Col>

                <Col md={3}>
                    <Card className="border">
                        <ListGroup variant="flush">
                            <ListGroup.Item style={{ backgroundColor: "var(--primary)" }}>
                                <h2 style={{  fontWeight:"bolder"}}>{summary.title[language]}</h2>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row>
                                    <Col>{summary.items[language]}</Col>
                                    <Col>€ {addDecimals(order.itemsPrice)}</Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row>
                                    <Col>{summary.shipping[language]}</Col>
                                    <Col>
                                        € {addDecimals(order.shippingCost)}
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row>
                                    <Col>{summary.tax[language]}</Col>
                                    <Col>€ {addDecimals(order.tax)}</Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item style={{ fontWeight:"bolder"}}>
                                <Row>
                                    <Col>{summary.total[language]}</Col>
                                    <Col>€ {addDecimals(order.totalPrice)}</Col>
                                </Row>
                            </ListGroup.Item>

                            {!order.isPaid && (<ListGroup.Item>
                                {!order.isPaid && !isAdmin(userInfo) ? (
                                    <div>
                                        {order.paymentMethod === "paypal" ? (
                                            <div>
                                                {paypalLoading && <Loader />}
                                                {!sdkReady ? (
                                                    <Loader />
                                                ) : (
                                                    <PayPalButton
                                                        amount={
                                                            order.totalPrice
                                                        }
                                                        currency={currencyDecoder(
                                                            currency
                                                        )}
                                                        onSuccess={paypalSuccessPaymentHandler}
                                                    />
                                                )}
                                            </div>
                                        ) : (
                                            <span className="d-grid gap-2">
                                                <Button 
                                                    type="button"
                                                    disabled={
                                                        order.orderItems
                                                            .length === 0 ||
                                                        order.isPaid ||
                                                         buttonLoading
                                                    }
                                                    onClick={placeOrderHandler}
                                                >
                                                    {summary.proceedToPayment[language]}
                                                </Button>
                                            </span>
                                        )}
                                    </div>
                                ) : !order.isPaid && isAdmin(userInfo) ? (
                                    <div>
                                        <Button className="w-100" disabled={order.orderItems.length === 0}
                                                onClick={() => paypalSuccessPaymentHandler()}
                                            >
                                                {summary.markAsPayed[language]}
                                        </Button>
                                    </div>
                                ) : null }
                            </ListGroup.Item>)}
                            {loadingDeliver && <Loader />}
                            {(userInfo &&
                                userInfo.role !== undefined &&
                                (userInfo.role === "admin" || userInfo.role === 'seller') &&
                                order.isPaid &&
                                !order.isDelivered) && (
                                    <ListGroup.Item>
                                        <span
                                            className="d-grid gap-2"
                                            onClick={deliverHandler}
                                        >
                                            <Button type="button">
                                                Lieferung versenden
                                            </Button>
                                        </span>
                                    </ListGroup.Item>
                                )}
                            {userInfo && order.isPaid && order.isDelivered && (
                                <ListGroup.Item>
                                    <span className="d-grid gap-2">
                                        <Button
                                            type="button"
                                            variant="danger"
                                            onClick={deliverHandler}
                                        >
                                            Lieferung abbrechen
                                        </Button>
                                    </span>
                                </ListGroup.Item>
                            )}
                        </ListGroup>
                    </Card>
                </Col>
            </Row>
        </Container>
        </>
    ) : loading ? (
        <Loader />
    ) : error ? (
        <Message variant="danger">{error}</Message>
    ) : (
        <></>
    );
};

export default OrderScreen;
