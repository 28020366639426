export const nav = {
    home: {
        en: "Home",
        de: "Home",
        fr: "Home",
    },
    sale: {
        en: "Shop",
        de: "Shop",
        fr: "Produit",
    },
    new: {
        en: "Home",
        de: "Startseite",
        fr: "NOUVELLES ARRIVÉES",
    },
    cart: {
        en: "Warenkorb",
        de: "Warenkorb",
        fr: "chariot",
    },
    profile: {
        en: "Profil",
        de: "Profil",
        fr: "Profil",
    },
    yourorder: {
        en:"Ihre Bestellungen",
        de: "Ihre Bestellungen",
        fr: "Vos commandes",
    },
    yourProduct: {
        en:"Your Products",
        de: "Meine Produkte",
        fr: "mes produits",
    },
    logout: {
        en:"Log out",
        de: "Ausloggen",
        fr: "Déconnexion",
    },
    signin: {
        en:"Anmelden",
        de: "Anmelden",
        fr: "S'inscrire",
    },
    register: {
        en:"Register",
        de: "Registrieren",
        fr: "",
    },
    user: {
        en: "Benutzer",
        de: "Benutzer",
        fr: "Utilisateurs",
    },
    product: {
        en:"Produkte",
        de: "Produkte",
        jpn: "Produits",
    },
    order: {
        en:"Bestellungen",
        de: "Bestellungen",
        fr: "Commandes",
    },
    chat: {
        en:"Chat",
        de: "Nachrichten",
        fr: "Message",
    }
};
