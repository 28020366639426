import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import {h} from '../Utils/translateLibrary/home';
import { useInView } from "react-intersection-observer";

export const CardComponent = () => {
    const { ref, inView } = useInView({
        threshold: 0.1,
        triggerOnce: true
      });

    return (
        <Card className="title-image">
            <Card.Img
                className="md:hidden"
                src="/images/title_image_mobile.jpg"
                alt="Title Image"
                height="100%"
                style={{ objectFit: "cover" }}
            />
            <Card.Img
                className="hidden md:block"
                src="/images/title_image.jpg"
                alt="Title Image"
                height={800}
                style={{ objectFit: "cover" }}
            />
            <Card.ImgOverlay>
                <Card.Body className={'content ' + (inView ? 'bottom-appear':  '')} ref={ref}>
                    <div className="main-content">
                        <Card.Text className="sub-title text-uppercase">
                            Von Natur aus hochwertig
                        </Card.Text>
                        <Card.Title
                            as="h1"
                            className="title text-uppercase"
                        >
                            Honigfinder
                        </Card.Title>
                        <Card.Text>
                            Hochwertiger Honig aus deiner Region, liebevoll geerntet von Imkern aus deiner Nähe. Gib deinen Standort ein und wir zeigen dir wo du flüssiges Gold frisch vom Imker deines Vertrauens bekommst.
                        </Card.Text>
                        <Link to="/seller-search" className="btn btn-primary">Jetzt testen</Link>
                    </div>
                    <div className="side-content">
                        <div className="text-amalfi mb-3">100% <br/> Natur <br/> Honig <br/></div>
                        <span className="font-weight-bold">Pur. Köstlich. Regional</span>
                    </div>
                </Card.Body>
            </Card.ImgOverlay>
        </Card>
    );
};

const handleOnClick = (link) => {
    window.open(link);
};

export const ProductsCardComponent = ({ item }) => {
    const settings = useSelector((state) => state.settings);
    const { language } = settings;

    return (
        <Card className="category-card"
            onClick={() => handleOnClick(item.link)}
        >
            <Link
                style={{ textDecoration: "none" }}
                to={item.link}
            >
                <div className="img-wrapper">
                    <Card.Img
                        src={item.image}
                        />
                </div>
                <Card.Body>
                    <Card.Title className="title text-center">
                        {item.title[language]}
                    </Card.Title>
                    <Card.Text className="text-dark text-center">
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
                    </Card.Text>
                </Card.Body>
            </Link>
        </Card>
    );
};

export const cardArr = [
    {
        title: h.types.creamy,
        link: "/search?consistency=0",
        image: "/images/kids_product.png",
    },
    {
        title: h.types.solid,
        link: "/search?consistency=1",
        image: "/images/men_product.webp",
    },
    {
        title: h.types.liquid,
        link: "/search?consistency=2",
        image: "/images/liquid_honey.jpg",
    },
];