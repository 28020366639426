import React, { useState } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";

const SearchBox = ({ history, user, placeholder, product, order, value = '' }) => {
    const [keyword, setKeyword] = useState(value);

    const submitHandler = (e) => {
        e.preventDefault();
        let role = 'admin';
        if(user && user.role && user.role !== 'support') {
            role = user.role.toLowerCase();
        }

        if (product) {
            history.push(`/${role}/products?q=${keyword}`);
            return;
        }

        if (order) {
            history.push(`/${role}/orders/?q=${keyword}`);
            return;
        }
            
        if (!user) {
            history.push(`/search?q=${keyword}`);
        } else {
            history.push(`/${role}/users?q=${keyword}`);
        }

        if (product) {
            history.push(`/${role}/products`);
            return;
        }

        if (order) {
            history.push(`/${role}/orders`);
            return;
        }
    };

    const handleOnChange = (e) => {
        setKeyword(e.target.value);
    };

    return (
        <>
            <Form onSubmit={submitHandler}>
                <InputGroup className="my-3">
                    <Form.Control
                        className="input"
                        defaultValue={keyword}
                        placeholder={placeholder}
                        onChange={handleOnChange}
                    />
                    <Button className="p-2 border-r-0 border" type="submit">
                        <i className="fas fa-search" />
                    </Button>
                </InputGroup>
            </Form>
        </>
    );
};

export default SearchBox;

//
