import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/message";
import Loader from "../components/loader";
import { login } from "../actions/user";
import { moveCartToDB } from "../actions/cart";
import FbLoginComponent from '../components/oauth/facebookLogin';
import GoogleLoginComponent from '../components/oauth/googleLogin';
import '../Utils/css/social-media.css';
import '../Utils/css/auth.css';

const FormComponent = ({ label, type, onChange, value, placeholder, labelClass, className }) => {
    return (
        <Form.Group controlId={type} className="py-2">
            <Form.Label className='font-weight-bold'>{label}</Form.Label>
            <Form.Control
                type={type}
                placeholder={placeholder}
                onChange={onChange}
                className={className}
                value={value}
            ></Form.Control>
        </Form.Group>
    );
};

const LoginScreen = ({ location, history }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const redirect = location.search ? location.search.split("=")[1] : "/";
    const dispatch = useDispatch();

    const userAuth = useSelector((state) => state.userAuth);
    const { loading, error, userInfo } = userAuth;

    const cart = useSelector((state) => state.cart);
    const { cartItems } = cart;

    const updateUserPassword = useSelector((state) => state.updateUserPassword);
    const { success } = updateUserPassword;

    useEffect(() => {
        if (userInfo) {
            dispatch(moveCartToDB(cartItems));
            history.push(redirect);
        }
    }, [dispatch, history, userInfo, redirect, cartItems]);

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(login(email, password));
    };

    return (
        <div className="auth-content" style={{backgroundImage: "url('/images/honey-background.jpg')"}}>
            <div className="auth-form">
                <h1>Login</h1>
                
                {error && <Message variant="danger">{error}</Message>}
                {loading && <Loader />}
                {success && (
                    <Message variant="success">{"Password aktualisiert"}</Message>
                )}
                <Form onSubmit={submitHandler} >
                    <FormComponent
                        label="Email"
                        type="email"
                        value={email}
                        placeholder="Email"
                        className="input"
                        onChange={(e) => setEmail(e.target.value)}
                        
                    />
                
                    <FormComponent
                        label="Passwort"
                        type="password"
                        value={password}
                        placeholder="Passwort"
                        className="input"
                        onChange={(e) => setPassword(e.target.value)}
                    />


                    <div className="py-3">
                        <Button type="submit" variant="primary"  style={{
                                backgroundColor: "var(--primary)",
                                color: "#000",
                                borderColor: "#000000",
                                fontSize:"large",
                                height: "45px",
                                borderRadius: "40px",
                                width: "100%"
                            }}>Login
                        </Button>
                    </div>
                </Form>

                <Row className="py-3" style={{textAlign: "center"}}>
                    <Col>
                        Noch kein Kunde?<br/>
                        <Link
                            to={
                                redirect
                                    ? `/register?redirect=${redirect}`
                                    : "/register"
                            }
                        >
                            Hier registrieren
                        </Link>
                    </Col>
                    <Col>
                    <Link
                            to={
                                redirect
                                    ? `/forgot-password?redirect=${redirect}`
                                    : "/forgot-password"
                            }
                        >
                            Passwort vergessen?
                        </Link>
                    </Col>
                    
                </Row>

                <div className="social-media-container">
                    <GoogleLoginComponent></GoogleLoginComponent>
                    <FbLoginComponent></FbLoginComponent>
                    {/* <AppleLoginComponent></AppleLoginComponent> */}
                </div>
            </div>
        </div>
    );
};

export default LoginScreen;
