export {g} from './globals';

export const a = {
    forgotPassword: {
        title: {
            en: "Forgot password",
            de: "Passwort vergessen",
            fr: "",
        }
    },
    pending_verification: {
        en: "Thank you for your interest. Your registration is currently under validation.",
        de: "Vielen Dank für Ihr Interesse. Ihre Registrierung wird gerade überprüft.",
        fr: "",
    }
};
