export const g = {
    from: {
        en: 'From',
        de: 'Von',
        fr: ''
    },
    to: {
        en: 'To',
        de: 'Bis',
        fr: ''
    },
    firstname: {
        en: 'First name',
        de: 'Vorname',
        fr: ''
    },
    lastname: {
        en: 'Last name',
        de: 'Nachname',
        fr: ''
    },
    name: {
        en: 'Name',
        de: 'Name',
        fr: ''
    },
    email: {
        en: 'Email',
        de: 'Email',
        fr: ''
    },
    mobile: {
        en: 'Mobile number',
        de: 'Telefonnummer',
        fr: ''
    },
    salutation: {
        en: 'Salutation',
        de: 'Anrede',
        fr: ''
    },
    address: {
        en: 'Address',
        de: 'Adresse',
        fr: ''
    },
    of: {
        en: 'of',
        de: 'von',
        fr: ''
    },
    color: {
        yellow: {
            en: "Yellow",
            de: "Gelb",
            fr: "",
        },
        brown: {
            en: "Brown",
            de: "Braun",
            fr: "",
        },
        white: {
            en: "White",
            de: "Weiß",
            fr: "",
        }
    },
    consistency: {
        creamy: {
            en: "Creamy",
            de: "Cremig",
            fr: "",
        },
        solid: {
            en: "Solid",
            de: "Fest",
            fr: "",
        },
        liquid: {
            en: "Liquid",
            de: "Flüssig",
            fr: "",
        }
    },
    category: {
        maple: {
            en: 'Maple honey',
            de: 'Ahornhonig',
            fr: ''
        },
        acacia: {
            en: 'Acacia honey',
            de: 'Akazienhonig',
            fr: ''
        },
        blossom: {
            en: 'Blossom honey',
            de: 'Blütenhonig',
            fr: ''
        },
        buckwheat: {
            en: 'Buckwheat honey ',
            de: 'Buchweizenhonig',
            fr: ''
        },
        oak: {
            en: 'Oak honey',
            de: 'Eichenhonig',
            fr: ''
        },
        fennel: {
            en: 'Fennel honey ',
            de: 'Fenchelhonig',
            fr: ''
        },
        springblossom: {
            en: 'Spring blossom honey',
            de: 'Frühjahrsblütenhonig',
            fr: ''
        },
        spruce: {
            en: 'Spruce honey',
            de: 'Fichtenhonig',
            fr: ''
        },
        heather: {
            en: 'Heather honey',
            de: 'Heidehonig',
            fr: ''
        },
        clover: {
            en: 'Clover honey',
            de: 'Kleehonig',
            fr: ''
        },
        lavender: {
            en: 'Lavender honey',
            de: 'Lavendelhonig',
            fr: ''
        },
        linden: {
            en: 'Linden honey',
            de: 'Lindenhonig',
            fr: ''
        },
        dandelion: {
            en: 'Dandelion honey',
            de: 'Löwenzahnhonig',
            fr: ''
        },
        phacelia: {
            en: 'Phacelia honey',
            de: 'Phaceliahonig',
            fr: ''
        },
        rapeseed: {
            en: 'Rapeseed honey',
            de: 'Rapshonig',
            fr: ''
        },
        summer: {
            en: 'Summer costume honey',
            de: 'Sommertrachthonig',
            fr: ''
        },
        sunflower: {
            en: 'Sunflower honey',
            de: 'Sonnenblumenhonig',
            fr: ''
        },
        fir: {
            en: 'Fir  honey',
            de: 'Tannenhonig',
            fr: ''
        },
        thyme: {
            en: 'Thyme honey',
            de: 'Thymianhonig',
            fr: ''
        },
        forest: {
            en: 'Forest honey',
            de: 'Waldhonig',
            fr: ''
        }
    },
    shipmentTypes: {
        shipment: {
            en: 'Shipment',
            de: 'Versand',
            fr: ''
        },
        pickUp: {
            en: 'Pick up',
            de: 'Abholung',
            fr: ''
        }
    },
    roles: {
        user: {
            en: 'User',
            de: 'Benutzer',
            fr: ''
        },
        admin: {
            en: 'Pick up',
            de: 'Administrator',
            fr: ''
        },
        seller: {
            en: 'Business',
            de: 'Verkäufer',
            fr: ''
        },
        support: {
            en: 'Support',
            de: 'Support',
            fr: ''
        }
    }
};