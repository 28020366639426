export {g} from './globals';

export const summary = {
    shippingInfos: {
        en: "Shipping Information",
        de: "Informationen zum Versand",
        fr: "Informations sur l'expédition",
    },
    notDeliverd: {
        en: "Not delivered",
        de: "Nicht geliefert",
        fr: "",
    },
    notPaid: {
        en: "Not paid",
        de: "Nicht bezahlt",
        fr: "",
    },
    paymentStatus: {
        en: "Payment status",
        de: "Bezahlstatus",
        fr: "",
    },
    paymentMethod: {
        en: "Payment method",
        de: "Bezahlmethode",
        fr: "",
    },
    paidAt: {
        en: "Paid at",
        de: "Bezahlt am",
        fr: "",
    },
    deliveredAt: {
        en: "Delivering started at",
        de: "Artikel versendet am",
        fr: "",
    },
    title: {
        en: "Zusammenfassung der Bestellung",
        de: "Zusammenfassung der Bestellung",
        fr: "",
    },
    shipping: {
        en: "SHIPPING",
        de: "Lieferung",
        fr: "",
    },
    payment: {
        en: "Payment Method",
        de: "Bezahlmethode",
        fr: "",
    },
    method: {
        en: "Method",
        de: "Methode",
        fr: "",
    },
    address: {
        en: "Shipping address",
        de: "Versandadresse",
        fr: "",
    },
    billingAddress: {
        en: "Billing address",
        de: "Rechnungsadresse",
        fr: "",
    },
    orderItems: {
        en: "Order Items",
        de: "Artikel",
        fr: "",
    },
    size: {
        en: "Size",
        de: "Größe",
        fr: "",
    },
    color: {
        en: "Color",
        de: "Farbe",
        fr: "",
    },
    price: {
        en: "Price",
        de: "Preis",
        fr: "",
    },
    items: {
        en: "ITEMS",
        de: "Artikel",
        fr: "",
    },
    shippingFee: {
        en: "Shipping Fee",
        de: "Versandkosten",
        fr: "",
    },
    tax: {
        en: "Tax",
        de: "Steuer",
        fr: "",
    },
    total: {
        en: "Total",
        de: "Gesamtpreis",
        fr: "",
    },
    pay: {
        en: "Pay",
        de: "Bezahlen",
        fr: "",
    },
    login: {
        en: "Logins",
        de: "Anmeldungen",
        fr: "",
    },
    placeOrder: {
        en: "Place order",
        de: "Bestellung aufgeben",
        fr: "",
    },
    success: {
        en: "Shipping Information checks out! Proceed to payment",
        de: "Bestellung erfolgreich erstellt. Weiterleitung zum Bezahlen.",
        fr: "",
    },
    proceedToPayment: {
        en: "Proceed to Payment",
        de: "Zur Bezahlung",
        fr: "",
    },
    orderNumber: {
        en: "Order Number #",
        de: "Auftragsnummer #",
        fr: "",
    },
    markAsPayed: {
        en: "Set as paid",
        de: "Als bezahlt markieren",
        fr: "",
    }
};
