export {g} from './globals';

export const c = {
    title: {
        en: "Contact us",
        de: "Kontaktformular",
        fr: "",
    },
    subject: {
        en: "Subject",
        de: "Betreff",
        fr: "",
    },
    content: {
        en: "Message",
        de: "Nachricht",
        fr: "",
    },
    female: {
        en: "Female",
        de: "Frau",
        fr: "",
    },
    male: {
        en: "Male",
        de: "Herr",
        fr: "",
    },
    selectSalutation: {
        en: "Choose a salutation",
        de: "Wählen Sie eine Anrede",
        fr: "",
    }
};

export const salutations = {
    0: c.female,
    1: c.male
};