import React , { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import {
    CardComponent,
    cardArr,
    ProductsCardComponent,
} from "../Utils/homeUIItems";
import Product from "../components/product";
import { useDispatch, useSelector } from "react-redux";
import { fetchPrioProducts } from "../actions/product";
import Loader from "../components/loader";
import Message from "../components/message";
import { newArrival } from "../Utils/translateLibrary/newArrival";
import { h } from "../Utils/translateLibrary/home";
import { useInView } from "react-intersection-observer";

const HomeScreen = ({ history, match }) => {
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');

    const settings = useSelector((state) => state.settings);
    const { language } = settings;

    const productList = useSelector((state) => state.latestProducts);
    const { loading, products, error } = productList;
    
    const [ref, inView ] = useInView({
        threshold: 0.1,
        triggerOnce: true
    })

    const [ref2, inView2 ] = useInView({
        threshold: 0.1,
        triggerOnce: true
    })

    const searchSeller = () => {
        if(search)(
            window.location.href = '/seller-search?q=' + search
        )
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchSeller();
        }
    } 

    useEffect(() => {
        dispatch(fetchPrioProducts());
    }, [dispatch]);

    return (
        <>
            <CardComponent />
            <Container>
                <Row className="d-flex justify-content-center section shop-section">
                    <h1 className="title" style={{ textAlign:"center", fontWeight:"bolder"}}>{newArrival.lastestProduct[language]}</h1>
                    <div className="my-4 content" ref={ref}>
                        <p>
                            Wir von Honigfinder glauben, es gibt kaum etwas Köstlicheres als den liebevoll 
                            geernteten Honig vom Imker aus deiner Nähe. Unterstütze regionale Imker und genieße 
                            reinen, heimischen Honig. Gib deine Adresse ein und finde deinen Lieblingshonig.
                        </p>
                        <div className="w-100 input seller-search m-auto my-3">
                            <input id="seller-search" onInput={(e) => setSearch(e.target.value)} onKeyDown={(e) => handleKeyDown(e)} placeholder="Gib deine PLZ ein und finde Honig in deiner Nähe" />
                            <i onClick={() => searchSeller()} className="fa fa-search" role="button"></i>
                        </div>
                    </div>
                    {products ? (
                        <>
                            <Row>
                                {products.map((product, i) => {
                                    return (
                                        <Col key={i} sm={12} md={6} lg={4} className={'mb-3 ' + (inView ? 'bottom-appear':  '')}>
                                            <Product
                                                product={product}
                                                lang={language}
                                                isExtended={true}
                                            />
                                        </Col>
                                    );
                                })}
                            </Row>
                        </>
                    ) : loading ? (
                        <Loader  />
                    ) : error ? (
                        <Message variant="danger">{error}</Message>
                    ) : null}
                </Row>

            </Container>

            <div className="section category-section" ref={ref2}>
                <Container>
                    <h1 className="title" style={{ textAlign:"center", fontWeight:"bolder"}}>{h.categories[language]}</h1>
                    <div className="types-grid mt-3">
                        {cardArr.map((item, idx) => (
                            <Col key={idx} md className={{'bottom-appear': inView2}}>
                                <ProductsCardComponent item={item} />
                            </Col>
                        ))}
                    </div>
                </Container>
            </div>
        </>
    );
};

export default HomeScreen;
