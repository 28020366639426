export const newArrival = {
    title: {
        en: "Honigprodukte",
        de: "Honigprodukte",
        fr: "Nouvelles arrivées en vedette",
    },
    lastestProduct: {
        en: "Neue Honigprodukte",
        de: "Neue Honigprodukte",
        fr: "Nouveaux arrivages",
    },
    search: {
        en: "Suchergebnisse",
        de: "Suchergebnisse",
        fr: "Résultats de la recherche",
    },
};
