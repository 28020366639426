export const p = {
    price: {
        en: "Price",
        de: "Preis",
        fr: "",
    },
    status: {
        en: "Status",
        de: "Status",
        fr: "Status",
    },
    quantity: {
        en: "Quantity",
        de: "Menge",
        fr: "",
    },
    size: {
        en: "Selected Size",
        de: "Ausgewählte Größe",
        fr: "",
    },
    color: {
        en: "Color",
        de: "Farbe",
        fr: "",
    },
    sizeGuide: {
        en: "See Size Chart",
        de: "Größentabelle",
        fr: "",
    },
    deliverNReturn: {
        en: "Deliver and Return",
        de: "Liefern und zurückgeben",
        fr: "",
    },
    addToCart: {
        en: "Add to Cart",
        de: "Warenkorb hinzufügen",
        fr: "",
    },
    payment: {
        en: "Acceptable Payment Methods",
        de: "Bezahlungsmethoden",
        fr: "",
    },
    create: {
        en: 'Create product',
        de: 'Produkt erstellen',
        fr: 'créer un produit'
    },
    update: {
        en: 'Update product',
        de: 'Produkt aktualisieren',
        fr: 'mettre à jour le produit'
    },
    goBack: {
        de: 'Zurück',
        en: 'Go back'
    },
    remove: {
        de: 'Entfernen',
        en: 'Remove'
    },
    name: {
        en: 'Name',
        de: 'Name',
        fr: ''
    },
    category: {
        en: 'Category',
        de: 'Sorte',
        fr: ''
    },
    type: {
        en: 'Type',
        de: 'Typ',
        fr: ''
    },
    description: {
        en: 'Description',
        de: 'Beschreibung',
        fr: ''
    },
    discount: {
        en: 'Discount',
        de: 'Rabatt',
        fr: ''
    },
    reducedPrice: {
        en: 'Reduced price',
        de: 'Reduzierter Preis',
        fr: ''
    },
    weight: {
        en: 'Weight',
        de: 'Gewicht',
        fr: ''
    },
    weightInGramm: {
        en: 'Weight in gramm',
        de: 'Gewicht in Gramm',
        fr: ''
    },
    consistency: {
        en: 'Consistency',
        de: 'Konsistenz',
        fr: ''
    },
    images: {
        en: 'Images',
        de: 'Bilder',
        fr: ''
    },
    maxStock: {
        en: 'Max. available',
        de: 'Max. verfügbar',
        fr: ''
    },
    pieceShort: {
        en: 'Piece',
        de: 'Stck.',
        fr: ''
    },
    shipmentType: {
        en: 'Shipment type',
        de: 'Versandart',
        fr: ''
    },
    chooseWeight: {
        en: 'Choose Weight',
        de: 'Gewicht auswählen',
        fr: ''
    },
    removeFromCart: {
        en: 'Remove from cart',
        de: 'Entfernen',
        fr: ''
    },
    itemsVisible: {
        en: "Products visible",
        de: "Produkte sichtbar",
        fr: "",
    },
    isPrio: {
        en: "Is prio",
        de: "Ist priorisiert",
        fr: "",
    }
};
