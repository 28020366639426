import React from 'react';
import FacebookLogin from 'react-facebook-login';
 
const responseFacebook = (response) => {
  console.log(response);
}

const FbLoginComponent = () => {
    return (
        <FacebookLogin
            appId="1401162927359187"
            fields="name,email"
            callback={responseFacebook} 
            cssClass="social-media-btn fb-btn"
            icon="fa-facebook" />
    );
};

export default FbLoginComponent;