export {g} from './globals';
export {shippingTranslate} from './shipping';

export const p = {
    title: {
        en: "Update User data",
        de: "Profil aktualisieren",
        fr: "",
    },
    username: {
        en: "User name",
        de: "Benutzername",
        fr: "",
    },
    shipping_address: {
        en: "Update User data",
        de: "Versandadresse mit Hausnummer",
        fr: "",
    },
    billing_address: {
        en: "Update User data",
        de: "Rechnungsadresse",
        fr: "",
    },
    country: {
        en: "Country",
        de: "Land",
        fr: "",
    },
    images: {
        en: "Profile images",
        de: "Profilbilder",
        fr: "",
    },
    city: {
        en: "City",
        de: "Stadt",
        fr: "",
    },
    contact_number: {
        en: "Contact number",
        de: "Kontaktnummer",
        fr: "",
    },
    update: {
        en: "Update",
        de: "Aktualisieren",
        fr: ""
    },
    description: {
        en: "Description",
        de: "Beschreibung",
        fr: ""
    }
};
