import React, { forwardRef, useEffect, useRef, useState, useImperativeHandle } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";

var gmarkers = [];
const MapComponent = (aProps) => {
    const ref = useRef();
    const center = aProps.center;
    const zoom = aProps.zoom;
    const markers = aProps.addresses.addresses;

    useEffect(() => {
      let map = new window.google.maps.Map(ref.current, {
        center,
        zoom
      });

      const geocoder = new window.google.maps.Geocoder();
      if(markers && markers.length > 0) {
        markers.forEach((marker) => {
          codeAddress(geocoder, map, marker);
        });
      }
    }, [markers, center, zoom]);
  
    return <div ref={ref} id="map" className='h-100' />;
  }

  const getMarkerContent = (aUserData) => {
    let parts = [aUserData.username, aUserData.address1];

    if(aUserData.address2) {
      parts.push(aUserData.address2);
    }

    parts.push(aUserData.city);
    let _content = parts.join('<br>');
    return `<a href="/sellers/${aUserData.userIdent}" style="text-decoration: none">
        <div class="seller-wrapper p-2">
          ${_content}
        </div>
      </a>`;
  };

  const codeAddress = (geocoder, map, addressData, aIsOnlyCenter) =>  {
    let _address = getAddressInfo(addressData);
    geocoder.geocode({'address': _address}, function(results, status) {
      if (status === 'OK') {
        let _location = results[0].geometry.location;
        if(aIsOnlyCenter) {
          map.setCenter(_location);
          map.setZoom(8);
          return;
        }

        let _marker = new window.google.maps.Marker({
          map: map,
          position: _location,
          title: getMarkerContent(addressData),
          customInfo: _address
          // icon: {
          //   //url: '/images/others/Icons/icon_kontakt adresse.svg',
          //   scaledSize: new window.google.maps.Size(50, 50)
          // }
        });

        const _infoWindow = new window.google.maps.InfoWindow({
          content: getMarkerContent(addressData)
        });

        if(!gmarkers.find(marker => marker.customInfo === _address)) {
          gmarkers.push(_marker);
        }

        window.google.maps.event.addListener(_marker, 'click', function() {
          _infoWindow.open(map, _marker);
        });
      }
    });
  }

  function findClosestMarker(searchPosition, aRadius) {
    let _markers = [];
    gmarkers.forEach((marker) => {
      marker.distance = window.google.maps.geometry.spherical.computeDistanceBetween(searchPosition, marker.getPosition());
      marker.setMap(null);
      if(aRadius * 1000 >= marker.distance) {
        _markers.push(marker);
      }
    })
    
    _markers.sort(sortByDist);
    return _markers;
  }
  
  function sortByDist(a, b) {
    return (a.distance - b.distance)
  }

  const getAddressInfo = (aAddress) => {
    let addressParts = [aAddress.address1];

    if(aAddress.address2) {
      addressParts.push(aAddress.address2);
    }

    addressParts.push(aAddress.city);

    return addressParts.join(', ');
  }

const GoogleMaps = forwardRef((aAddresses, ref) => {
    const [center, setCenter] = useState({ lat: 51.165691, lng: 10.451526 });
    const [zoom, setZoom] = useState(6);

    useImperativeHandle(ref, () => ({
      async zoomTo(aAddress, aRadius) {
          const geocoder = new window.google.maps.Geocoder();
          try {
            let _results = await geocoder.geocode({'address': getAddressInfo(aAddress)}, function(results, status) {
              if (status === 'OK') {
                  return results;
              }
            });

            if(_results.results) {
              setCenter(_results.results[0].geometry.location);
              setZoom(10);
              
          
              let closest = findClosestMarker(_results.results[0].geometry.location, aRadius);
              var _closestMarkers = '';
              closest.forEach(element => {
                _closestMarkers += element.title;
              });
              document.getElementById('closest-markers').innerHTML = _closestMarkers;
            }
          } catch (error) {
            return 'Adresse konnte nicht eindeutig bestimmt werden.';
          }
      }
    }));

    return (
      <Wrapper apiKey="AIzaSyBGzbO2hvzWewH8zRY3uylA7xBAMIy25yA" libraries={['geometry']}>
          <MapComponent center={center} zoom={zoom} addresses={aAddresses}>
          </MapComponent>
      </Wrapper>
    );
});

export default GoogleMaps;
