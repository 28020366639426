export const h = {
    categories: {
        en: "Types of Honey",
        de: "Honigarten",
        fr: "",
    },
    types: {
        creamy: {
            en: "Creamy Honey",
            de: "Cremig",
            fr: "",
        },
        solid: {
            en: "Solid Honey",
            de: "Fest",
            fr: "",
        },
        liquid: {
            en: "Liquid Honey",
            de: "Flüssig",
            fr: "",
        }
    }
};
