import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,
    USER_LOGOUT,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    USER_DETAILS_RESET,
    USER_UPDATE_PROFILE_REQUEST,
    USER_UPDATE_PROFILE_SUCCESS,
    USER_UPDATE_PROFILE_FAIL,
    USER_UPDATE_LANGUAGE_REQUEST,
    USER_UPDATE_LANGUAGE_SUCCESS,
    USER_UPDATE_LANGUAGE_FAIL,
    USER_UPDATE_PASSWORD_REQUEST,
    USER_UPDATE_PASSWORD_SUCCESS,
    USER_UPDATE_PASSWORD_FAIL,
    USER_ORDER_RESET,
    USER_LIST_REQUEST,
    USER_LIST_SUCCESS,
    USER_LIST_FAIL,
    USER_LIST_RESET,
    USER_DELETE_REQUEST,
    USER_DELETE_SUCCESS,
    USER_DELETE_FAIL,
    USER_EDIT_REQUEST,
    USER_EDIT_SUCCESS,
    USER_EDIT_FAIL,
    MESSAGES_LIST_SUCCESS,
    MESSAGES_LIST_REQUEST,
    MESSAGES_LIST_FAIL,
    MESSAGES_CREATE_REQUEST,
    MESSAGES_CREATE_SUCCESS,
    MESSAGES_CREATE_FAIL,
    DIALOG_LIST_FAIL,
    DIALOG_LIST_REQUEST,
    DIALOG_LIST_SUCCESS,
    SEND_EMAIL_FAIL,
    SEND_EMAIL_REQUEST,
    SEND_EMAIL_SUCCESS,
    SELLER_LIST_FAIL,
    SELLER_LIST_REQUEST,
    SELLER_LIST_SUCCESS
} from "./types";

import api from "../apis/api";
import { configUtil } from "../Utils/apiConfig";
import { errorHandler } from "../Utils/errorHandling";

export const login = (email, password) => async (dispatch) => {
    try {
        dispatch({ type: USER_LOGIN_REQUEST });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const { data } = await api.post(
            "/signin",
            { email, password },
            config
        );
        dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
        localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

export const logout = (userId) => async (dispatch, getState) => {
    const { token } = getState().userAuth.userInfo;
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const { data } = await api.post(
            "/signout",
            {
                userId: userId,
                token: token,
            },
            config
        );
        dispatch({ type: USER_LOGOUT, payload: data });
        localStorage.removeItem("userInfo");
        dispatch({ type: USER_DETAILS_RESET });
        localStorage.removeItem("shippingAddress");
        localStorage.removeItem("paymentMethod");
        localStorage.removeItem("cartItems");
        localStorage.removeItem("userShippingInfo");
        dispatch({ type: USER_ORDER_RESET });
        dispatch({ type: USER_LIST_RESET });
    } catch (error) {
        //
    }
};

// Register
export const register =
    (username, email, password, userRole, language, token, userIdent) => async (dispatch) => {
        try {
            dispatch({ type: USER_REGISTER_REQUEST });
            const config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const { data } = await api.post(
                "/signup",
                { username, email, password, userRole, language, token, userIdent },
                config
            );
            dispatch({ type: USER_REGISTER_SUCCESS, payload: data });
            dispatch({ type: USER_LOGIN_SUCCESS, payload: data });
            localStorage.setItem("userInfo", JSON.stringify(data));
        } catch (error) {
            dispatch({
                type: USER_REGISTER_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };

// Get user Info
export const getUserInfo = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: USER_DETAILS_REQUEST });
        const { userInfo } = getState().userAuth;
        const { token } = userInfo;

        const { data } = await api.get(`/user/${id}`, configUtil(token));

        dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: USER_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

// Update User Language
export const updateLanguage = (language) => async (dispatch, getState) => {
    const { userInfo } = getState().userAuth;
    try {
        dispatch({ type: USER_UPDATE_LANGUAGE_REQUEST });
        const { data } = await api.patch(
            `/user/${userInfo._id}`,
            { language },
            configUtil(userInfo.token)
        );
        dispatch({ type: USER_UPDATE_LANGUAGE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: USER_UPDATE_LANGUAGE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

// Update User Password
export const updatePassword =
    (currentPassword, password) => async (dispatch, getState) => {
        const { userInfo } = getState().userAuth;
        try {
            dispatch({ type: USER_UPDATE_PASSWORD_REQUEST });
            const { data } = await api.patch(
                `/user/${userInfo._id}`,
                { currentPassword, password },
                configUtil(userInfo.token)
            );
            dispatch({ type: USER_UPDATE_PASSWORD_SUCCESS, payload: data });
        } catch (error) {
            dispatch({
                type: USER_UPDATE_PASSWORD_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };

export const resetPassword =
(token, password) => async (dispatch, getState) => {
    try {
        dispatch({ type: USER_UPDATE_PASSWORD_REQUEST });

        /** @todo replace atob logic */
        const { data: userInfo } = await api.get(`/user/${atob(token)}`);

        const { data: updateData } = await api.patch(
            `/user/${userInfo._id}`,
            { password, type: 'reset' },
            configUtil(userInfo.token)
        );
        dispatch({ type: USER_UPDATE_PASSWORD_SUCCESS, payload: updateData });
    } catch (error) {
        dispatch({
            type: USER_UPDATE_PASSWORD_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
};

// Update user Info
export const updateUserProfile =
    (username, name, surname, email, contactNum, city, zip, address1, address2, country, description, userIdent) =>
    async (dispatch, getState) => {
        const { userInfo } = getState().userAuth;
        const { token } = userInfo;
        try {
            dispatch({ type: USER_UPDATE_PROFILE_REQUEST });

            const { data } = await api.patch(
                `/user/${userInfo._id}`,
                {
                    username,
                    name,
                    surname,
                    contactNum,
                    email,
                    city,
                    zip,
                    address1,
                    address2,
                    country,
                    description,
                    userIdent
                },
                configUtil(token)
            );
            dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: data });
        } catch (error) {
            dispatch({
                type: USER_UPDATE_PROFILE_FAIL,
                payload: errorHandler(error),
            });
        }
    };

export const listMessages =
    (participantId) =>
    async (dispatch, getState) => {
        const { userInfo } = getState().userAuth;
        try {
            dispatch({ type: MESSAGES_LIST_REQUEST });

            const { data } = await api.get(
                `/messages/${userInfo._id}?userId=${participantId}`,
                configUtil(userInfo.token)
            );
            dispatch({ type: MESSAGES_LIST_SUCCESS, payload: data });
        } catch (error) {
            dispatch({
                type: MESSAGES_LIST_FAIL,
                payload: errorHandler(error),
            });
        }
    };

export const sendMessage =
    (content, participantId) =>
    async (dispatch, getState) => {
        const { userInfo } = getState().userAuth;
        try {
            dispatch({ type: MESSAGES_CREATE_REQUEST });

            if(participantId === userInfo._id) {
                throw new Error('Ungültiger Empfänger');
            }
            
            const { data } = await api.post(
                `/messages`,
                {
                    content: content,
                    receiverId: participantId,
                    senderId: userInfo._id
                },
                configUtil(userInfo.token)
            );
            dispatch({ type: MESSAGES_CREATE_SUCCESS, payload: data });
        } catch (error) {
            dispatch({
                type: MESSAGES_CREATE_FAIL,
                payload: errorHandler(error),
            });
        }
    };

export const listDialogs =
    (userId) =>
    async (dispatch, getState) => {
        const { userInfo } = getState().userAuth;
        try {
            dispatch({ type: DIALOG_LIST_REQUEST });

            const { data } = await api.get(
                `/dialog/${userId}`,
                configUtil(userInfo.token)
            );
            dispatch({ type: DIALOG_LIST_SUCCESS, payload: data });
        } catch (error) {
            dispatch({
                type: DIALOG_LIST_FAIL,
                payload: errorHandler(error),
            });
        }
    };

export const listSellers =
    (isHighPrio = false) =>
    async (dispatch, getState) => {
        try {
            dispatch({ type: SELLER_LIST_REQUEST });
            const { data } = await api.get(
                `/sellers?prio=${isHighPrio === true ? 1 : 0}`
            );
            
            dispatch({ type: SELLER_LIST_SUCCESS, payload: data });
        } catch (error) {
            dispatch({
                type: SELLER_LIST_FAIL,
                payload: errorHandler(error),
            });
        }
    };

// Admin Actions
export const listUsers =
    (keyword = "", pageNumber = "", limit = '', role='') =>
    async (dispatch, getState) => {
        const { userInfo } = getState().userAuth;
        try {
            dispatch({ type: USER_LIST_REQUEST });
            const { data } = await api.get(
                `/users?q=${keyword}&pageNumber=${pageNumber}&limit=${limit}&role=${role}`,
                configUtil(userInfo.token)
            );
            
            dispatch({ type: USER_LIST_SUCCESS, payload: data });
        } catch (error) {
            dispatch({
                type: USER_LIST_FAIL,
                payload: errorHandler(error),
            });
        }
    };

export const adminUpdateUser =
    (userId, items) => async (dispatch, getState) => {
        const { userInfo } = getState().userAuth;

        try {
            dispatch({ type: USER_EDIT_REQUEST });

            const { data } = await api.patch(
                `/user/${userId}/admin`,
                { ...items },
                configUtil(userInfo.token)
            );

            dispatch({ type: USER_EDIT_SUCCESS, payload: data });
        } catch (error) {
            dispatch({
                type: USER_EDIT_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };

export const deleteUser = (userId) => async (dispatch, getState) => {
    const { userInfo } = getState().userAuth;
    try {
        dispatch({ type: USER_DELETE_REQUEST });
        await api.delete(`/user/${userId}/admin`, configUtil(userInfo.token));
        dispatch({ type: USER_DELETE_SUCCESS });
    } catch (error) {
        dispatch({ type: USER_DELETE_FAIL, payload: errorHandler(error) });
    }
};

export const sendEmail = (userData, subject, content, authUser = false) => async (dispatch, getState) => {
    try {
        dispatch({ type: SEND_EMAIL_REQUEST });
        
        if(authUser) {
            try {
                await api.get(`/user/${userData.receiverEmail}`);
            } catch (error) {

                // if user couldnt be found, we wont send an email
                dispatch({ type: SEND_EMAIL_SUCCESS, payload: {message: "Email erfolgreich gesendet."}});
                return;
            }
        }

        const _data = {...userData, ...{subject: subject, html: content}};
        const { data } = await api.post(
            `/email`,
            _data,
        );

        dispatch({ type: SEND_EMAIL_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: SEND_EMAIL_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        });
    }
}