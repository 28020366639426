import React, { useEffect, useState } from "react";
import FormComponent from "../../components/formComponent";
import FormContainer from "../../components/formContainer";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/message";
import Loader from "../../components/loader";
import {a,g} from '../../Utils/translateLibrary/auth';
import ForgotPasswordComponent from "../../components/email/forgotPassword";
import ReactDOMServer from 'react-dom/server';
import { sendEmail } from "../../actions/user";

const ForgotPassword = ({ history }) => {
    const [email, setEmail] = useState("");
    const dispatch = useDispatch();

    const settings = useSelector((state) => state.settings);
    const { language } = settings;

    const sendEmailState = useSelector((state) => state.sendEmail);
    const { loading,  error, success } = sendEmailState;
    
    useEffect(() => {
        if (error) {
            setEmail('');
        }
    }, [dispatch, history, success, error]);

    const submitHandler = (e) => {
        e.preventDefault();

        let _user = {receiverEmail: email};
        dispatch(sendEmail(_user, 'Passwort vergessen', ReactDOMServer.renderToStaticMarkup(<ForgotPasswordComponent email={email}/>), true));
    };

    return (
        <>
            <FormContainer>
                <h1>{a.forgotPassword.title[language]}</h1>
                {error && <Message variant="danger">{error}</Message>}
                {success && <Message variant="success">{success.message}</Message>}
                {loading && <Loader />}
                <Form onSubmit={submitHandler} autoComplete="on">
                    <FormComponent
                        label={g.email[language]}
                        type="email"
                        defaultValue={email}
                        required={true}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <div className="py-3">
                        <Button
                            type="submit"
                            variant="primary"
                            disabled={email === ""}>
                            Email senden
                        </Button>
                    </div>
                </Form>
            </FormContainer>
        </>
    );
};

export default ForgotPassword;
