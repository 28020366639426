import { g } from "./globals";

export const sale = {
    all: {
        en: "Nach Angeboten suchen",
        cn: "Nach Angeboten suchen",
        jpn: "Tous les produits à prix réduit",
    },
};

export const colors = {
    0: g.color.yellow,
    1: g.color.brown,
    2: g.color.white
};

export const consistencies = {
    0: g.consistency.creamy,
    1: g.consistency.solid,
    2: g.consistency.liquid
};

export const categories = {
    0: g.category.maple,
    1: g.category.acacia,
    2: g.category.blossom,
    3: g.category.buckwheat,
    4: g.category.oak,
    5: g.category.fennel,
    6: g.category.springblossom,
    7: g.category.spruce,
    8: g.category.heather,
    9: g.category.clover,
    10: g.category.lavender,
    11: g.category.linden,
    12: g.category.dandelion,
    13: g.category.phacelia,
    14: g.category.rapeseed,
    15: g.category.summer,
    16: g.category.sunflower,
    17: g.category.fir,
    18: g.category.thyme,
    19: g.category.forest,
};

export const shipmentTypes = {
    0: g.shipmentTypes.shipment,
    1: g.shipmentTypes.pickUp
}