// Paypal currency decoder
export const currencyDecoder = (currency) => {
  switch (currency) {
    case 'usd': 
      return 'USD';
    default:
      return 'EUR'
  }
}

export const stripeCurrencyDecoder = (currency) => {
  switch (currency) {
    case 'usd': 
        return 'usd';
    default:
      return 'eur'
  }
}