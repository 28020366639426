import React from "react";
import {a} from '../../Utils/translateLibrary/auth';
import { useSelector } from "react-redux";

const VerificationScreen = ({ history }) => {
    // Settings
    const settings = useSelector((state) => state.settings);
    const { language } = settings;

    return (
        <>
            <div className="text-center">{a.pending_verification[language]}</div>
        </>
    );
};

export default VerificationScreen;
