import React, { useState } from "react";
import { logout } from "../actions/user";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { Nav, Navbar, Container, NavDropdown, Button } from "react-bootstrap";
import { nav } from "../Utils/translateLibrary/navbar";
import '../Utils/css/header.css';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { isAdminOrSupport } from "../Utils/helpers/user";

const Header = () => {
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');

    const userAuth = useSelector((state) => state.userAuth);
    const { userInfo } = userAuth;

    const settings = useSelector((state) => state.settings);
    const { language } = settings;

    const handleLogout = (userId) => {
        dispatch(logout(userId));
    };

    const searchSeller = () => {
        if(search)(
            window.location.href = '/seller-search?q=' + search
        )
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchSeller();
        }
    } 

    const getCurrentShippingCartItems = () => {
        // 
    }

    return (
        <header id="navbar">
             <Navbar expand="lg" collapseOnSelect>
                <Container>
                    <LinkContainer to="/" className="md:hidden">
                        <Navbar.Brand className="mr-0">
                            <img
                                src="/images/logo.png"
                                className="logo-img"
                                alt="Honigfinder Logo"
                            />
                        </Navbar.Brand> 
                    </LinkContainer>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto w-100 hidden md:block">
                            <div className="flex-column w-100">
                                <div className="ms-auto nav-grid">
                                    <div></div>
                                    <div>
                                        <div className="d-flex ms-auto">
                                            {userInfo ? (
                                                <NavDropdown className="d-flex w-100 justify-content-center"
                                                    title={<span><i className="fa fa-user fa-fw navbar-icon"></i>{userInfo.username}</span>}
                                                    id="username"
                                                >
                                                    <LinkContainer to="/profile">
                                                        <NavDropdown.Item>
                                                            {nav.profile[language]}
                                                        </NavDropdown.Item>
                                                    </LinkContainer>
                                                    <LinkContainer to="/orders">
                                                        <NavDropdown.Item>
                                                            {nav.yourorder[language]}
                                                        </NavDropdown.Item>
                                                    </LinkContainer>
                                                    <LinkContainer to="/chat">
                                                        <NavDropdown.Item>
                                                            {nav.chat[language]}
                                                        </NavDropdown.Item>
                                                    </LinkContainer>
                                                    {userInfo.role === 'seller' && (
                                                        <LinkContainer to="/seller/products">
                                                            <NavDropdown.Item>
                                                                {nav.yourProduct[language]}
                                                            </NavDropdown.Item>
                                                        </LinkContainer>
                                                    )}

                                                    <NavDropdown.Item
                                                        onClick={() =>
                                                            handleLogout(userInfo._id)
                                                        }
                                                    >
                                                        {nav.logout[language]}
                                                    </NavDropdown.Item>
                                                </NavDropdown>
                                            ) : (
                                                <div className="center-items auth-btn-container">
                                                    <LinkContainer to="/login" className="mr-2">
                                                        <Button variant="tertiary">
                                                            {nav.signin[language]}
                                                        </Button>
                                                    </LinkContainer>
                                                    <LinkContainer to="/register">
                                                        <Button variant="secondary">
                                                            {nav.register[language]}
                                                        </Button>
                                                    </LinkContainer>
                                                </div>
                                            )}
                                            {userInfo && isAdminOrSupport(userInfo) && (
                                                <NavDropdown className="d-flex w-100 justify-content-center"
                                                    title={<span><i className="fa fa-hammer"></i></span>}
                                                    id="adminmenu"
                                                >
                                                    <LinkContainer to="/admin/users">
                                                        <NavDropdown.Item>
                                                            {nav.user[language]}
                                                        </NavDropdown.Item>
                                                    </LinkContainer>
                                                    <LinkContainer to="/admin/products">
                                                        <NavDropdown.Item>
                                                            {nav.product[language]}
                                                        </NavDropdown.Item>
                                                    </LinkContainer>
                                                    <LinkContainer to="/admin/orders">
                                                        <NavDropdown.Item>
                                                            {nav.order[language]}
                                                        </NavDropdown.Item>
                                                    </LinkContainer>
                                                </NavDropdown>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="nav-grid">
                                    <div className="d-flex ms-auto w-100 align-items-center">
                                        <LinkContainer to="/">
                                            <Navbar.Brand className="mr-0">
                                                <img
                                                    src="/images/logo.png"
                                                    className="logo-img"
                                                    alt="Honigfinder Logo"
                                                />
                                            </Navbar.Brand> 
                                        </LinkContainer>

                                        <div className="w-100 input seller-search">
                                            <input id="seller-search" onInput={(e) => setSearch(e.target.value)} onKeyDown={(e) => handleKeyDown(e)} placeholder="Gib deine PLZ ein und finde Honig in deiner Nähe" />
                                            <i onClick={() => searchSeller()} className="fa fa-search" role="button"></i>
                                        </div>
                                        <div className="ml-auto d-flex" style={{fontSize: "0.9em"}}>
                                            <LinkContainer to="/">
                                                <Nav.Link className="text-uppercase">{nav.home[language]}</Nav.Link>
                                            </LinkContainer>
                                            <LinkContainer to="/search">
                                                <Nav.Link className="text-uppercase">{nav.sale[language]}</Nav.Link>
                                            </LinkContainer>
                                            <LinkContainer to="/seller-search">
                                                <Nav.Link className="text-uppercase">Honig in deiner Nähe</Nav.Link>
                                            </LinkContainer>

                                            <LinkContainer to="/cart">
                                                <Nav.Link className="text-uppercase">
                                                    Warenkorb
                                                    <i className="fas fa-shopping-cart mr-3 navbar-icon"></i> 
                                                </Nav.Link>
                                            </LinkContainer>
                                        </div>
                                    </div>
                                    <div className="center-items">
                                        <Link to="/kontakt" className="w-100 font-weight-bold btn btn-primary">Kontakt</Link>
                                    </div>
                                </div>
                            </div>
                        </Nav>

                        {/* Mobile */}
                        <Nav className="ms-auto md:hidden">
                            <LinkContainer to="/">
                                <Nav.Link className="text-uppercase">{nav.home[language]}</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/search">
                                <Nav.Link className="text-uppercase">{nav.sale[language]}</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/seller-search">
                                <Nav.Link className="text-uppercase">Honig in deiner Nähe</Nav.Link>
                            </LinkContainer>

                            <LinkContainer to="/cart">
                                <Nav.Link className="font-weight-bold text-dark">
                                    Warenkorb <i className="fas fa-shopping-cart mr-3 navbar-icon"></i>
                                </Nav.Link>
                            </LinkContainer>
                            {userInfo ? (
                                <NavDropdown
                                    title={<span className="font-weight-bold text-dark">{userInfo.username} <i className="fa fa-user fa-fw navbar-icon"></i></span>}
                                    id="username"
                                >
                                    <LinkContainer to="/profile">
                                        <NavDropdown.Item>
                                            {nav.profile[language]}
                                        </NavDropdown.Item>
                                    </LinkContainer>
                                    <LinkContainer to="/orders">
                                        <NavDropdown.Item>
                                            {nav.yourorder[language]}
                                        </NavDropdown.Item>
                                    </LinkContainer>
                                    <LinkContainer to="/chat">
                                        <NavDropdown.Item>
                                            {nav.chat[language]}
                                        </NavDropdown.Item>
                                    </LinkContainer>
                                    {userInfo.role === 'seller' && (
                                        <LinkContainer to="/seller/products">
                                            <NavDropdown.Item>
                                                {nav.yourProduct[language]}
                                            </NavDropdown.Item>
                                        </LinkContainer>
                                    )}

                                    <NavDropdown.Item
                                        onClick={() =>
                                            handleLogout(userInfo._id)
                                        }
                                    >
                                        {nav.logout[language]}
                                    </NavDropdown.Item>
                                </NavDropdown>
                            ) : (
                                <>
                                    <LinkContainer to="/login">
                                        <Nav.Link className="font-weight-bold text-dark">
                                            {nav.signin[language]}
                                        </Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to="/register">
                                        <Nav.Link className="font-weight-bold text-dark">
                                            {nav.register[language]}
                                        </Nav.Link>
                                    </LinkContainer>
                                </>
                            )}
                            {userInfo && userInfo.role === "admin" && (
                                <NavDropdown
                                    title={<span className="font-weight-bold text-dark"><i className="fa fa-hammer"></i></span>}
                                    id="adminmenu"
                                >
                                    <LinkContainer to="/admin/users">
                                        <NavDropdown.Item>
                                            {nav.user[language]}
                                        </NavDropdown.Item>
                                    </LinkContainer>
                                    <LinkContainer to="/admin/products">
                                        <NavDropdown.Item>
                                            {nav.product[language]}
                                        </NavDropdown.Item>
                                    </LinkContainer>
                                    <LinkContainer to="/admin/orders">
                                        <NavDropdown.Item>
                                            {nav.order[language]}
                                        </NavDropdown.Item>
                                    </LinkContainer>
                                </NavDropdown>
                            )}
                            <Button>Kontakt</Button>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
};

export default Header;
