export const payT = {
    title: {
        en: "Choose Payment Method",
        de: "Zahlungsmethode auswählen",
        fr: "Mode de paiement",
    },
    select: {
        en: "Select Method",
        de: "Methode wählen",
        fr: "Sélectionnez la méthode",
    },
    methods: {
        bank: {
            en: "Bank transfer",
            de: "Banküberweisung",
            fr: "",
        },
        paypal: {
            en: "PayPal",
            de: "PayPal",
            fr: "PayPal",
        }
    }
};
