export const setLang = [
    {
        label: "English",
        lang: "en",
    },
    {
        label: "French",
        lang: "fr",
    },
    {
        label: "Deutsch",
        lang: "de",
    },
];
