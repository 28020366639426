import React, { useEffect, useRef } from "react";
import { Button, Row, Col, ListGroup, Card, Table } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { Image } from "cloudinary-react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Message from "../components/message";
import CheckoutSteps from "../components/checkoutSteps";
import { addDecimals } from "../Utils/addDecimals";
import { createOrder } from "../actions/order";
import { currencyDecoder } from "../Utils/currencyDecoder";
import { summary } from "../Utils/translateLibrary/orderSummary";
import { p } from "../Utils/translateLibrary/productDetails";
import { colors } from '../Utils/translateLibrary/product';
import { payT } from "../Utils/translateLibrary/paymentMethod";

const PlaceOrderScreen = ({ history }) => {
    const cart = useSelector((state) => state.cart);
    const { cartItems, shippingAddress, paymentMethod, userShippingInfo, billingAddress } =
        cart;
    const { address1, address2, zip, city, country } = shippingAddress;

    let billingAddress1 = '';
    let billingAddress2 = '';
    let billingZip = '';
    let billingCity = '';
    let billingCountry = '';
    if(billingAddress) {
        billingAddress1 = billingAddress.billingAddress1;
        billingAddress2 = billingAddress.billingAddress2;
        billingZip = billingAddress.billingZip;
        billingCity = billingAddress.billingCity;
        billingCountry = billingAddress.billingCountry;
    }

    // Order Reducer
    const orderCreate = useSelector((state) => state.orderCreate);
    const { order, success, error } = orderCreate;

    // Settings
    const settings = useSelector((state) => state.settings);
    const { currency, language } = settings;

    cart.itemsPrice = Number(
        addDecimals(
            cart.cartItems.reduce(
                (acc, curr) =>
                    curr.discount > 0
                        ? curr.discount * curr.qty + acc
                        : curr.price * curr.qty + acc,
                0
            )
        )
    );

    // useRef Hook for Recaptcha
    const reRef = useRef();

    // EDIT THE SHIPPING ARRANGEMENT
    cart.shippingCost = Number(addDecimals(1));
    cart.tax = Number(addDecimals(0));
    cart.totalPrice = Number(cart.itemsPrice + cart.shippingCost + cart.tax);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!shippingAddress || !userShippingInfo) {
            history.push("/shipping");
        }
        if (success) {
            setTimeout(() => {
                history.push(`/order/${order._id}`);
            }, 1500);
        }
    }, [
        dispatch,
        history,
        cart,
        success,
        shippingAddress,
        userShippingInfo,
        order,
    ]);

    const placeOrderHandler = async () => {
        const token = await reRef.current.executeAsync();
        reRef.current.reset();

        dispatch(
            createOrder(
                {
                    user: userShippingInfo,
                    orderItems: cart.cartItems,
                    shippingAddress: cart.shippingAddress,
                    paymentMethod: paymentMethod,
                    shippingCost: cart.shippingCost,
                    tax: cart.tax,
                    totalPrice: cart.totalPrice,
                    itemsPrice: cart.itemsPrice,
                    currency: currencyDecoder(currency),
                },
                token
            )
        );
    };

    const getShippingAdressInfo = () => {
        let addressParts = [address1];

        if(address2) {
            addressParts.push(address2);
        }

        addressParts.push(zip + ' ' + city);
        addressParts.push(country);

        return addressParts.join(', ');
    }

    const getBillingAddressInfo = () => {
        let addressParts = [billingAddress1];

        if(billingAddress2) {
            addressParts.push(billingAddress2);
        }

        addressParts.push(billingZip + ' ' + billingCity);
        addressParts.push(billingCountry);

        return addressParts.join(', ');
    }

    return (
        <>
            <CheckoutSteps step1 step2 step3 step4 />
            {error && <Message variant="danger">{error}</Message>}
            {success && (
                <Message variant="success">
                    {summary.success[language]}
                </Message>
            )}
            <Row style={{ borderRadius:"40px"}}>
                <Col md={9} >
                    <ListGroup variant="flush" >
                        <ListGroup.Item>
                            <h2 style={{ textAlign: "center", fontWeight:"bolder" }}>{summary.shipping[language]}</h2>
                            <p>
                                <strong>{summary.address[language]}:</strong>
                                {' ' + getShippingAdressInfo()}
                            </p>
                            {billingAddress && <p>
                                <strong>{summary.billingAddress[language]}:</strong>
                                {' ' + getBillingAddressInfo()}
                            </p>}
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h2>{summary.payment[language]}</h2>
                            <strong>{summary.method[language]}: </strong>
                            {payT.methods[paymentMethod][language]}
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h2>{summary.orderItems[language]}</h2>
                            {cartItems.length === 0 ? (
                                <Message variant="danger">
                                    Your cart is empty
                                </Message>
                            ) : (
                                <Table striped hover responsive>
                                    <thead>
                                        <tr>
                                            <th className="hidden md:d-table-cell"></th>
                                            <th colSpan={2}>
                                                <strong className="text-primary">{p.name[language]}</strong>
                                            </th>
                                            <th colSpan={2}>
                                                <strong>{p.weight[language]}</strong>
                                            </th>
                                            <th colSpan={2} className="hidden md:d-table-cell">
                                                <strong>{summary.color[language]}</strong>
                                            </th>
                                            <th colSpan={2}>
                                                <strong>{p.price[language]}</strong>
                                            </th>
                                            <th>
                                                <strong>{p.quantity[language]}</strong>
                                            </th>
                                            <th colSpan={2}>
                                                <strong>{summary.total[language]}</strong>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {cartItems.map((item, index) => {
                                        return (
                                        <tr key={'cart_item_' + index}>
                                            <td className="hidden md:d-table-cell">
                                                <Image  cloudName="dycgvrxas"
                                                        publicId={
                                                            process.env.REACT_APP_DEFAULT_PRODUCT_IMAGE
                                                        }
                                                        alt={item.name}
                                                        width={50}
                                                        variant="fluid"
                                                />
                                            </td>
                                            <td colSpan={2}>
                                                <Link
                                                    to={`/product/${item.productId}`}
                                                    className="text-primary"
                                                >
                                                    {item.name}
                                                </Link>
                                            </td>
                                            <td colSpan={2}>
                                                {item.weight} g
                                            </td>
                                            <td colSpan={2} className="hidden md:d-table-cell">
                                                {colors[item.color] ? colors[item.color][language] : ''}
                                            </td>
                                            <td colSpan={2}>
                                                €
                                                {item.discount > 0
                                                    ? Number(item.discount).toFixed(2)
                                                    : Number(item.price).toFixed(2)}
                                            </td>
                                            <td>
                                                {item.qty} {p.pieceShort[language]}
                                            </td>
                                            <td colSpan={2}>
                                                €
                                                {item.discount > 0
                                                ? Number(item.discount * item.qty).toFixed(2)
                                                : Number(item.price * item.qty).toFixed(2)}
                                            </td>
                                        </tr>
                                    )})}
                                    </tbody>
                                </Table>
                            )}
                        </ListGroup.Item>
                    </ListGroup>
                </Col>

                <Col md={3} >
                    <Card >
                        <ListGroup variant="flush" >
                            <ListGroup.Item style={{ backgroundColor: "var(--primary)" }}>
                                <h2 style={{ fontWeight:"bolder" }}>{summary.title[language]}</h2>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row>
                                    <Col>{summary.items[language]}</Col>
                                    <Col>€ {Number(cart.itemsPrice).toFixed(2)}</Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row>
                                    <Col>{summary.shippingFee[language]}</Col>
                                    <Col>€ {Number(cart.shippingCost).toFixed(2)}</Col>
                                </Row>
                            </ListGroup.Item >
                            <ListGroup.Item>
                                <Row>
                                    <Col>{summary.tax[language]}</Col>
                                    <Col>€ {Number(cart.tax).toFixed(2)}</Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <Row>
                                    <Col>{summary.total[language]}</Col>
                                    <Col>€ {Number(cart.totalPrice).toFixed(2)}</Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className="d-grid gap-2">
                                    <Button 
                                        className="btn btn-primary"
                                        type="button"
                                        disabled={
                                            cartItems.length === 0 || success
                                        }
                                        onClick={placeOrderHandler}
                                    >
                                        {summary.placeOrder[language]}
                                    </Button>
                                </span>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
            </Row>
{process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}
            <ReCAPTCHA
                sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}
                size="invisible"
                ref={reRef}
            />
        </>
    );
};

export default PlaceOrderScreen;
