import React from "react";
import { Pagination } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

export const PaginateProductsAdmin = ({ pages, page, keyword = "" }) => {
    return (
        pages > 1 && (
            <Pagination
                className="d-flex justify-content-center"
                variant="light"
            >
                {[...Array(pages).keys()].map((x) => {
                    return (
                        <LinkContainer
                            key={x + 1}
                            to={
                                keyword
                                    ? `/admin/products/search/${keyword}/page/${
                                          x + 1
                                      }`
                                    : `/admin/products/page/${x + 1}`
                            }
                        >
                            <Pagination.Item active={x + 1 === page}>
                                {x + 1}
                            </Pagination.Item>
                        </LinkContainer>
                    );
                })}
            </Pagination>
        )
    );
};

export const PaginateUsers = ({ pages, page, keyword = "" }) => {
    return (
        pages > 1 && (
            <Pagination
                className="d-flex justify-content-center"
                variant="light"
            >
                {[...Array(pages).keys()].map((x) => {
                    return (
                        <LinkContainer
                            key={x + 1}
                            to={`/admin/users?keyword=${keyword}&page=${x + 1}`}
                        >
                            <Pagination.Item active={x + 1 === page}>
                                {x + 1}
                            </Pagination.Item>
                        </LinkContainer>
                    );
                })}
            </Pagination>
        )
    );
};

const Paginate = ({ pages, page, keyword = '' }) => {
    const getLink = (aPage) => {
        let _params = '?page=' + aPage;
        if(keyword) {
            _params += '&q=' + keyword;
        }

        return _params;
    }

    return (
        pages > 1 && (
            <Pagination
                className="d-flex justify-content-center"
                variant="light"
            >
                {[...Array(pages).keys()].map((x) => {
                    return (
                        <LinkContainer
                            key={'link_'+ (x + 1)}
                            to={getLink(x + 1)}>
                            <Pagination.Item active={x + 1 === page}>
                                {x + 1}
                            </Pagination.Item>
                        </LinkContainer>
                    );
                })}
            </Pagination>
        )
    );
};


export default Paginate;
