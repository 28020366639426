import React, { useCallback, useEffect, useRef, useState } from 'react';
import GoogleMaps from './googleMaps';
import '../Utils/css/map.css';
import '../Utils/css/web.css';
import { useDispatch, useSelector } from 'react-redux';
import { listUsers, listSellers } from '../actions/user';
import Message from './message';
import { Button } from 'react-bootstrap';

const Sellersearch = () => {
    const [error, setError]  = useState('');
    const mapRef = useRef();
    const searchParams = window.location.search;
    const params = new URLSearchParams(searchParams);
    const initSearch = params.get('q') || '';
    const [search, setSearch] = useState(initSearch);
    const [hasInitSearch, setHasInitSearch] = useState(initSearch !== '');
    const [radius, setRadius] = useState(50);

    const dispatch = useDispatch()
    const userList = useSelector((state) => state.userList);
    const { users } = userList;
    
    const sellerList = useSelector((state) => state.sellerList);
    const { sellers } = sellerList;

    const setZoomByAddress = useCallback(async () => {
        if(mapRef.current) {
            const error = await mapRef.current.zoomTo({address1: search}, radius);
            setError(error);
        }
    }, [search, radius]);

    const setInitZoom = useCallback(() => {
        setHasInitSearch(false);
        setTimeout(() => {
            setZoomByAddress();
        }, 300);
    }, [setZoomByAddress]);

    useEffect(() => {
        if(hasInitSearch) {
            setInitZoom();
        }

        dispatch(listSellers(true));
        dispatch(listUsers('', 1, 50, 'Seller,admin'));
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return (
        <div className='container section'>
            <div className="container-honigliebhaber">
                <div className="container-left col-md-4" style={{fontSize: "1rem"}}>
                    <div className="search-description" >
                        <div className="search-description-title text-left" style={{fontSize: "larger", fontWeight: "bolder"}}> 
                            Finde Honig in deiner Nähe
                        </div>
                        <div className="search-description-text mb-3" style={{textAlign: "left"}}>
                            Unterstütze regionale Imker und genieße reinen, heimischen Honig. Gib deine Adresse ein und finde deinen Lieblingshonig:
                        </div>
                    </div>

                <div className='d-flex'>
                    <input className="form-control border type-search mr-3" formcontrolname="op" id="type-search" ng-minlength="4" placeholder="53879 Euskirchen" type="search" 
                        defaultValue={search}
                            style={{
                                fontSize: "large",
                                borderRadius: "40px",
                                maxWidth: "450px",
                                }}
                                onChange={(e) => setSearch(e.target.value)}
                                >
                            </input>
                            <Button className='searchButton btn btn-primary btn-md mybtn m-auto py-2'
                        style={{
                            borderColor: "#000000",
                            fontSize: "large",
                            borderRadius: "40px",
                            maxWidth: "250px",
                            display: "block",
                            }}
                            onClick={(e) => setZoomByAddress()}>
                            Suchen</Button>
                            </div>

                    <div className="slide-container">
                        <input onChange={(e) => setRadius(e.target.value)} type="range" min="1" max="100" defaultValue="50" className="slider" />
                        <span className='slider-radius'>{radius} km</span>
                    </div>
                    {error && <Message variant="danger">{error}</Message>}
                    
                    <img src={'/images/Beekeeper_Black.png'} style={{ height: "40px", width: "40px"}} alt="seller-icon" className="mr-3 mt-3"/>
                            <div className="beekeeper-nearby-text mt-3"
                                    style={{
                                        fontSize: "larger",
                                        fontWeight:"bolder"
                                    }}>
                                Verkäufer in der Nähe:
                            </div>
                            <div id="closest-markers" className='sellers-container mb-3'>
                                Keine Händler in der Nähe
                            </div>

                            {sellers && sellers.length > 0 && (
                                <>
                                    <div className="beekeeper-nearby-text mt-3"
                                        style={{
                                            fontSize: "larger",
                                            fontWeight:"bolder"
                                            }}>
                                        Beliebte Verkäufer:
                                    </div> 
                                    <div className='sellers-container'>
                                        {sellers.map((seller) => {
                                            return  seller.userIdent && (
                                                <a href={'/sellers/' + seller.userIdent} key={`seller_${seller._id}`}>
                                                    <div className='seller-wrapper d-flex p-0'>
                                                        <div className='icon-col center-items bg-primary' >
                                                            <i className="fas fa-star"></i>
                                                        </div>
                                                        <div className='py-1'>
                                                            {seller.username}
                                                            <div>
                                                                {seller.address1}
                                                            </div>
                                                            <div>
                                                                {seller.zip} {seller.city}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            );
                                        })}
                                    </div>
                                </>
                            )}
                            
                </div>
                <div className="container-right w-100">
                    <div className='map-wrapper p-3'>
                        <GoogleMaps ref={mapRef} addresses={users}></GoogleMaps>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Sellersearch;
