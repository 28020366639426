import React from 'react'
import { Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

const EditProfileNavbar = ({match}) => {
  return (
    <Nav className="justify-content-center mb-4" >
      <Nav.Item>
        <LinkContainer to="/profile" style={{color: match.url === '/profile' ? 'var(--primary)' : ''}}>
          <Nav.Link>Profil aktualisieren</Nav.Link>
        </LinkContainer>
      </Nav.Item>
      <Nav.Item>
        <LinkContainer to="/profile/change-password" style={{color: match.url === '/profile/change-password' ? 'var(--primary)' : ''}}>
          <Nav.Link>Passwort ändern</Nav.Link>
        </LinkContainer>
      </Nav.Item>
      {/*
      <Nav.Item>
        <LinkContainer to="/profile/settings">
          <Nav.Link>Einstellungen</Nav.Link> 
        </LinkContainer>
      </Nav.Item>
      */}
    </Nav>
  )
}
export default EditProfileNavbar
