import React from 'react';

const ForgotPasswordComponent = ({email}) => {
    const style = `
    .email-container {
        background: lightgrey;
    }

    .email-container .content {
        border: 1px solid black;
        padding: 3rem;
        width: 50%;
        margin: auto;
        margin-top: 1rem;
        background: white;
    }
    
    .footer {
        color: grey;
        text-align: center;
        margin-top: 1rem;
    }
    `;

    const token = btoa(email);
    /** @todo replace btoa with a safer logic */
    return (
        <html>
            <head>
                <style dangerouslySetInnerHTML={{ __html: style }} />
            </head>
            <body  className='email-container'>
                <div className='content'>
                    <p>Hallo {email},</p><br/>
                    <p>Bitte verwende den unteren Link, um dein Passwort zurückzusetzen.</p>
                    <a href={'https://honigfinder.de/reset-password?token=' + token}>https://honigfinder.de/reset-password?token={token}</a>
                    <br/><br/>
                    <p>Dein Honigfinder Team</p>
                </div>
            </body>
        </html>
    );
};

export default ForgotPasswordComponent;