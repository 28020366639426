import React from "react";
import { Link } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import { Image } from "cloudinary-react";
import '../Utils/css/card.css';
import {p} from '../Utils/translateLibrary/productDetails';

const Product = ({ product, lang, isExtended }) => {

    const handleSellerClick = (aUserId) => {
        window.location.href= '/sellers/' + aUserId;
    }

    return (
        <Card className="m-2 rounded product-card" style={{ border: "none" }}>
            <Link to={`/product/${product._id}`} className="h-100">
                    <Card style={{ border: "none" }}>
                        <Image
                            style={{ objectFit: "cover", border: "none" }}
                            cloudName="dycgvrxas"
                            publicId={process.env.REACT_APP_DEFAULT_PRODUCT_IMAGE}
                            height={400}
                            alt=""
                        />
                    </Card>
                    <Card.Body>
                        <div>
                            <Card.Title
                                as="div"
                                className="text-center"
                            >
                                {isExtended && (<div className="d-flex w-100 text-dark my-2">
                                    <div className="pr-3 w-100 border-right">
                                        <div onClick={() => handleSellerClick(product.productOwner.userIdent)}>
                                            <img src={'/images/Beekeeper_Black.png'} style={{ height: "40px", width: "40px"}} alt="seller-icon" className="mr-3"/>
                                            <span className="font-weight-bold">{product.productOwner.username}</span>
                                        </div>
                                    </div>
                                    <div className="w-100">
                                        <img src={'/images/Beehive_Black.png'} style={{ height: "40px", width: "40px"}} alt="seller-icon" className="mr-3"/>
                                        <span className="font-weight-bold">{product.productOwner.city}</span>
                                    </div>
                                </div>)}
                                <strong
                                    className="title"
                                >
                                    {product.name.substring(0, 15)}
                                </strong>
                                <div className="w-100 price">
                                    {product.discount > 0 ? (
                                        <div className="d-flex text-center justify-content-center">
                                            <p className="p-0 mr-3">
                                                € {product?.discount}
                                            </p>

                                            <p className="text-right">
                                                <span className="strikediag text-dark ml-2">
                                                    € {product?.price}
                                                </span>
                                            </p>
                                        </div>
                                    ) : (
                                        <p>
                                            € {product?.price}
                                        </p>
                                    )}
                                </div>
                            </Card.Title>   
                        </div>
                        <div className="mt-auto">
                            {product.description && (<p className="text-center text-dark">{product.description.length > 30 ? product.description.substring(0, 30) + '...' : product.description}</p>)}
                            <div className="d-flex justify-content-center">
                                <Button variant="icon"><i className="fa fa-shopping-cart"></i>{p.chooseWeight[lang]}</Button>
                            </div>
                        </div>
                </Card.Body>
            </Link>
        </Card>
    );
};

export default Product;
