import { configUtil } from "../Utils/apiConfig";
import { errorHandler } from "../Utils/errorHandling";
import { IMAGE_DELETE_FAIL, IMAGE_DELETE_REQUEST, IMAGE_DELETE_SUCCESS, IMAGE_UPLOAD_FAIL, IMAGE_UPLOAD_REQUEST, IMAGE_UPLOAD_SUCCESS } from "./types";
import api from "../apis/api";

export const uploadProductImage = (productId, image) => async (dispatch, getState) =>{
    const { userInfo } = getState().userAuth;
    try {
        dispatch({ type: IMAGE_UPLOAD_REQUEST });

        const { data } = await api.post(
            `/image/product`,
            {
                productId: productId,
                image: image
            },
            configUtil(userInfo.token)
        );

        dispatch({ type: IMAGE_UPLOAD_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: IMAGE_UPLOAD_FAIL, payload: errorHandler(error) });
    }
}

export const deleteProductImage = (productId, image) => async (dispatch, getState) =>{
    const { userInfo } = getState().userAuth;
    try {
        dispatch({ type: IMAGE_DELETE_REQUEST });

        const { data } = await api.delete(
            `/image/product?productId=${productId}&image=${image}`,
            configUtil(userInfo.token)
        );

        dispatch({ type: IMAGE_DELETE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: IMAGE_DELETE_FAIL, payload: errorHandler(error) });
    }
}


export const uploadProfileImage = (userId, image) => async (dispatch, getState) =>{
    const { userInfo } = getState().userAuth;
    try {
        dispatch({ type: IMAGE_UPLOAD_REQUEST });

        const { data } = await api.post(
            `/image/user`,
            {
                userId: userId,
                image: image
            },
            configUtil(userInfo.token)
        );

        dispatch({ type: IMAGE_UPLOAD_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: IMAGE_UPLOAD_FAIL, payload: errorHandler(error) });
    }
}

export const deleteProfileImage = (userId, imagePath) => async (dispatch, getState) =>{
    const { userInfo } = getState().userAuth;
    try {
        dispatch({ type: IMAGE_DELETE_REQUEST });

        const { data } = await api.delete(
            `/image/user?userId=${userId}&image=${imagePath}`,
            configUtil(userInfo.token)
        );

        dispatch({ type: IMAGE_DELETE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: IMAGE_DELETE_FAIL, payload: errorHandler(error) });
    }
}