import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { getUserInfo } from '../../actions/user';
import '../../Utils/css/seller.css';
import { isAdminOrSupport, isSeller } from '../../Utils/helpers/user';
import { Image } from 'cloudinary-react';

export const ShowSellerScreen = ({ match, history }) => {
    const userIdent = match.params.userIdent;
    const dispatch = useDispatch();

    // User Auth
    const userDetails = useSelector((state) => state.userDetails);
    const { error, user } = userDetails;

    const productList = useSelector((state) => state.productList);
    const { products } = productList;

    // auth
    useEffect(() => {
        if (error || (user && (!isAdminOrSupport(user) && !isSeller(user)))) {
            history.push('/')
            return;
        }

        dispatch(getUserInfo(userIdent));
    }, [dispatch, history, userIdent, user, error, products])
    return (
        <>
        {user && (
        <div className='container' 
            style={{
            color: "rgb(0, 0, 0)",
            paddingTop: "0px",
            paddingBottom: "0px",
            paddingLeft: "20px",
            paddingRight: "20px",
            textAlign: "center"
             }}>

        <div className="md:flex"
            style={{
            borderRadius: "50px"
             }}>

            <div className="container-pictures"
                style={{
                maxWidth: "960px",
                margin: "0 auto"
                }}>

                <div className="photos"
                    style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                    alignContent: "stretch",
                    maxWidth: "1200px"
                    }}>

                    {(user.images && user.images.length > 0) ? (
                        <div className='image-grid'>
                            {user.images.map((image) => {
                                return (
                                    <Image
                                        key={image}
                                        className="img-lg"
                                        cloudName="dycgvrxas"
                                        publicId={image}
                                    />
                                )
                            })}
                        </div>
                    ): (
                        <>
                            <img className='picture2'src={'/images/sellers/roman2.jpg'} alt=""
                                style={{
                                maxWidth:"444px",
                                maxHeight:"444px",
                                width: "100%",
                                padding:"5px",
                                objectFit: "cover",
                                display: "flex",
                                borderRadius: "30px"
                                }}/>

                            <img className='picture3'src={'/images/sellers/roman3.jpg'} alt=""
                                style={{
                                maxWidth:"444px",
                                maxHeight:"444px",
                                width: "100%",
                                padding:"5px",
                                objectFit: "cover",
                                display: "flex",
                                borderRadius: "30px"
                                }}/>

                            <img className='picture4'src={'/images/sellers/roman4.jpg'} alt=""
                                style={{
                                maxWidth:"444px",
                                maxHeight:"444px",
                                width: "100%",
                                padding:"5px",
                                objectFit: "cover",
                                display: "flex",
                                borderRadius: "30px"
                                }}/>
                        </>
                    )}
                </div>
            </div>

            <div className= "seller-description"
                style={{
                    paddingTop: "20px",
                    paddingLeft: "40px",
                    paddingRight: "40px",
                    borderRadius: "40px"}}>
                <br />
            
                <div className= "account">
                    <div className="seller_icon" >
                        <img src={'/images/Beekeeper_Black.png'} style={{ height: "40px", width: "40px"}} alt="" /></div>  <br />

                    <div className="seller_label" style={{fontSize: "2.5rem", color: "gray", lineHeight: "35px" }}> <span> Imkerprofil</span></div>
                    <div className="seller_name"  style={{fontSize: "3.5rem", lineHeight: 'normal'}}>
                        <span>{user.username}</span>
                    </div> 
                    <p className='mt-3'>{user.description}</p>
                </div>
                <br /><br />

                <br />

                <div className= "seller-products">
                    <img src={'/images/HoneyJar_Black.png'} style={{ height: "40px", width: "40px"}} alt=""/> 
                    <Link to={`/search?seller=${user._id}`}>Meine Produkte</Link>
                </div> 

                <br />

                <div className= "seller-location">
                    <img src={'/images/Beehive_Black.png'} style={{ height: "40px", width: "40px"}} alt=""/>{user.zip} {user.city}
                </div>  
                <br /><br />
            </div>
        </div>

       {/* <div className='hidden md:flex'>
            {products && products.length > 0 ? (
                <Row>
                    {products.map((product, i) => {
                        return (
                            <Col key={i} sm={12} md={6} lg={4} xl={4} className="mb-3">
                                <Product
                                    product={product}
                                    lang={language}
                                />
                            </Col>
                        );
                    })}
                </Row>
            ): (
                <div>
                    {f.noProductsFound[language]}
                </div>
            )}
        </div>*/}

    </div>
    )}
    </>)
}

export default ShowSellerScreen;