import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
    Row,
    Col,
    ListGroup,
    OverlayTrigger,
    Tooltip,
    Button,
    Card,
    Container,
    Table,
} from "react-bootstrap";
import { Image } from "cloudinary-react";
import Message from "../components/message";
import {
    updateCart,
    removeItemInCart,
    updateCartToDB,
    removeCartItemInDB,
} from "../actions/cart";
import Loader from "../components/loader";
import { ORDER_CREATE_RESET } from "../actions/types";
import { c } from "../Utils/translateLibrary/cart";
import { p } from "../Utils/translateLibrary/productDetails";
import '../Utils/css/table.css';

const CartScreen = ({ match, location, history }) => {
    const [subTotal, setSubTotal] = useState(0);
    const [total, setTotal] = useState(0);

    const dispatch = useDispatch();
    const cart = useSelector((state) => state.cart);
    const { cartItems, cartLoading, cartError } = cart;
    
    const userAuth = useSelector((state) => state.userAuth);
    const { userInfo } = userAuth;

    // Settings Reducer
    const settings = useSelector((state) => state.settings);
    const { language } = settings;

    useEffect(() => {
        if(cartItems && Array.isArray(cartItems)) {
            let _counter = 0;
            cartItems.forEach((item) => {
                if(item.qty <= 0) {
                    return;
                }

                let _discount = Number(item.discount);
                if(!Number.isNaN(_discount) && _discount > 0) {
                    _counter += _discount * item.qty;
                    return;
                }

                let _price = Number(item.price);
                if(!Number.isNaN(_price) && _price > 0) {
                    _counter += _price * item.qty;
                }
            });
    
            setSubTotal(_counter.toFixed(2));
            setTotal(_counter.toFixed(2));
        }
    }, [dispatch, history, cartItems]);

    const removeFromCartHandler = (cartId) => {
        if (userInfo) {
            dispatch(removeCartItemInDB(cartId));
        } else {
            dispatch(removeItemInCart(cartId));
        }
    };

    const checkoutHandler = () => {
        // to make that the order state is reset
        dispatch({ type: ORDER_CREATE_RESET });
        history.push("/login?redirect=shipping");
    };

    const quantityOnChangeHandler = (item, qty) => {
        if(qty <= 0) {
            removeFromCartHandler(item._id);
            return;
        }

        if (userInfo) {
            dispatch(
                updateCartToDB(
                    item._id,
                    qty
                )
            );
        } else {
            dispatch(
                updateCart(
                    item._id,
                    qty,
                    item.productId,
                    item.weight
                )
            );
        }
    };

    return (
        <>
        <Container className="table-section">
            <Row style={{borderRadius:"20px"}}>
                <Col md={8}>
                    <p>
                        <i className="fas fa-truck mr-3"></i>
                    Bestellungen über 100 € qualifizieren sich für einen
                    kostenlosen Versand
                    </p>
                    {cartError && (
                        <Message variant="hf-secondary">
                            {cartError}
                        </Message>
                    )}
                    {Array.isArray(cartItems) && cartItems.length === 0 ? (
                        <Message variant="hf-secondary">
                            {c.emptyCart[language]}{" "}
                            <Link to="/">{c.goBack[language]}</Link>
                        </Message>
                    ) : (
                        <ListGroup
                            variant="flush"
                            style={
                                cartLoading
                                    ? { opacity: "0.4", backgroundColor: "grey" }
                                    : null
                            }
                        >
                            {cartLoading && (
                                <Loader />
                            )}
                            <Table striped hover responsive>
                                <thead>
                                    <tr>
                                        <th colSpan={1}></th>
                                        <th colSpan={2} className="hidden md:d-table-cell"></th>
                                        <th colSpan={3} >
                                            <strong className="text-primary">{p.name[language]}</strong>
                                        </th>
                                        <th colSpan={2}>
                                            <strong>{p.weight[language]}</strong>
                                        </th>
                                        <th colSpan={2}>
                                            <strong>{p.price[language]}</strong>
                                        </th>
                                        <th colSpan={2}>
                                            <strong>{p.quantity[language]}</strong>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(cartItems) && cartItems.length > 0 &&
                                        cartItems.map((item, index) => {
                                            return (
                                                <tr key={'order_item_' + index}>
                                                    <td colSpan={1}>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="button-tooltip">
                                                                        {p.removeFromCart[language]}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <Button
                                                                    type="button"
                                                                    onClick={() =>
                                                                        removeFromCartHandler(
                                                                            item.productId
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="fas fa-trash" />
                                                                </Button>
                                                            </OverlayTrigger>
                                                    </td>
                                                    <td colSpan={2} className="hidden md:d-table-cell">
                                                        <Image
                                                            cloudName="dycgvrxas"
                                                            publicId={item.image ? item.image[0] : process.env.REACT_APP_DEFAULT_PRODUCT_IMAGE}
                                                            width={100}
                                                            alt={item.name}
                                                            variant="fluid"
                                                            rounded="true"
                                                        />
                                                    </td>
                                                    <td colSpan={3} >
                                                        <Link
                                                            to={`/product/${item.productId}`}
                                                            style={{
                                                                textDecoration: "none",
                                                            }}
                                                        >
                                                            <strong
                                                                className={
                                                                    "text-primary"
                                                                }
                                                            >
                                                            {item.name}
                                                            </strong>
                                                        </Link>
                                                    </td>
                                                    <td colSpan={2}>
                                                        {item.weight} g
                                                    </td>
                                                    <td colSpan={2}>
                                                        €
                                                        {item.discount > 0
                                                            ? Number(item.discount).toFixed(2)
                                                            : Number(item.price).toFixed(2)}
                                                    </td>
                                                    <td colSpan={2} className="action-col">
                                                        <i onClick={() => quantityOnChangeHandler(item, item.qty - 1)} className="fa fa-minus" role="button"></i> 
                                                        <span>{item.qty} {p.pieceShort[language]}</span>
                                                        <i onClick={() => quantityOnChangeHandler(item, item.qty + 1)} className="fa fa-plus" role="button"></i>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </Table>
                        </ListGroup>
                    )}
                </Col>
                <Col md={4}>
                    <Card>
                        <ListGroup variant="flush" className="border">
                            <ListGroup.Item style={{padding: 0}}>
                                <h2 style={{ textAlign: "center", fontWeight:"bolder", backgroundColor: "var(--primary)"}}>
                                    {c.checkoutNow[language]}
                                </h2>
                                <Row style={{padding: '.25rem 1rem'}}>
                                    <Col md={8}>
                                        {c.subTotal[language]} (
                                        {Array.isArray(cartItems) ? (cartItems.reduce(
                                            (acc, item) => acc + item.qty,
                                            0
                                        )) : (0)}
                                        ) {c.items[language]}
                                    </Col>
                                    <Col md={4} style={{ textAlign: "right" }}>
                                        € {subTotal}
                                    </Col>
                                </Row>
                                <Row style={{padding: '.25rem 1rem'}}>
                                    <Col md={6} style={{ fontWeight: "bolder",backgroundColor:"white" }}>{c.total[language]}</Col>
                                    <Col md={6} style={{ textAlign: "right",fontWeight: "bolder", backgroundColor:"white" }}>
                                        € {total}
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <span className="d-grid gap-2">
                                    <Button className="btn btn-primary"
                                        type="button"
                                        disabled={
                                            cartItems.length === 0 || cartLoading
                                        }
                                        onClick={checkoutHandler}
                                    >
                                        {c.proceed[language]}
                                    </Button>
                                </span>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card>
                </Col>
            </Row>
        </Container>
        </>
    );
};

export default CartScreen;
