import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import Header from './components/header'
import Footer from './components/footer'
import SearchScreen from './screens/searchScreen';
import ProductScreen from './screens/ProductScreen/productScreen'
import LoginScreen from './screens/loginScreen'
import CartScreen from './screens/cartScreen'
import RegisterScreen from './screens/registerScreen'

// Footer
import ContactFormular from './components/contact-us'
import imprint from './components/imprint'
import agb from './components/agb'
import dataProtection from './components/data-protection';

// Search Page Sellers
import Sellersearch from './components/sellerSearch'

// Sellers
import SellerProductListScreen from './screens/Seller/Product/Index';
import ChatScreen from './screens/Users/chatScreen';

// Home Screen
import HomeScreen from './screens/homeScreen'

// User Profile related screens
import EditProfile from './screens/UpdateUserInfo/editProfile'
import ChangePassword from './screens/UpdateUserInfo/changePassword'
import Settings from './screens/UpdateUserInfo/settings'
import SettingsScreen from './screens/settingsScreen'

// Order related screens
import OrderHistoryScreen from './screens/orderHistoryScreen'
import ShippingScreen from './screens/shippingScreen'
import PaymentScreen from './screens/paymentScreen'
import PlaceOrderScreen from './screens/placeOrderScreen'
import OrderScreen from './screens/orderScreen'

// Admin Actions
import UserListScreen from './screens/Admin/userListScreen'
import EditUserScreen from './screens/Admin/userEditScreen'
import OrderListScreen from './screens/Admin/orderListScreen'

import './index.css'
import SellerEditOrCreateProductScreen from './screens/Seller/Product/EditOrCreate'
import ShowSellerScreen from './screens/Seller/Show';
import AdminProductListScreen from './screens/Admin/Product/Index'
import AdminEditOrCreateProductScreen from './screens/Admin/Product/EditOrCreate'
import ForgotPassword from './screens/Auth/forgotPassword'
import VerificationScreen from './screens/Auth/verificationScreen'
import ResetPassword from './screens/Auth/resetPassword'

const App = () => {
  return (
    <Router>
      <Header />
      <main>
        
        {/* Home Page */}
        <Route path="/" exact component={HomeScreen} />
        <Route path="/search" exact component={SearchScreen} />
        <Route path="/cart/:id?" exact component={CartScreen} />
        <Route path="/order/:id" exact component={OrderScreen} />
        <Route path="/register" exact component={RegisterScreen} />
        <Route path="/login" exact component={LoginScreen} />
        <Route path="/orders" exact component={OrderHistoryScreen} />
        <Route path="/admin/products" exact component={AdminProductListScreen} />
        <Route path="/admin/product" exact component={AdminEditOrCreateProductScreen} />
        <Route path="/admin/product/:id/edit" exact component={AdminEditOrCreateProductScreen}/>
        <Route path="/admin/users" exact component={UserListScreen} />

        <Container>
          <Route path="/profile" exact component={EditProfile} />
          <Route path="/forgot-password" exact component={ForgotPassword} />
          <Route path="/reset-password" exact component={ResetPassword} />
          <Route
            path="/profile/change-password"
            exact
            component={ChangePassword}
          />
          <Route path="/profile/settings" exact component={Settings} />
          <Route path="/verification" exact component={VerificationScreen} />


          {/* Footer Pages */}
          <Route path="/contact-us" exact component={ContactFormular}  />
          <Route path="/kontakt" exact component={ContactFormular}  />
          <Route path="/imprint" exact component={imprint}  />
          <Route path="/agb" exact component={agb}  />
          <Route path="/data-protection" exact component={dataProtection}  />

          {/*  Search Page Sellers */}
          <Route path="/seller-search" exact component={Sellersearch}  />

          {/* Sellers Pages */}
          <Route path="/sellers/:userIdent" exact component={ShowSellerScreen}  />

          {/* Seller Product List  */}
          <Route path="/seller/products" exact component={SellerProductListScreen}  />
          <Route
            path="/seller/product/:id/edit"
            exact
            component={SellerEditOrCreateProductScreen}
          />

          <Route path="/seller/product" exact component={SellerEditOrCreateProductScreen} />

          {/*Order paths*/}
          <Route path="/product/:id/:menu?" exact component={ProductScreen} />
          <Route path="/shipping" exact component={ShippingScreen} />
          <Route path="/payment" exact component={PaymentScreen} />
          <Route path="/placeorder" exact component={PlaceOrderScreen} />
          <Route path="/settings" exact component={SettingsScreen} />
          <Route path="/chat" exact component={ChatScreen} />
          <Route path="/chat/:userId" exact component={ChatScreen} />

          {/*Admin actions */}
          <Route path="/admin/user/:id/edit" exact component={EditUserScreen} />

          {/*Admin Order List Screen */}
          <Route path="/admin/orders" exact component={OrderListScreen} />
        </Container>
      </main>
      <Footer />
    </Router>
  )
}

export default App
