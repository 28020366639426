import React from 'react'
import { Nav } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { summary } from "../Utils/translateLibrary/orderSummary";

const CheckoutSteps = ({ step1, step2, step3, step4, step5 }) => {
  
    // Settings
    const settings = useSelector((state) => state.settings);
    const { language } = settings;

  return (
    <Nav className="justify-content-center my-3">
      <Nav.Item>
        {step2 ? (
          <LinkContainer to="/shipping">
            <Nav.Link>{summary.shipping[language]}</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>{summary.shipping[language]}</Nav.Link>
        )}
      </Nav.Item>
      <Nav.Item>
        {step3 ? (
          <LinkContainer to="/payment">
            <Nav.Link>{summary.payment[language]}</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>{summary.payment[language]}</Nav.Link>
        )}
      </Nav.Item>
      <Nav.Item>
        {step4 ? (
          <LinkContainer to="/place-order">
            <Nav.Link>{summary.placeOrder[language]}</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>{summary.placeOrder[language]}</Nav.Link>
        )}
      </Nav.Item>
      <Nav.Item>
        {step5 ? (
          <LinkContainer to="/order/">
            <Nav.Link>{summary.pay[language]}</Nav.Link> 
          </LinkContainer>
        ) : (
          <Nav.Link disabled>{summary.pay[language]}</Nav.Link>
        )}
      </Nav.Item>
    </Nav>
  )
}

export default CheckoutSteps
