export const countrySettings = [
    {
        label: "Deutschland",
        value: "ger",
    },
    {
        label: "France",
        value: "fr",
    },
];

export const currencySettings = [
    {
        label: "Euro",
        value: "eur",
    },
    {
        label: "Dollar",
        value: "usd",
    },
];
