import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Route } from 'react-router-dom'
import { Table, Button, ButtonGroup, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../../components/message'
import Loader from '../../../components/loader'
import SearchBox from '../../../components/searchBox'
import { deleteProduct, fetchAllProducts } from '../../../actions/product'
import { p } from "../../../Utils/translateLibrary/productDetails";
import { consistencies, categories } from '../../../Utils/translateLibrary/product';
import "../../../Utils/css/web.css";
import Paginate from '../../../components/paginate'
import { isAdmin, isAdminOrSupport } from '../../../Utils/helpers/user'

const AdminProductListScreen = ({ history, match }) => {

    const searchParams = window.location.search;
    const params = new URLSearchParams(searchParams);
    const search = params.get('q') || '';
    const pageNumber = params.get('page') || 1;
    const createSuccess = params.get('success') || false;

    const dispatch = useDispatch();
  
    // User Auth
    const userAuth = useSelector((state) => state.userAuth)
    const { userInfo } = userAuth
  
    // User Settings
    const settings = useSelector((state) => state.settings)
    const { language } = settings
  
    // Product List
    const productList = useSelector((state) => state.productList)
    const { loading, products, error, page, pages } = productList
  
    // Product Delete
    const productDelete = useSelector((state) => state.productDelete)
    const {
      loading: loadingDelete,
      error: errorDelete,
      success: successDelete,
    } = productDelete

  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else if (!isAdminOrSupport(userInfo)) {
      history.push('/')
    } else  {
      dispatch(fetchAllProducts(undefined, pageNumber, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, search))
    }
  }, [dispatch, history, userInfo, successDelete, pageNumber, search])

  const deleteHandler = (e, id) => {
    e.preventDefault()
    if (window.confirm('Möchten Sie das Produkt löschen ?')) {
      dispatch(deleteProduct(id))
    }
  }

  const createProductHandler = () => {
    history.push('/admin/product')
  }
  return (
    <>
      <Container>
        {isAdmin(userInfo) && (<div className="d-flex justify-content-end">
            <Button
              className="my-3 align-items-right"
              onClick={createProductHandler}
            >
              <i className="fas fa-plus mr-3" />
              { p.create[language] }
          </Button>
        </div>)}

        <Route
          render={({ history }) => (
            <SearchBox
              value={search}
              history={history}
              product
              user={userInfo}
              placeholder="Suche ein Produkt"
            />
          )}
        />
        
        {loadingDelete && <Loader />}
        {errorDelete && <Message variant="danger">{errorDelete}</Message>}
        {createSuccess && <Message variant="success">Produkt erfolgreich erstellt !</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : products ? (
          <>
            <Table bordered hover responsive className="table-sm">
              <thead>
                <tr>
                  <th>Produkt Id</th>
                  <th>Produkt Name</th>
                  <th>Preis</th>
                  <th>Kategorie</th>
                  <th>Konsistenz</th>
                  <th>Verkäufer</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {products?.map((product) => {
                  return (
                    <tr key={product._id}>
                      <td>{product._id}</td>
                      <td>{product.name}</td>
                      <td>
                        {product.discount ? product.discount.toFixed(2) : product.price.toFixed(2)} €
                      </td>
                      <td>{categories[product.category][language]}</td>
                      <td>{consistencies[product.consistency][language]}</td>
                      <td>{product.productOwner.username}</td>
                      <td>
                        <ButtonGroup className="d-flex justify-content-center">
                          <LinkContainer
                            to={`/admin/product/${product._id}/edit`}
                          >
                            <Button className="ml-1 btn-sm btn-primary">
                              <i className="fas fa-edit" />
                            </Button>
                          </LinkContainer>
                          <Button
                            variant="danger"
                            className="mr-1 btn-sm"
                            onClick={(e) => deleteHandler(e, product._id)}
                          >
                            <i className="fas fa-trash" />
                          </Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
            <Paginate
              page={page}
              pages={pages}
            ></Paginate>
          </>
        ) : (
          <></>
        )}
      </Container>
    </>
  )
}

export default AdminProductListScreen;
