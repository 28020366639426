import { combineReducers } from "redux";
import {
    productListReducer,
    productDetailsReducer,
    productDeleteReducer,
    productCreateReducer,
    productEditReducer,
    latestProductReducer,
    productsYouMayLikeReducer,
} from "./productReducers";
import { cartReducer } from "./cartReducers";
import { changeSettingsReducer } from "./settingsReducers";
import {
    userAuthenticationReducer,
    userRegisterReducer,
    userDetailsReducer,
    userUpdateProfileReducer,
    userUpdatePasswordReducer,
    userUpdateLanguageReducer,
    userListReducer,
    userDeleteReducer,
    userEditReducer,
    sendEmailReducer,
    sellerListReducer
} from "./userReducers";
import {
    orderCreateReducer,
    orderDetailsReducer,
    orderPayReducer,
    checkoutWithStripeReducer,
    getUserOrdersReducer,
    listAllOrdersReducer,
    deleteOrderReducer,
    orderDeliverReducer,
} from "./orderReducers";
import {
    messageListReducer,
    dialogListReducer
} from "./messageReducers";
import { deleteImageReducer, uploadImageReducer } from "./imageReducers";

export default combineReducers({
    // Product
    productList: productListReducer,
    productDetails: productDetailsReducer,
    productDelete: productDeleteReducer,
    productCreate: productCreateReducer,
    productEdit: productEditReducer,
    latestProducts: latestProductReducer,
    productsYouMayLike: productsYouMayLikeReducer,
    // Cart
    cart: cartReducer,
    // Settings
    settings: changeSettingsReducer,
    // User
    userAuth: userAuthenticationReducer,
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    userUpdateProfile: userUpdateProfileReducer,
    updateUserPassword: userUpdatePasswordReducer,
    updateUserLanguage: userUpdateLanguageReducer,
    userList: userListReducer,
    userDelete: userDeleteReducer,
    userEdit: userEditReducer,
    sellerList: sellerListReducer,
    
    // Messages
    messageList: messageListReducer,
    dialogList: dialogListReducer,

    // Orders
    orderCreate: orderCreateReducer,
    orderDetails: orderDetailsReducer,
    orderPay: orderPayReducer,
    checkoutWithStripe: checkoutWithStripeReducer,
    userOrders: getUserOrdersReducer,
    listOrders: listAllOrdersReducer,
    orderDelete: deleteOrderReducer,
    orderDeliver: orderDeliverReducer,

    // Email
    sendEmail: sendEmailReducer,

    // Images
    uploadImage: uploadImageReducer,
    deleteImage: deleteImageReducer
});

const cartItemsFromStorage = localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [];

const userInfoFromStorage = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))
    : null;

const shippingAddressFromStorage = localStorage.getItem("shippingAddress")
    ? JSON.parse(localStorage.getItem("shippingAddress"))
    : null;

const paymentMethodFromStorage = localStorage.getItem("paymentMethod")
    ? JSON.parse(localStorage.getItem("paymentMethod"))
    : null;

const userShippingInfoFromStrage = localStorage.getItem("userShippingInfo")
    ? JSON.parse(localStorage.getItem("userShippingInfo"))
    : null;

const billingAddressFromStorage = localStorage.getItem("billingAddress")
    ? JSON.parse(localStorage.getItem("billingAddress"))
    : null;

const userBillingInfoFromStorage = localStorage.getItem("userBillingInfo")
        ? JSON.parse(localStorage.getItem("userBillingInfo"))
        : null;

const settingsFromStorage = localStorage.getItem("settings")
    ? JSON.parse(localStorage.getItem("settings"))
    : { language: "de", country: "de", currency: "eur" };

export const initialState = {
    cart: {
        cartItems: cartItemsFromStorage,
        shippingAddress: shippingAddressFromStorage,
        paymentMethod: paymentMethodFromStorage,
        userShippingInfo: userShippingInfoFromStrage,
        billingAddress: billingAddressFromStorage,
        userBillingInfo: userBillingInfoFromStorage
    },
    settings: settingsFromStorage,
    userAuth: { userInfo: userInfoFromStorage },
};
