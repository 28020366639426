import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Button, Form} from 'react-bootstrap';
import Loader from '../components/loader';
import FormComponent from '../components/formComponent';
import FormContainer from '../components/formContainer';
import Message from '../components/message'
import {sendEmail} from '../actions/user';
import {c,g, salutations} from '../Utils/translateLibrary/contact';
import { validation } from '../Utils/translateLibrary/validation'; 

const ContactFormular = () => {
    const [salutation, setSalutation] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [subject, setSubject] = useState('');
    const [content, setContent] = useState('');
    const [validationError, setErrorMessage] = useState('');

    const settings = useSelector((state) => state.settings);
    const { language } = settings;

    const sendEmailState = useSelector((state) => state.sendEmail);
    const { loading,  error, success } = sendEmailState;
    const dispatch = useDispatch();

    const validate = () => {
        if(salutation === '' || firstname === '' || lastname === '' || email === '' || subject === '' || content === '') {
          throw new Error(validation.errors.required_field[language]);
        }
    }
    
    const clearInput = () => {
        setSalutation('');
        setFirstname('');
        setLastname('');
        setEmail('');
        setMobile('');
        setSubject('');
        setContent('');
    }

    const submitHandler = (e) => {
        e.preventDefault()
    
        try {
          validate();
        } catch (validationError) {
console.log(validationError);
          setErrorMessage(validationError.message);
          return;
        }

        const _user = {
            salutation: salutations[salutation]['de'],
            firstname: firstname,
            lastname: lastname,
            mobile: mobile,
            senderEmail: process.env.REACT_APP_MAIL_FROM,
            receiverEmail: process.env.REACT_APP_MAIL_FROM
        };
    
console.log(_user);
        dispatch(sendEmail(_user, subject, content))
        clearInput();
    }

    return (
        <div className="container-contact">
        <FormContainer>
            <div className="contact-title"><br></br><h2>{c.title[language]}</h2></div> 
            
            {success && <Message variant="success">{success}</Message>}
            {error && <Message variant="danger">{error}</Message>}
            {validationError && <Message variant="danger">{validationError}</Message>}
            {loading ? (
            <Loader />
            ) : (
            <div className="contact-inputs"> 
                <Form onSubmit={submitHandler} autoComplete="on">
                    <Form.Group controlId="formBasicSelect" className='py-2'>
                    <Form.Label style={{ fontWeight:"bolder"}}>{g.salutation[language]} *</Form.Label>
                    <Form.Control
                        as="select"
                        value={salutation}
                        className="large-input border"
                        onChange={e => {
                        setSalutation(e.target.value);
                    }}
                    >
                        <option value="" disabled>{c.selectSalutation[language]}</option>
                        {Object.values(salutations).map((salutation, key) => {
                        return (
                            <option value={key} key={'salutation_' + key}>{salutation[language]}</option>
                        )
                        })}
                    </Form.Control>
                    </Form.Group>

                  <FormComponent
                    label={g.firstname[language] + ' *'}
                    value={firstname}
                    required
                    className="large-input border"
                    onChange={(e) => setFirstname(e.target.value)}
                  />

                  <FormComponent
                    label={g.lastname[language] + ' *'}
                    value={lastname}
                    required
                    className="large-input border"
                    onChange={(e) => setLastname(e.target.value)}
                  />

                  <FormComponent
                    label={g.email[language] + ' *'}
                    value={email}
                    required
                    className="large-input border"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  
                  <FormComponent
                    label={g.mobile[language]}
                    value={mobile}
                    className="large-input border"
                    onChange={(e) => setMobile(e.target.value)}
                  />
                  
                  <FormComponent
                    label={c.subject[language] + ' *'}
                    value={subject}
                    required
                    className="large-input border"
                    onChange={(e) => setSubject(e.target.value)}
                  />

                  <div className='mt-auto'>
                    <Form.Label style={{ fontWeight:"bolder"}}>{c.content[language]} *</Form.Label>
                    <textarea onChange={(e) => setContent(e.target.value)} value={content} placeholder="..." rows={3} className="w-100 large-input border"></textarea>
                  </div>

                    <div className="forms--required" style={{textAlign: "center"}}>
                            Die mit einem * markierten Felder sind Pflichtfelder.
                            </div>
                        <p className="privacy-information block-group" style={{textAlign: "center"}}> Ich habe die <a title="Datenschutzbestimmungen" href="/data-protection" target="_blank"><u>Datenschutzbestimmungen</u></a> zur Kenntnis genommen. </p>
                        <div className="buttons mb-3" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                          <Button className='btn--with-padding border'>Senden</Button>
                        </div> 
                </Form> 
            </div> 
            )}
            </FormContainer>
        </div> 
    )
}

export default ContactFormular;