import React, { useState, useEffect } from "react";
import {
    Form,
    Button,
    Row,
    Col,
    InputGroup,
    FormControl,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { saveBillingAddress, saveShippingAddress, saveUserBillingInfo, saveUserShippingInfo } from "../actions/cart";
import FormContainer from "../components/formContainer";
import FormComponent from "../components/formComponent";
import CheckoutSteps from "../components/checkoutSteps";
import Loader from "../components/loader";
import { getUserInfo } from "../actions/user";
import { shippingTranslate } from "../Utils/translateLibrary/shipping";
import "../Utils/css/web.css";

const ShippingScreen = ({ history }) => {
    const [isBillingAddressDifferent, setIsBillingAddressDifferent] = useState(false);
    
    // shipping data
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [zip, setZip] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [contactNum, setContactNum] = useState("");

    // billing data
    const [billingName, setBillingName] = useState("");
    const [billingSurname, setBillingSurname] = useState("");
    const [billingEmail, setBillingEmail] = useState("");
    const [billingAddress1, setBillingAddress1] = useState("");
    const [billingAddress2, setBillingAddress2] = useState("");
    const [billingZip, setBillingZip] = useState("");
    const [billingCity, setBillingCity] = useState("");
    const [billingCountry, setBillingCountry] = useState("");
    const [billingContactNum, setBillingContactNum] = useState("");

    // Get States from Redux
    const userAuth = useSelector((state) => state.userAuth);
    const { userInfo } = userAuth;

    const userDetails = useSelector((state) => state.userDetails);
    const { user, loading } = userDetails;

    // Settings Reducers
    const settings = useSelector((state) => state.settings);
    const { language } = settings;

    const dispatch = useDispatch();

    useEffect(() => {
        if (!userInfo) {
            history.push("/login");
        } else {
            if (!user) {
                dispatch(getUserInfo(userInfo._id));
            } else {
                setAddress1(user.address1 ? user.address1 : "");
                setAddress2(user.address2 ? user.address2 : "");
                setCountry(user.country ? user.country : "");
                setZip(user.zip || "");
                setCity(user.city ? user.city : "");

                setEmail(user.email ? user.email : "");
                setName(user.name ? user.name : "");
                setSurname(user.surname ? user.surname : "");
                setContactNum(user.contactNum ? user.contactNum : "");
            }
        }
    }, [dispatch, history, userInfo, user]);

    const submitHandler = (e) => {
        e.preventDefault();

        dispatch(
            saveShippingAddress({
                address1,
                address2,
                country,
                zip,
                city,
            })
        );

        dispatch(
            saveUserShippingInfo({
                name,
                surname,
                email,
                contactNum,
                userId: userInfo._id,
            })
        );

        if(isBillingAddressDifferent) {
            dispatch(
                saveBillingAddress({
                    billingAddress1,
                    billingAddress2,
                    billingCountry,
                    billingZip,
                    billingCity,
                })
            );
    
            dispatch(
                saveUserBillingInfo({
                    billingName,
                    billingSurname,
                    billingEmail,
                    billingContactNum,
                    userId: userInfo._id,
                })
            );
        }

        history.push("/payment");
    };

    const isValid = () => {
        if(name === "" ||
            surname === "" ||
            email === "" ||
            address1 === "" ||
            contactNum === "" ||
            country === "" ||
            zip === "" ||
            city === "") {
            return false;
        }

        if(!isBillingAddressDifferent) {
            return true;
        }

        return !(billingName === "" ||
        billingSurname === "" ||
        billingEmail === "" ||
        billingAddress1 === "" ||
        billingContactNum === "" ||
        billingCountry === "" ||
        billingZip === '' ||
        billingCity === "");
    }

    return (
        <FormContainer>
            <CheckoutSteps step1 step2 />
            {loading ? (
                <Loader />
            ) : (
                <Form onSubmit={submitHandler} autoComplete="on">
                    <Row>
                        <Col md={6}>
                            <Form.Label style={{ fontWeight:"bolder"}}>{shippingTranslate.receiver[language]}</Form.Label>
                            <Row>
                                <Col md={6} >
                                    <FormComponent 
                                        type="text"
                                        value={name}
                                        required={true}
                                        placeholder={shippingTranslate.givenName[language]}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Col>
                                <Col md={6}>
                                    <FormComponent 
                                        type="text"
                                        value={surname}
                                        required={true}
                                        placeholder={shippingTranslate.surname[language]}
                                        onChange={(e) => setSurname(e.target.value)}
                                    />
                                </Col>
                            </Row>
                            <FormComponent 
                                type="email"
                                value={email}
                                required={true}
                                placeholder={shippingTranslate.email[language]}
                                onChange={(e) => setEmail(e.target.value)}
                            />

                            <FormComponent 
                                label={shippingTranslate.addressLine1[language]}
                                type="text"
                                value={address1}
                                required={true}
                                placeholder={shippingTranslate.street[language]}
                                onChange={(e) => setAddress1(e.target.value)}
                            />
                            <FormComponent 
                                type="text"
                                value={address2}
                                placeholder={shippingTranslate.addressLine2[language]}
                                onChange={(e) => setAddress2(e.target.value)}
                            />
                            <Form.Group >
                                <Form.Select
                                    onChange={(e) => setCountry(e.target.value)}
                                    required
                                    value={country}
                                >
                                    <option disabled value="">Bitte wählen Sie ein Land aus</option>
                                    <option value="Deutschland">Deutschland</option>
                                </Form.Select>
                            </Form.Group>

                            <FormComponent 
                                required={true}
                                placeholder={shippingTranslate.zip[language]}
                                value={zip}
                                onChange={(e) => setZip(e.target.value)}
                            />

                            <FormComponent 
                                required={true}
                                type="text"
                                placeholder={shippingTranslate.city[language]}
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                            />

                            <Form.Group className="py-2" >
                                <Form.Label >
                                    {shippingTranslate.contact[language]}
                                </Form.Label>
                                <InputGroup>
                                    <InputGroup.Text className="filter-label">
                                        {country === "Deutschland" ? "+49" : "Bitte wählen Sie ein Land aus"}
                                    </InputGroup.Text>
                                    <FormControl disabled={!country} placeholder="Telefonnummer" style={{"border":  "1px solid black"}}
                                        value={contactNum}
                                        onChange={(e) => setContactNum(e.target.value)}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Col>

                        {/* Billing Address */}
                        <Col md={6}>
                            <Form.Label onClick={(e) => setIsBillingAddressDifferent(!isBillingAddressDifferent)} role="button" className="input-label mb-2 mr-3">{shippingTranslate.isBillingAddressDifferent[language]}</Form.Label>
                            <input type="checkbox" checked={isBillingAddressDifferent} onChange={(e) => setIsBillingAddressDifferent(!isBillingAddressDifferent)} />
                            {isBillingAddressDifferent && <div>
                                <Row>
                                    <Col md={6} >
                                        <FormComponent 
                                            type="text"
                                            value={billingName}
                                            required={true}
                                            placeholder={shippingTranslate.givenName[language]}
                                            onChange={(e) => setBillingName(e.target.value)}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <FormComponent 
                                            type="text"
                                            value={billingSurname}
                                            required={true}
                                            placeholder={shippingTranslate.surname[language]}
                                            onChange={(e) => setBillingSurname(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                                <FormComponent 
                                    type="email"
                                    value={billingEmail}
                                    required={true}
                                    placeholder={shippingTranslate.email[language]}
                                    onChange={(e) => setBillingEmail(e.target.value)}
                                />

                                <FormComponent 
                                    label={shippingTranslate.addressLine1[language]}
                                    type="text"
                                    value={billingAddress1}
                                    required={true}
                                    placeholder={shippingTranslate.street[language]}
                                    onChange={(e) => setBillingAddress1(e.target.value)}
                                />

                                <FormComponent 
                                    type="text"
                                    value={billingAddress2}
                                    placeholder={shippingTranslate.addressLine2[language]}
                                    onChange={(e) => setBillingAddress2(e.target.value)}
                                />
                                <Form.Group >
                                    <Form.Select style={{  borderRadius:"40px", borderWidth:"3px"}}
                                        onChange={(e) => setBillingCountry(e.target.value)}
                                        required
                                        value={billingCountry}
                                    >
                                        <option disabled value="">Bitte wählen Sie ein Land aus</option>
                                        <option value="Deutschland">Deutschland</option>
                                    </Form.Select>
                                </Form.Group>

                                <FormComponent 
                                    required={true}
                                    placeholder={shippingTranslate.zip[language]}
                                    value={billingZip}
                                    onChange={(e) => setBillingZip(e.target.value)}
                                />

                                <FormComponent 
                                    required={true}
                                    placeholder={shippingTranslate.city[language]}
                                    value={billingCity}
                                    onChange={(e) => setBillingCity(e.target.value)}
                                />

                                <Form.Group className="py-2" >
                                    <Form.Label >
                                        {shippingTranslate.contact[language]}
                                    </Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text className="filter-label" style={{ borderWidth:"3px" }}>
                                            {country === "Deutschland" ? "+49" : "Bitte wählen Sie ein Land aus"}
                                        </InputGroup.Text>
                                        <FormControl disabled={!country} placeholder="Telefonnummer" style={{ borderBottomRightRadius: "40px", borderTopRightRadius: "40px", borderWidth:"3px"}}
                                            value={billingContactNum}
                                            onChange={(e) => setBillingContactNum(e.target.value)}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </div>}
                        </Col>
                    </Row>
                    <div className="py-3">
                        <Button style={{ borderRadius:"40px", borderWidth:"3px"}}
                            type="submit"
                            variant="primary"
                            disabled={!isValid()}>
                            {shippingTranslate.continue[language]}
                        </Button>
                    </div>
                </Form>
            )}
        </FormContainer>
    );
};

export default ShippingScreen;
