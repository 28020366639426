import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, ButtonGroup, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/message";
import Loader from "../../components/loader";
import { listUsers, deleteUser } from "../../actions/user";
import SearchBox from "../../components/searchBox";
import Paginate from "../../components/paginate";
import {g} from '../../Utils/translateLibrary/globals';
import { isAdmin, isAdminOrSupport } from "../../Utils/helpers/user";

const UserListScreen = ({ history, match }) => {
    const searchParams = window.location.search;
    const params = new URLSearchParams(searchParams);
    const keyword = params.get('q') || '';
    const pageNumber = params.get('page') || 1;

    const dispatch = useDispatch();
    const userList = useSelector((state) => state.userList);
    const { loading, users, error, page, pages } = userList;

    // userAuth
    const userAuth = useSelector((state) => state.userAuth);
    const { userInfo } = userAuth;

    // userDelete
    const userDelete = useSelector((state) => state.userDelete);
    const { success: successDelete, error: deleteError } = userDelete;

    const settings = useSelector((state) => state.settings);
    const { language } = settings;

    useEffect(() => {
        if (userInfo) {
            if (!isAdminOrSupport(userInfo)) {
                history.push("/");
            }
        } else {
            history.push("/login");
        }

        dispatch(listUsers(keyword, pageNumber));
    }, [dispatch, history, userInfo, successDelete, keyword, pageNumber]);

    const deleteHandler = (e, id) => {
        e.preventDefault();
        if (window.confirm("Möchten Sie den Benutzer löschen ?")) {
            dispatch(deleteUser(id));
        }
    };

    return (
        <>
            <Container>
            {successDelete && (
                <Message variant="success">
                    Benutzer erfolgreich gelöscht
                </Message>
            )}
            {deleteError && <Message variant="danger">{deleteError}</Message>}
            <Route
                render={({ history }) => (
                    <SearchBox
                        history={history}
                        user
                        placeholder="Suche Benutzer"
                        value={keyword}
                    />
                )}
            />
            {loading ? (
                <Loader />
            ) : error ? (
                <Message variant="danger">{error}</Message>
            ) : users && userInfo ? (
                <>
                    <Table
                        bordered
                        hover
                        responsive
                        className="table-sm"
                    >
                        <thead>
                            <tr>
                                <th>Benutzer Id</th>
                                <th>Benutzername</th>
                                <th>Email</th>
                                <th>Rolle</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => {
                                return (
                                    <tr key={user._id}>
                                        <td>{user._id}</td>
                                        <td>{user.username}</td>
                                        <td>
                                            <a href={`mailto:${user.email}`}>
                                                {user.email}
                                            </a>
                                        </td>
                                        <td>{user.role !== undefined ? g.roles[user.role][language] : ''}</td>
                                        <td>
                                            {(isAdmin(userInfo) || !isAdmin(user)) && (<ButtonGroup className="d-flex justify-content-center">
                                                <LinkContainer
                                                    to={`/admin/user/${user._id}/edit`}
                                                >
                                                    <Button
                                                        type="button"
                                                        className="m-1 btn-sm"
                                                    >
                                                        <i className="fas fa-edit" />
                                                    </Button>
                                                </LinkContainer>
                                                {userInfo._id !== user._id && (
                                                    <Button
                                                        variant="danger"
                                                        className="m-1 btn-sm"
                                                        onClick={(e) =>
                                                            deleteHandler(
                                                                e,
                                                                user._id
                                                            )
                                                        }
                                                    >
                                                        <i className="fas fa-trash" />
                                                    </Button>
                                                )}
                                            </ButtonGroup>)}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    <Paginate
                        pages={pages}
                        page={page}
                        keyword={keyword}
                    />
                </>
            ) : (
                <></>
            )}
            </Container>
        </>
    );
};

export default UserListScreen;
