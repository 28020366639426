import React, { useEffect, useState } from "react";
import FormComponent from "../../components/formComponent";
import FormContainer from "../../components/formContainer";
import { Form, Button } from "react-bootstrap";
import { resetPassword } from "../../actions/user";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/message";
import Loader from "../../components/loader";

const ResetPassword = ({ history, match }) => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordMessage, setPasswordMessage] = useState(null);
    const [passwordErrorMessage, setPasswordErrorMessage] = useState(null);
    const [validPassword, setValidPassword] = useState(false);
    const [validConfirmPassword, setValidConfirmPassword] = useState(false);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');

    const dispatch = useDispatch();

    // Update User
    const updateUserPassword = useSelector((state) => state.updateUserPassword);
    const { success, loading, error } = updateUserPassword;

    // User Authentication
    const userAuth = useSelector((state) => state.userAuth);
    const { userInfo } = userAuth;

    useEffect(() => {
        if (userInfo) {
            history.push("/profile/change-password");
            return;
        }

        if(!token || success) {
            history.push('/login');
            return;
        }

        if (error) {
            setPassword("");
            setConfirmPassword("");
            setValidPassword(false);
            setValidConfirmPassword(false);
        }
    }, [dispatch, history, success, error, userInfo, token]);

    const newPasswordConfirmOnChangeHandler = (e) => {
        const cPassword = e.target.value;
        setConfirmPassword(cPassword);
        if (password !== cPassword) {
            setPasswordMessage("Passwörter stimmen nicht überein");
            setValidConfirmPassword(false);
        } else if (cPassword.length < 8) {
            setPasswordMessage(
                "Das Passwort muss länger als 8 Zeichen sein"
            );
            setValidConfirmPassword(false);
        } else {
            setPasswordMessage(null);
            setValidConfirmPassword(true);
        }
    };

    const newPasswordOnChangeHandler = (e) => {
        let p = e.target.value;
        setPassword(p);
        if (p.length < 8) {
            setPasswordErrorMessage(
                "Das Passwort muss länger als 8 Zeichen sein"
            );
            setValidPassword(false);
        } else {
            setPasswordErrorMessage(null);
            setValidPassword(true);
        }
    };

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(resetPassword(token, password));
    };

    return (
        <>
            <FormContainer>
                <h1>Passwort ändern</h1>
                {error && <Message variant="danger">{error}</Message>}
                {success && (
                    <Message variant="success">{"Password aktualisiert"}</Message>
                )}
                {loading && <Loader />}
                <Form onSubmit={submitHandler} autoComplete="on">
                    <FormComponent
                        label="Neues Passwort"
                        type="password"
                        value={password}
                        errorMessage={passwordErrorMessage}
                        valid={validPassword}
                        required={false}
                        onChange={newPasswordOnChangeHandler}
                    />
                    <FormComponent
                        label="Neues Passwort erneut eingeben"
                        type="password"
                        value={confirmPassword}
                        errorMessage={passwordMessage}
                        valid={validConfirmPassword}
                        required={false}
                        onChange={newPasswordConfirmOnChangeHandler}
                    />
                    <div className="py-3">
                        <Button
                            type="submit"
                            variant="primary"
                            disabled={
                                password === "" ||
                                confirmPassword === "" ||
                                password !== confirmPassword
                            }
                        >
                            Passwort aktualisieren
                        </Button>
                    </div>
                </Form>
            </FormContainer>
        </>
    );
};

export default ResetPassword;
