export const setUserRole = [
    {
        label: "Administrator",
        value: "admin",
    },
    {
        label: "Benutzer",
        value: "user",
    },
    {
        label: "Gewerblich",
        value: "seller",
    },
    {
        label: "Support",
        value: "support",
    },
];
