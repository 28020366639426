export const validation = {
    errors: {
        required_field: {
            en: "Required field cant be empty !",
            de: "Pflichtfelder dürfen nicht leer sein !",
            fr: "",
        },
        required_weight_field: {
            en: "Required weight fields cant be empty !",
            de: "Gewichtsfelder dürfen nicht leer sein !",
            fr: "",
        },
        duplicate_weight: {
            en: "Weight already exists",
            de: "Die Gewichtsangabe existiert schon",
            fr: "",
        },
        required_weight: {
            en: "Atleast one weight must be set",
            de: "Es muss mindestens ein Gewicht hinterlegt werden",
            fr: "",
        },
        invalid_quantity: {
            en: "Invalid quantity",
            de: "Ungültige Menge",
            fr: "",
        },
        required_selected_weight: {
            en: "Please choose a weight",
            de: "Bitte wählen Sie ein Gewicht",
            fr: "",
        },
        min_length_password: {
            en: "Password length must be greater than 8 character",
            de: "Das Passwort muss eine Mindestlänge von 8 Zeichen haben.",
            fr: "",
        },
        password_not_matching: {
            en: "Password doesnt match",
            de: "'Passwort bestätigen' stimmt nicht überein.",
            fr: "",
        }
    }
};