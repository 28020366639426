import React, { useState } from "react";
import {  useSelector } from 'react-redux'
import { Form, Row, FloatingLabel, Button, InputGroup } from "react-bootstrap";
import '../Utils/css/filter.css';
import { colors, consistencies, categories, shipmentTypes } from '../Utils/translateLibrary/product';
import FormComponent from './formComponent'
import { f, g } from '../Utils/translateLibrary/filter';
import { p } from '../Utils/translateLibrary/productDetails';

const FilterComponent = ({ history, pageNum }) => {
    const settings = useSelector((state) => state.settings);
    const {language} = settings;
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const [sort, setSort] = useState('');
    const [category, setCategory] = useState(params.get('category') || '');
    const [color, setColor] = useState(params.get('color') || '');
    const [priceFrom, setPriceFrom] = useState('');
    const [consistency, setConsistency] = useState(params.get('consistency') || '');
    const [priceTo, setPriceTo] = useState('');
    const [seller, setSeller] = useState(params.get('seller') || params.get('userId') || '');
    const [name, setName] = useState(params.get('q') || '');
    const [shipmentType, setShipmentType] = useState(params.get('shipment-type') || '');

    const onSelectorHandler = (e) => {
        e.preventDefault();
        history.push(
            `?sort=${sort}&category=${category}&color=${color}&price-from=${priceFrom}&price-to=${priceTo}&seller=${seller}&q=${name}&consistency=${consistency}&shipment-type=${shipmentType}&page=${
                pageNum ? pageNum : "1"
            }`
        );
    };

    const handleFilterReset = (e) => {
        e.preventDefault();

        setSort('');
        setCategory('');
        setColor('');
        setPriceFrom('');
        setPriceTo('');
        setConsistency('');
        setName('');
        setSeller('');
        setShipmentType('');

        history.push(`/search`);
    };

    const sortOnChangeHandler = (e) => {
        setSort(e.target.value);
    };

    const priceFromOnChangeHandler = (e) => {
        setPriceFrom(e.target.value);
    };

    const priceToOnChangeHandler = (e) => {
        setPriceTo(e.target.value);
    };
    
    const onCheckboxChange = (e, callback) => {
        const value = e.target.checked ? e.target.value : '';
        callback(value);
    }

    return (
        <Form onSubmit={onSelectorHandler} className="filter-container">
            <Form.Group>
                <FormComponent
                  label={f.sellerSearch[language]}
                  type="text"
                  placeholder={f.search[language]}
                  value={seller}
                  onChange={(e) => setSeller(e.target.value)}
                />

                <FormComponent
                  label={f.productSearch[language]}
                  type="text"
                  placeholder={f.search[language]}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />

                <Form.Group controlId="formBasicSelect" className='py-2'>
                    <Form.Label style={{ fontWeight:"bolder"}}>{p.consistency[language]}</Form.Label>
                    <Form.Control
                      as="select"
                      value={consistency}
                      className="input"
                      onChange={e => {
                        setConsistency(e.target.value);
                      }}
                    >
                      <option value="">{f.selectConsistency[language]}</option>
                      {Object.values(consistencies).map((consistency, key) => {
                        return (
                          <option value={key} key={'consistency_' + key}>{consistency[language]}</option>
                        )
                      })}
                    </Form.Control>
                </Form.Group>

                {/*
                <Form.Group>
                    <Form.Label className="label">{ p.consistency[language] }</Form.Label>
                    {Object.values(consistencies).map((consistencyNameKey, key) => {
                        return (
                            <InputGroup className="input-wrapper" key={'consistency_' + key}>
                                <input onChange={(e) => onCheckboxChange(e, setConsistency)} type="checkbox" value={key} defaultChecked={consistency === key.toString()} />
                                <Form.Label className="input-label">{consistencyNameKey[language]}</Form.Label>
                            </InputGroup>
                        )
                    })}

                    <Form.Label className="label mt-3">{p.color[language]}</Form.Label>
                    {Object.values(colors).map((colorNameKey, key) => {
                        return (
                            <InputGroup className="input-wrapper" key={'color_' + key}>
                                <input onChange={(e) => onCheckboxChange(e, setColor)} type="checkbox" value={key} defaultChecked={color === key.toString()} />
                                <Form.Label className="input-label">{colorNameKey[language]}</Form.Label>
                            </InputGroup>
                        )
                      })}
                    </Form.Group> */}
                <Form.Group controlId="formBasicSelect" className='py-2'>
                    <Form.Label style={{ fontWeight:"bolder"}}>{p.color[language]}</Form.Label>
                    <Form.Control
                      as="select"
                      value={color}
                      className="input"
                      onChange={e => {
                        setColor(e.target.value);
                      }}
                    >
                      <option value="">{f.selectColor[language]}</option>
                      {Object.values(colors).map((color, key) => {
                        return (
                          <option value={key} key={'color_' + key}>{color[language]}</option>
                        )
                      })}
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="formBasicSelect" className='py-2'>
                    <Form.Label style={{ fontWeight:"bolder"}}>{p.category[language]}</Form.Label>
                    <Form.Control
                      as="select"
                      value={category}
                      className="input"
                      onChange={e => {
                        setCategory(e.target.value);
                      }}
                    >
                      <option value="">{f.selectCategory[language]}</option>
                      {Object.values(categories).map((category, key) => {
                        return (
                          <option value={key} key={'category_' + key}>{category[language]}</option>
                        )
                      })}
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="formBasicSelect" className='py-2'>
                    <Form.Label style={{ fontWeight:"bolder"}}>{p.shipmentType[language]}</Form.Label>
                    <Form.Control
                      as="select"
                      value={shipmentType}
                      className="input"
                      onChange={e => {
                        setShipmentType(e.target.value);
                      }}
                    >
                      <option value="">{f.selectShipment[language]}</option>
                      {Object.values(shipmentTypes).map((type, key) => {
                        return (
                          <option value={key} key={'shipment_' + key}>{type[language]}</option>
                        )
                      })}
                    </Form.Control>
                </Form.Group>

                <InputGroup className="my-3">
                    <InputGroup.Text className="filter-label">{g.from[language]}</InputGroup.Text>
                    <Form.Control
                        value={priceFrom}
                        placeholder="€"
                        onChange={(e) => priceFromOnChangeHandler(e)}
                    />
                    <InputGroup.Text className="filter-label">{g.to[language]}</InputGroup.Text>
                    <Form.Control
                        value={priceTo}
                        placeholder="€"
                        onChange={(e) => priceToOnChangeHandler(e)}
                    />
                </InputGroup>
                
                <FloatingLabel label={f.sortBy[language]} className="my-2" style={{fontWeight:"bolder"}}>
                    <Form.Select className="select"
                        aria-label="Sort"
                        size="sm"
                        value={sort}
                        onChange={(e) => sortOnChangeHandler(e)}
                    >
                        <option value="asc">{f.sortPriceAsc[language]}</option>
                        <option value="desc">{f.sortPriceDesc[language]}</option>
                    </Form.Select>
                </FloatingLabel>

                <Row className="d-flex align-items-center mx-1 mb-2 mt-3">
                    <Button type="submit" style={{fontWeight:"bolder"}}>{f.applyFilter[language]}</Button>
                </Row>
                <Row className="d-flex align-items-center mx-1 my-2">
                    <Button style={{ backgroundColor: "var(--secondary)", fontWeight:"bolder"}}
                        type="button"
                        onClick={handleFilterReset}
                        variant="danger"
                    >
                        {f.removeFilter[language]}
                    </Button>
                </Row>
            </Form.Group>
        </Form>
    );
};

export default FilterComponent;
